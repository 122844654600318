/* eslint-disable react/no-array-index-key */
import type {
  ButtonHTMLAttributes,
  Dispatch,
  PropsWithChildren,
  ReactEventHandler,
  ReactNode,
  Ref,
} from "react";
import { forwardRef, Fragment } from "react";
import { pipe } from "fp-ts/lib/function";
import { fromNullable, none } from "fp-ts/lib/Option";
import { Key } from "ts-key-enum";

import type { SVGString } from "*.svg";

import { E, O } from "@scripts/fp-ts";
import { Svg } from "@scripts/react/components/Svg";
import type { Klass, KlassProp } from "@scripts/react/util/classnames";
import { classNames, klass, klassConditional, klassPropO } from "@scripts/react/util/classnames";
import type { AnyPageMeta } from "@scripts/routes/routing/base";
import type { AriaLabelRequired, ChildStringOrAriaLabel } from "@scripts/types/ariaLabel";
import { delProp } from "@scripts/util/delProp";
import type { LabelOrAriaLabel } from "@scripts/util/labelOrAriaLabel";
import { merge } from "@scripts/util/merge";

import { buttons as b } from "@styles/components/_buttons";

import checkmark from "@svgs/checkmark.svg";
import chevronDown from "@svgs/chevron-down.svg";
import closeX from "@svgs/close-x.svg";
import download from "@svgs/download.svg";
import pencil from "@svgs/pencil.svg";
import plus from "@svgs/plus.svg";
import reject from "@svgs/reject.svg";

import { mapOrEmpty } from "../components/Empty";
import { getNavigateBackFn } from "../router";
import type { RouterAction } from "../state/router";
import type { PageHistoryOldToNew } from "../state/store";
import type { WithHTMLAttrs } from "../util/dom";
import { AnchorIconLayout, type AnchorIconLayoutProps, type ArrowType, arrowTypeKlassO } from "./Anchor";
import { Image } from "./Image";
import { LoaderBars } from "./Loader";
import { LoadingCircle } from "./LoadingCircle";

export type Variant = "primary" | "secondary" | "link" | "none" | "ghost" | "callout" | "action" | "danger" | "reject" | "danger-secondary" | "gray";

interface ButtonPropsI {
  children: ReactNode;
  onClick: ReactEventHandler;
  variant: Variant;
}
export type ButtonProps = WithHTMLAttrs<ButtonPropsI, ButtonHTMLAttributes<HTMLElement>>;

interface ButtonVariantI {
  children: ReactNode;
  onClick: ReactEventHandler;
}
export type ButtonVariantProps = WithHTMLAttrs<ButtonVariantI, ButtonHTMLAttributes<HTMLElement>>;

export const Button = forwardRef(({ variant, onClick, children, ...props }: ButtonProps, ref: Ref<HTMLButtonElement>) => {
  const classes = classNames(
    variant !== "none" ? `btn-${variant}` : none,
    variant !== "link" ? b[".btn"] : none,
    fromNullable(props.className)
  );
  const defaultAttributes: ButtonHTMLAttributes<HTMLElement> = {
    className: classes,
    onClick: onClick,
  };
  const mergedAttrs = merge(props)(defaultAttributes);

  return (
    <button ref={ref} type="button" {...mergedAttrs}>
      {children}
    </button>
  );
});

export const ButtonPrimary = forwardRef(({ children, ...props }: ButtonVariantProps, ref: Ref<HTMLButtonElement>) => (
  <Button ref={ref} variant="primary" {...props}>{children}</Button>
));

export const ButtonGray = forwardRef(({ children, ...props }: ButtonVariantProps, ref: Ref<HTMLButtonElement>) => (
  <Button ref={ref} variant="gray" {...props}>{children}</Button>
));

export const ButtonSecondary = ({ children, ...props }: ButtonVariantProps) => (
  <Button variant="secondary" {...props}>{children}</Button>
);

export const ButtonDanger = ({ children, ...props }: ButtonVariantProps) => (
  <Button variant="danger" {...props}>{children}</Button>
);

export const btnLinkSmallStyles = ["small", "mb-025", "b-0"];

export const ButtonLink = forwardRef(({ children, ...props }: ButtonVariantProps, ref: Ref<HTMLButtonElement>) => (
  <Button ref={ref} variant="link" {...props}>{children}</Button>
));

type ButtonLinkMultilineProps = Omit<ButtonVariantProps, "children"> & { text: string };
export const ButtonLinkMultiline = forwardRef((props: ButtonLinkMultilineProps, ref: Ref<HTMLButtonElement>) => (
  <ButtonLink
    {...delProp(props, "className")}
    {...klassPropO(["btn-link-multiline", "text-left"])(props.className)}
    ref={ref}
  >
    <span>
      {props.text}
    </span>
  </ButtonLink>
));

export const InputAlignedButtonLink = (props: PropsWithChildren<ButtonVariantProps>) =>
  <ButtonLink {...props} {...klassPropO(["no-decoration input-aligned"])(props.className)}>{props.children}</ButtonLink>;

export type ButtonLoading<LT extends string> = {
  loading: boolean;
  text: ReactNode;
  loadingText: LT extends `${string}${"..." | "…" | "&hellip;"}` ? never : LT;
};

export const LoadingElement = <LT extends string>(props: ButtonLoading<LT>) => props.loading
  ? <Fragment><LoaderBars />{props.loadingText}&hellip;</Fragment>
  // eslint-disable-next-line react/jsx-no-useless-fragment
  : <Fragment>{props.text}</Fragment>;

export type ButtonSubmitProps<LT extends string> = WithHTMLAttrs<
  ButtonLoading<LT> & {
    onClick: ReactEventHandler;
    variant?: Variant;
  },
  ButtonHTMLAttributes<HTMLElement>
>;

const _ButtonSubmit = <LT extends string>(props: Omit<ButtonSubmitProps<LT>, "children">, ref: Ref<HTMLButtonElement>) => (
  <Button ref={ref} type="submit"  {...delProp(props, "loading", "loadingText", "text")} variant={props.variant ?? "primary"} disabled={props.loading || props.disabled} >
    <LoadingElement {...props} />
  </Button>
);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ButtonSubmit = forwardRef(_ButtonSubmit) as typeof _ButtonSubmit;

const _ButtonAsync = <LT extends string>(props: Omit<ButtonProps & ButtonLoading<LT>, "children">, ref: Ref<HTMLButtonElement>) => {
  return <Button ref={ref} {...delProp(props, "loading", "loadingText", "text")} disabled={props.loading || props.disabled}>
    <LoadingElement {...props} />
  </Button>;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ButtonAsync = forwardRef(_ButtonAsync) as typeof _ButtonAsync;

const ButtonCalloutIcon = (props: { icon: SVGString }) => <>
  <Svg src={props.icon} {...klass(b[".btn-callout"][".callout-icon"])} />
  <span {...klass(b[".btn-callout"][".callout-plus"])}>
    <Svg src={plus} />
  </span>
</>;

export const ButtonCallout = forwardRef(({ children, icon, ...props }: ButtonVariantProps & { icon: SVGString }, ref: Ref<HTMLButtonElement>) => (
  <Button ref={ref} variant="callout" {...props}>
    <ButtonCalloutIcon icon={icon} />
    <span>{children}</span>
  </Button>
));

export const ButtonCalloutAsync = forwardRef(({ children, icon, ...props }: ButtonVariantProps & { icon: SVGString, loading: boolean }, ref: Ref<HTMLButtonElement>) => (
  <Button ref={ref} variant="callout" {...delProp(props, "loading")} disabled={props.loading || props.disabled}>
    {props.loading
      ? <div {...klass(b[".btn-callout"][".callout-icon"])}><LoadingCircle loadingMsg="" /></div>
      : <ButtonCalloutIcon icon={icon} />
    }
    <span>{children}</span>
  </Button>
));


export const ButtonCalloutContainer = (props: { children: ReactNode }) => (
  <div {...klass(b[".btn-callout-container"])}>
    {Array.isArray(props.children)
      ? props.children.filter(_ => !(_ == null || (Array.isArray(_) && _.length === 0)))
        .map((n, i) => Array.isArray(n)
          ? n.map(_ => <div key={i}>{_}</div>)
          : <div key={i}>{n}</div>
        )
      : <div>{props.children}</div>
    }
  </div>
);

export const ButtonSmall = forwardRef(({ onClick, children, variant, ...props }: ButtonProps, ref: Ref<HTMLButtonElement>) => (
  <Button
    {...props}
    ref={ref}
    variant={variant}
    onClick={onClick}
    {...klass(b[".btn"].attrs[".btn-small"])}
  >
    {children}
  </Button>
));

interface SizeButtonProps extends ButtonVariantProps {
  small: boolean;
}

export const ButtonApprove = forwardRef(({ onClick, children, small, ...props }: SizeButtonProps, ref: Ref<HTMLButtonElement>) => (
  <Button
    ref={ref}
    onClick={onClick}
    variant={"none"}
    {...klass(b[".btn-approve"], small ? b[".btn"].attrs[".btn-small"] : none)}
    {...props}
  >
    <Svg src={checkmark} />
    {children}
  </Button>
));

export const ButtonReject = forwardRef(({ onClick, children, small, ...props }: SizeButtonProps, ref: Ref<HTMLButtonElement>) => (
  <Button
    variant={"none"}
    ref={ref}
    onClick={onClick}
    {...klass(b[".btn-reject"], small ? b[".btn"].attrs[".btn-small"] : none)}
    {...props}
  >
    <Svg src={reject} />
    {children}
  </Button>
));

export const ButtonEdit = forwardRef(({ onClick, children, ...props }: ButtonVariantProps, ref: Ref<HTMLButtonElement>) => (
  <Button
    variant={"none"}
    ref={ref}
    onClick={onClick}
    {...klass(b[".btn-review"], b[".btn"].attrs[".btn-small"])}
    {...props}
  >
    <Svg src={pencil} />
    {children}
  </Button>
));

export const ButtonEditLoading = forwardRef(({ children, ...props }: ButtonVariantProps, ref: Ref<HTMLButtonElement>) => (
  <Button ref={ref} variant="none" {...props} disabled {...klass(b[".btn-review"], b[".btn"].attrs[".btn-small"])}>
    <LoaderBars />
    {children}
  </Button>
));


type ChevronProps = {
  chevronOpen: boolean;
};
export const ButtonReview = forwardRef(({ onClick, children, small, chevronOpen, ...props }: SizeButtonProps & ChevronProps, ref: Ref<HTMLButtonElement>) => (
  <Button
    variant={"none"}
    ref={ref}
    onClick={onClick}
    {...klassConditional("chevron-open rotate", [b[".btn-review"], small ? b[".btn"].attrs[".btn-small"] : none, "rotate-ccw-180-svg"])(chevronOpen)}
    {...props}
  >
    <Svg src={chevronDown} />
    {children}
  </Button>
));

type IconButtonBase = {
  onClick: ReactEventHandler;
  icon: SVGString;
};

type ButtonIconProps = IconButtonBase & ChildStringOrAriaLabel & {
  linkPostfix?: ReactNode;
  variant: Exclude<Variant, "link">;
};

type IconButtonWithHtmlAttributes<T> = WithHTMLAttrs<T, ButtonHTMLAttributes<HTMLElement>>;

type ButtonIconWithHtmlAttributes = IconButtonWithHtmlAttributes<ButtonIconProps>;

export const ButtonIcon = forwardRef(({ onClick, variant, icon, linkPostfix, children, ...props }: ButtonIconWithHtmlAttributes, ref: Ref<HTMLButtonElement>) => (
  <Button ref={ref} onClick={onClick} variant={variant} {...props}>
    <Svg src={icon} />
    <span>{children}</span>
    {linkPostfix}
  </Button>
));

type ButtonLinkIconProps = Omit<ButtonIconWithHtmlAttributes, "variant" | "children" | "aria-label"> &
  Pick<AnchorIconLayoutProps, "appendIcon"> & { textOrAriaLabel: LabelOrAriaLabel };

export const ButtonLinkIcon = forwardRef(({ onClick, icon, textOrAriaLabel, appendIcon, ...props }: ButtonLinkIconProps, ref: Ref<HTMLButtonElement>) => (
  <Button
    ref={ref}
    onClick={onClick}
    variant={"link"}
    {...props}
    {...klassPropO(["no-decoration", "b-0"])(props.className)}
    aria-label={E.toUnion(textOrAriaLabel)}
  >
    <AnchorIconLayout
      appendIcon={appendIcon}
      disabled={props.disabled || false}
      icon={icon}
      textOrAriaLabel={textOrAriaLabel}
    />
  </Button>
));

export const ButtonIconStacked = forwardRef(({ onClick, variant, icon, linkPostfix, children, ...props }: ButtonIconWithHtmlAttributes, ref: Ref<HTMLButtonElement>) => (
  <Button ref={ref} onClick={onClick} variant={variant} {...props}>
    <div {...klass(b[".btn-stacked"])}>
      <Svg src={icon} {...klass(b[".btn-stacked"][".stacked-icon"])} />
      <span>{children}</span>
      {linkPostfix}
    </div>
  </Button>
));

const _ButtonAsyncIcon = <LT extends string>({ onClick, variant, icon, linkPostfix, children, ...props }: ButtonIconWithHtmlAttributes & Omit<ButtonLoading<LT>, "text">, ref: Ref<HTMLButtonElement>) => (
  <Button ref={ref} onClick={onClick} variant={variant} {...delProp(props, "loading", "loadingText")}>
    <LoadingElement loading={props.loading} loadingText={props.loadingText} text={
      <Fragment>
        <Svg src={icon} />
        <span>{children}</span>
        {linkPostfix}
      </Fragment>
    }
    />
  </Button>
);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ButtonAsyncIcon = forwardRef(_ButtonAsyncIcon) as typeof _ButtonAsyncIcon;

type IconButtonProps = IconButtonWithHtmlAttributes<IconButtonBase> & AriaLabelRequired;

export const IconButtonWithChildren = forwardRef(({ onClick, ...props }: Omit<IconButtonProps, "icon">, ref: Ref<HTMLButtonElement>) => (
  <button
    ref={ref}
    type="button"
    onClick={onClick}
    {...props}
    {...klassConditional("disabled", ["icon-button", fromNullable(props.className)])(props.disabled ?? false)}
  >
    {props.children}
  </button>
));

export const IconButton = forwardRef((props: Omit<IconButtonProps, "children">, ref: Ref<HTMLButtonElement>) => (
  <IconButtonWithChildren {...delProp(props, "icon")} ref={ref}>
    <Svg src={props.icon} />
  </IconButtonWithChildren>
));

type ButtonAction = "edit" | "delete" | "download";

type IconType = {
  type: "svg";
  svg: SVGString;
};

type ImageType = {
  type: "img";
  src: string;
};

export type ButtonIconType = IconType | ImageType;

type ActionIcons = {
  [key in ButtonAction]: SVGString;
};

const actionIcons: ActionIcons = {
  edit: pencil,
  delete: closeX,
  download: download,
};

export type ButtonActionProps = PropsWithChildren<{
  action: ButtonAction;
  contentName: string;
  disabled?: boolean;
  onActionClick: ReactEventHandler;
  onClick: ReactEventHandler;
  variant: "tall" | "short";
  disableHover?: boolean;
}>;

const ButtonActionBaseIcon = (props: Omit<ButtonActionProps, "onClick" | "variant" | "contentName">) => <ButtonLink
  aria-label={props.action}
  {...klass("action-type")}
  tabIndex={0}
  onClick={props.onActionClick}
  onKeyPress={(e) => (e.key === Key.Enter || e.key === "SpaceBar" || e.key === " ") && props.onActionClick(e)}
  disabled={props.disabled}
>
  <Svg src={actionIcons[props.action]} />
</ButtonLink>;

const ButtonActionBase = (props: ButtonActionProps) =>
  <div
    {...klassConditional("no-hover", ["btn", `btn-action-${props.variant}`])(props.disableHover ?? false)}
    {...(props.disabled && { "data-disabled": true })}
  >
    <Button
      {...klass("content")}
      variant={"link"}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
    <ButtonActionBaseIcon {...props} />
  </div>;

export const ButtonActionShort = (props: Omit<ButtonActionProps, "variant">) => {
  return (
    <ButtonActionBase
      action={props.action}
      onActionClick={props.onActionClick}
      onClick={props.onClick}
      contentName={props.contentName}
      variant={"short"}
      disableHover={props.disableHover}
    >
      <div {...klass("content-label")}>
        <span {...klass("content-name")}>{props.contentName}</span>
      </div>
    </ButtonActionBase>
  );
};

type ButtonActionTallProps = Omit<ButtonActionProps, "variant" | "children"> & {
  contentMeta: O.Option<string>;
  icon: ButtonIconType;
};

const imageComponentProps = klass("content-type");

export const ButtonActionTall = (props: ButtonActionTallProps) =>
  <ButtonActionBase
    action={"edit"}
    onActionClick={props.onActionClick}
    onClick={props.onClick}
    contentName={props.contentName}
    variant={"tall"}
    disableHover={props.disableHover}
  >
    {props.icon.type === "img"
      ? <div {...imageComponentProps}><Image src={props.icon.src} alt={"ImageLabel"} /></div>
      : <Svg {...imageComponentProps} src={props.icon.svg} />
    }
    <div {...klass("content-label")}>
      <span {...klass("content-name", O.isSome(props.contentMeta) ? "has-meta" : "")}>{props.contentName}</span>
      {pipe(props.contentMeta, mapOrEmpty(t => <span {...klass("content-meta")}>{t}</span>))}
    </div>
  </ButtonActionBase>;

export type CloseXButtonProps = Omit<IconButtonProps, "icon" | "aria-label">;

export const CloseXButton = forwardRef(({ ...props }: CloseXButtonProps, ref: Ref<HTMLButtonElement>) => (
  <IconButton ref={ref} icon={closeX} aria-label="close" {...props} />
));

export const PlusButton = (props: { icon: SVGString, text: string }) => <>
  <div {...klass("icon-container")}>
    <span {...klass("callout-plus")}>
      <Svg src={plus} {...klass("callout-plus-icon")} />
    </span>
    <Svg src={props.icon} />
  </div>
  {props.text}
</>;

export const ButtonsContainer = (props: PropsWithChildren<{ klasses: KlassProp, divider?: true }>) => (
  <div {...klassPropO(["buttons-container", props.divider ? "divider" : ""])(props.klasses)}>
    {props.children}
  </div>
);

const secondToLastIndex = 2;

type ButtonLinkPageLastProps = Omit<ButtonVariantProps, "children" | "onClick"> & ArrowType & { klasses?: Klass, fallbackPage: AnyPageMeta, pageHistoryOldToNew: PageHistoryOldToNew, dispatch: Dispatch<RouterAction> };

export const ButtonLinkPageLast = (props: PropsWithChildren<ButtonLinkPageLastProps>) => {
  const buttonLinkProps = delProp(props, "arrowType", "fallbackPage", "pageHistoryOldToNew", "dispatch");

  const backPage = pipe(
    props.pageHistoryOldToNew[props.pageHistoryOldToNew.length - secondToLastIndex],
    O.fromNullable,
    O.getOrElse(() => props.fallbackPage),
  );

  const pageLastTitle = pipe(
    backPage.dataMeta,
    O.chain(_ => _.customDisplayName),
    O.getOrElse(() => backPage.title())
  );
  return (
    <ButtonLink
      {...buttonLinkProps}
      className={klass(arrowTypeKlassO(O.fromNullable(props.arrowType), props.klasses).className, "mt-0").className}
      onClick={() => getNavigateBackFn(props.dispatch)(backPage)}
    >
      Back to {pageLastTitle}
    </ButtonLink>
  );
};
