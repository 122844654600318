import type { Dispatch, ReactNode, SetStateAction } from "react";
import { useState } from "react";
import { sequenceS } from "fp-ts/lib/Apply";
import { useStableO } from "fp-ts-react-stable-hooks";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { O } from "@scripts/fp-ts";
import type { LoginReasonU } from "@scripts/generated/domaintables/loginReasons";
import type { MfaInitiated } from "@scripts/generated/domaintables/loginStatus";
import type { Bank } from "@scripts/generated/models/bank";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { LoginPageData, LoginPostC } from "@scripts/generated/models/login";
import type { SignupPostC } from "@scripts/generated/models/signup";
import { ContactBondLinkAnchor } from "@scripts/react/components/Anchor";
import { TabRow } from "@scripts/react/components/TabRow";
import { useConfig } from "@scripts/react/context/Config";
import type { FormState } from "@scripts/react/form/form";

import { MinimalContainer, MinimalFormContainer } from "@scripts-ssr/components/MinimalLayout";

import { CreateAccountForm } from "../components/loginPage/CreateAccountForm";
import { LoginForm } from "../components/loginPage/LoginForm";
import { loginSignUpRedirect, makeCreateAccountFormState, makeLoginFormState } from "../components/loginPage/loginPageSyntax";
import { MFAVerification } from "../components/loginPage/MFAVerification";


const loginOrSignup = "Log in or create a free BondLink account";
const blProvides = "access to documents, roadshows, email notifications, and more at all BondLink-powered issuer sites";
const loginRequiredMessage = (action: ReactNode) =>
  <>{loginOrSignup} to {action}.</>;
const issuerRequestMessage = (action: ReactNode) =>
  <>
    This issuer has requested that all visitors log in or create an account to {action}.
    BondLink accounts are free and provide {blProvides}.
  </>;

const makeLoginMessage = (
  config: BLConfigWithLog,
  issuer: O.Option<Issuer>,
  bank: O.Option<Bank>
) => O.fold(
  () => <>{loginOrSignup} for {blProvides}.</>,
  (r: LoginReasonU) => {
    switch (r._tag) {
      case "ViewBond":
        return O.fold(
          () => issuerRequestMessage("view this content"),
          (a: { issuer: Issuer, bank: Bank }) => loginRequiredMessage(
            <>
              view this <strong>{a.bank.name}</strong> bond offering for <strong>{a.issuer.name}</strong>
            </>,
          )
        )(sequenceS(O.Applicative)({ issuer, bank }));
      case "BookmarkBond":
        return loginRequiredMessage("bookmark this bond");
      case "BookmarkRfp":
        return loginRequiredMessage("bookmark this RFP");
      case "Notifications":
        return loginRequiredMessage("subscribe to notifications");
      case "DownloadDoc":
      case "Roadshow":
      case "ViewDoc":
        return issuerRequestMessage("view this content");
    }
    return config.exhaustive(r);
  },
);

type LoginPageView = "login" | "createAccount";

const LoginOrCreateAccount = (props: LoginPageProps & {
  setMfaInitiated: Dispatch<SetStateAction<O.Option<MfaInitiated>>>;
}) => {
  const config = useConfig();
  const [view, setView] = useState<LoginPageView>((O.isSome(props.contactSubmission) || props.showCreateAccount)
    ? "createAccount"
    : "login"
  );

  const [loginFormState, setLoginFormState] =
    useState<FormState<LoginPostC>>(makeLoginFormState(props));
  const [signupFormState, setSignupFormState] =
    useState<FormState<SignupPostC>>(makeCreateAccountFormState(props));
  return (
    <>
      <p>
        {makeLoginMessage(config, props.issuer, props.bank)(props.reason)}{" "}
        If you need help logging in, contact us at{" "}
        <ContactBondLinkAnchor title={config.customerSuccessEmail} />
      </p>
      <TabRow
        current={view}
        items={[
          {
            icon: O.none,
            onClick: () => setView("login"),
            title: "Log In",
            value: "login",
          },
          {
            icon: O.none,
            onClick: () => setView("createAccount"),
            title: "Create Account",
            value: "createAccount",
          },
        ]}
        klasses={[]}
        variant="login"
      />
      <MinimalFormContainer>
        {view === "login"
          ? (
            <LoginForm
              setMfaInitiated={props.setMfaInitiated}
              setState={setLoginFormState}
              state={loginFormState}
            />
          ) : (
            <CreateAccountForm
              organizations={props.commonCompanies}
              setState={setSignupFormState}
              state={signupFormState}
              redirect={props.redirect}
            />
          )}
      </MinimalFormContainer>
    </>
  );
};

export type LoginPageProps = LoginPageData & { showCreateAccount: boolean };

export const LoginPage = (props: LoginPageProps) => {
  const [mfaInitiated, setMfaInitiated] = useStableO<MfaInitiated>(O.none);

  return (
    <MinimalContainer>
      {O.fold(
        () => <LoginOrCreateAccount {...props} setMfaInitiated={setMfaInitiated} />,
        (m: MfaInitiated) =>
          <MFAVerification
            mfaInitiated={m}
            onSuccess={(_, r) => loginSignUpRedirect(r.data.redirect)}
            showRememberCheckbox={true}
          />,
      )(mfaInitiated)}
    </MinimalContainer>
  );
};
