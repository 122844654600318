import * as t from "io-ts";
import { withIdC as imported193_withIdC, withIdC as imported194_withIdC, WithStatusCU as imported200_WithStatusCU } from "./threadThrough";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const issuerLinkSectionC = t.type({
  name: t.string,
  sortOrder: t.number
});
export type IssuerLinkSectionC = typeof issuerLinkSectionC;
export type IssuerLinkSection = t.TypeOf<IssuerLinkSectionC>;


export const issuerLinkC = t.type({
  name: t.string,
  url: t.string,
  description: optionFromNullable(t.string),
  section: imported193_withIdC(issuerLinkSectionC),
  sortOrder: t.number
});
export type IssuerLinkC = typeof issuerLinkC;
export type IssuerLink = t.TypeOf<IssuerLinkC>;


export const linksBySectionC = t.type({
  section: imported194_withIdC(issuerLinkSectionC),
  links: t.readonlyArray(imported200_WithStatusCU(issuerLinkC))
});
export type LinksBySectionC = typeof linksBySectionC;
export type LinksBySection = t.TypeOf<LinksBySectionC>;


export const linkPostBodyC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  url: t.string,
  description: optionFromNullable(t.string),
  sectionId: t.number,
  sortOrder: t.number
});
export type LinkPostBodyC = typeof linkPostBodyC;
export type LinkPostBody = t.TypeOf<LinkPostBodyC>;


export const linkSectionPostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  sortOrder: t.number
});
export type LinkSectionPostC = typeof linkSectionPostC;
export type LinkSectionPost = t.TypeOf<LinkSectionPostC>;


