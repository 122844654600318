import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { LocalDateOrd } from "../../syntax/date/jodaSyntax";
import * as E from "fp-ts/lib/Either";
import { taggedContentC as imported111_taggedContentC, taggedContentC as imported114_taggedContentC, taggedContentC as imported110_taggedContentC, taggedContentC as imported116_taggedContentC, taggedContentC as imported112_taggedContentC, taggedContentC as imported115_taggedContentC, taggedContentC as imported113_taggedContentC } from "./taggedContent";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { subscribedC as imported21_subscribedC, subscribedC as imported22_subscribedC } from "./subscribed";
import { eventC as imported10_eventC } from "./event";
import { roadShowDataC as imported17_roadShowDataC, roadShowDataC as imported18_roadShowDataC } from "./roadshow";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { withModInfoC as imported117_withModInfoC, withIdC as imported161_withIdC, withModInfoC as imported116_withModInfoC, WithStatusCU as imported197_WithStatusCU, WithStatusCU as imported199_WithStatusCU, withIdC as imported164_withIdC, withModInfoC as imported120_withModInfoC, WithStatusCU as imported196_WithStatusCU, withIdC as imported162_withIdC, withIdC as imported163_withIdC, withIdC as imported160_withIdC, withModInfoC as imported123_withModInfoC, withModInfoC as imported119_withModInfoC, withIdC as imported158_withIdC, WithStatusCU as imported198_WithStatusCU, withIdC as imported159_withIdC, withModInfoC as imported118_withModInfoC, withModInfoC as imported121_withModInfoC, withModInfoC as imported122_withModInfoC } from "./threadThrough";
import { issuerC as imported27_issuerC, issuerC as imported32_issuerC, issuerC as imported30_issuerC, issuerC as imported28_issuerC, issuerC as imported29_issuerC, issuerC as imported31_issuerC } from "./issuer";
import { Ord as stringOrd } from "fp-ts/lib/string";
import { IssuerOrProgramRatingCU as imported3_IssuerOrProgramRatingCU } from "./ratingBase";
import { bidSubmissionTemplateDataC as imported6_bidSubmissionTemplateDataC } from "./bidSubmissionTemplates";
import { issuerNewsC as imported13_issuerNewsC } from "./issuerNewsBase";
import { documentWithCategoryC as imported11_documentWithCategoryC } from "./document";
import { mediaC as imported45_mediaC } from "./media";
import { LocalDateTimeFromIsoStringC, LocalDateC } from "../../codecs/localDate";
import { bondOfferingC as imported16_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported33_rfpC } from "./rfpBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { bidSubmissionDataC as imported6_bidSubmissionDataC } from "./bidSubmissions";

export const create = {
  _tag: `Create`,
  name: `Create`
} as const;

export const createTaggedC = t.type({
  _tag: t.literal(`Create`)
});
export type CreateTaggedC = typeof createTaggedC;
export type CreateTagged = t.TypeOf<CreateTaggedC>;
export type Create = CreateTagged & typeof create;
export const createC = pipe(createTaggedC, c => new t.Type<Create, CreateTagged>(
  `Create`,
  (u: unknown): u is Create => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Create> => pipe(c.decode(u), E.map(x => ({ ...x, ...create }))),
  (x: Create): CreateTagged => ({ ...x, _tag: `Create`}),
));
export type CreateC = typeof createC;


export const update = {
  _tag: `Update`,
  name: `Update`
} as const;

export const updateTaggedC = t.type({
  _tag: t.literal(`Update`)
});
export type UpdateTaggedC = typeof updateTaggedC;
export type UpdateTagged = t.TypeOf<UpdateTaggedC>;
export type Update = UpdateTagged & typeof update;
export const updateC = pipe(updateTaggedC, c => new t.Type<Update, UpdateTagged>(
  `Update`,
  (u: unknown): u is Update => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Update> => pipe(c.decode(u), E.map(x => ({ ...x, ...update }))),
  (x: Update): UpdateTagged => ({ ...x, _tag: `Update`}),
));
export type UpdateC = typeof updateC;


export const allActivityActionC = [createC, updateC] as const;
export const allActivityActionNames = [`Create`, `Update`] as const;
export type ActivityActionName = (typeof allActivityActionNames)[number];

export const ActivityActionCU = t.union([createC, updateC]);
export type ActivityActionCU = typeof ActivityActionCU;
export type ActivityActionU = t.TypeOf<ActivityActionCU>;

export const activityActionOrd: Ord.Ord<ActivityActionU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allActivityAction = [create, update] as const;
export type ActivityActionMap<A> = { [K in ActivityActionName]: A };


export const offeringItemC = t.type({
  _tag: t.literal(`OfferingItem`),
  item: imported21_subscribedC(imported196_WithStatusCU(imported116_withModInfoC(imported110_taggedContentC(imported16_bondOfferingC)))),
  roadshows: t.readonlyArray(imported158_withIdC(imported111_taggedContentC(imported17_roadShowDataC))),
  documents: t.readonlyArray(imported159_withIdC(imported112_taggedContentC(imported45_mediaC))),
  issuer: imported27_issuerC,
  at: LocalDateTimeFromIsoStringC,
  action: ActivityActionCU
});
export type OfferingItemC = typeof offeringItemC;
export type OfferingItem = t.TypeOf<OfferingItemC>;


export const newsItemC = t.type({
  _tag: t.literal(`NewsItem`),
  item: imported160_withIdC(imported117_withModInfoC(imported113_taggedContentC(imported13_issuerNewsC))),
  issuer: imported28_issuerC,
  at: LocalDateTimeFromIsoStringC
});
export type NewsItemC = typeof newsItemC;
export type NewsItem = t.TypeOf<NewsItemC>;


export const calendarItemC = t.type({
  _tag: t.literal(`CalendarItem`),
  item: imported161_withIdC(imported118_withModInfoC(imported10_eventC)),
  issuer: imported29_issuerC,
  at: LocalDateTimeFromIsoStringC
});
export type CalendarItemC = typeof calendarItemC;
export type CalendarItem = t.TypeOf<CalendarItemC>;


export const documentUploadC = t.type({
  _tag: t.literal(`DocumentUpload`),
  item: imported162_withIdC(imported119_withModInfoC(imported114_taggedContentC(imported11_documentWithCategoryC))),
  issuer: imported30_issuerC,
  at: LocalDateTimeFromIsoStringC
});
export type DocumentUploadC = typeof documentUploadC;
export type DocumentUpload = t.TypeOf<DocumentUploadC>;


export const bondRatingItemC = t.type({
  _tag: t.literal(`BondRatingItem`),
  item: imported163_withIdC(imported120_withModInfoC(imported3_IssuerOrProgramRatingCU)),
  issuer: imported31_issuerC,
  at: LocalDateTimeFromIsoStringC,
  action: ActivityActionCU
});
export type BondRatingItemC = typeof bondRatingItemC;
export type BondRatingItem = t.TypeOf<BondRatingItemC>;


export const rFPItemC = t.type({
  _tag: t.literal(`RFPItem`),
  item: imported22_subscribedC(imported197_WithStatusCU(imported121_withModInfoC(imported115_taggedContentC(imported33_rfpC)))),
  roadshows: t.readonlyArray(imported164_withIdC(imported116_taggedContentC(imported18_roadShowDataC))),
  bidSubmissionTemplate: optionFromNullable(imported198_WithStatusCU(imported122_withModInfoC(imported6_bidSubmissionTemplateDataC))),
  bidSubmission: optionFromNullable(imported199_WithStatusCU(imported123_withModInfoC(imported6_bidSubmissionDataC))),
  issuer: imported32_issuerC,
  at: LocalDateTimeFromIsoStringC,
  action: ActivityActionCU
});
export type RFPItemC = typeof rFPItemC;
export type RFPItem = t.TypeOf<RFPItemC>;


export const allIssuerActivityTypeC = [offeringItemC, newsItemC, calendarItemC, documentUploadC, bondRatingItemC, rFPItemC] as const;
export const allIssuerActivityTypeNames = [`OfferingItem`, `NewsItem`, `CalendarItem`, `DocumentUpload`, `BondRatingItem`, `RFPItem`] as const;
export type IssuerActivityTypeName = (typeof allIssuerActivityTypeNames)[number];

export const IssuerActivityTypeCU = t.union([offeringItemC, newsItemC, calendarItemC, documentUploadC, bondRatingItemC, rFPItemC]);
export type IssuerActivityTypeCU = typeof IssuerActivityTypeCU;
export type IssuerActivityTypeU = t.TypeOf<IssuerActivityTypeCU>;

export type IssuerActivityTypeMap<A> = { [K in IssuerActivityTypeName]: A };


export const issuerActivityC = readonlyMapFromEntries(LocalDateC, LocalDateOrd, readonlyNonEmptyArrayC(IssuerActivityTypeCU));
export type IssuerActivityC = typeof issuerActivityC;
export type IssuerActivity = t.TypeOf<IssuerActivityC>;


