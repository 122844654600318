import type { ComponentType, MutableRefObject, ReactElement, ReactNode } from "react";
import { useCallback, useId, useRef, useState } from "react";
import type {
  ActionMeta,
  ClearActionMeta,
  ClearIndicatorProps,
  CreateOptionActionMeta,
  DeselectOptionActionMeta,
  DropdownIndicatorProps,
  FormatOptionLabelMeta,
  IndicatorSeparatorProps,
  MultiValueProps,
  MultiValueRemoveProps,
  NoticeProps,
  OnChangeValue,
  OptionProps,
  PlaceholderProps,
  PopValueActionMeta,
  Props,
  RemoveValueActionMeta,
  SelectComponentsConfig,
  SelectOptionActionMeta,
  Theme,
  ValueContainerProps,
} from "react-select";
import ReactSelect, { components } from "react-select";
import Creatable from "react-select/creatable";
import type { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import type { default as ReactSelectElement } from "react-select/dist/declarations/src/Select";
import * as E from "fp-ts/lib/Either";
import * as Eq from "fp-ts/lib/Eq";
import { constVoid, flow, type Lazy, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import * as TH from "fp-ts/lib/These";
import { useStableMemo } from "fp-ts-react-stable-hooks";
import V from "voca";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { type Predicate, Struct } from "@scripts/fp-ts";
import { not } from "@scripts/fp-ts/boolean";
import { Svg } from "@scripts/react/components/Svg";
import { useConfig } from "@scripts/react/context/Config";
import type { Codec } from "@scripts/react/form/codecs";
import { codecToUnsafeCodec, isOptionC, isRequiredC, rneaKind } from "@scripts/react/form/codecs";
import type { DataCodec, FormState, ParseValue, UnsafeDataLens, UnsafeFormDataNoCodec, UnsafeFormProp, UpdateStateViaCodecTransitionFn } from "@scripts/react/form/form";
import {
  clearAndSetState,
  getValErrViaCodec,
  getValue,
  parseValue,
  setErrorViaLens,
  useUpdateAndSetStateViaCodecTransition,
} from "@scripts/react/form/form";
import type { UnsafeData } from "@scripts/types/deepPartialWithOptions";
import * as colors from "@scripts/ui/colors";
import { fromNullableOrOption, type TypeOrOptionalType } from "@scripts/util/fromNullableOrOption";
import { getLabel, toLabelString, uniqueAriaId } from "@scripts/util/labelOrAriaLabel";
import type { NonArray } from "@scripts/util/nonArray";
import type { NonOption } from "@scripts/util/nonOption";
import { rotateWithDefaults } from "@scripts/util/rotateElement";
import { fuzzyHighlight, useFuzzySearch } from "@scripts/util/uFuzzy";

import { form as f } from "@styles/components/_form";

import closeX from "@svgs/close-x.svg";
import searchIcon from "@svgs/magnifying-glass.svg";
import triangleSmallDown from "@svgs/triangle-small-down.svg";

import type { KlassList } from "../../util/classnames";
import { klassPropO } from "../../util/classnames";
import { Empty, mapOrEmpty, toEmpty } from "../Empty";
import type { TooltipProps } from "../Tooltip";
import { UnsafeContent } from "../UnsafeContent";
import type { BaseInputProps } from "./Form";
import { LabelEl, valErrMsgElsO } from "./Labels";


export type FormatFn<A, isMulti extends boolean = false> = (option: SelectOption<A, isMulti>, labelMeta: FormatOptionLabelMeta<unknown>) => ReactNode;
export type FormatGroupFn<A, isMulti extends boolean = false> = (group: SelectGroup<A, isMulti>) => ReactElement;

export type SelectOptions<T> = {
  [K in keyof T]: { label: string, value: T[K] }
};

type FormatAll<A, isMulti extends boolean = false> = FormatFn<A, isMulti>;
type FormatMenu<A, isMulti extends boolean = false> = FormatFn<A, isMulti>;
type FormatValue<A, isMulti extends boolean = false> = FormatFn<A, isMulti>;

export type SelectOption<A, isMulti extends boolean = false> = {
  value: A;
  label: string;
  formatLabelOrValueOrAll?: E.Either<TH.These<FormatMenu<A, isMulti>, FormatValue<A, isMulti>>, FormatAll<A, isMulti>>;
};

export type SelectGroup<A, isMulti extends boolean = false> = {
  label: string;
  options: ReadonlyArray<SelectOption<A, isMulti>>;
  formatGroupLabel?: FormatGroupFn<A, isMulti>;
};

export type SelectOptionTypeUnion<A, isMulti extends boolean = false> = SelectGroup<A, isMulti> | SelectOption<A, isMulti>;

// We need to make a custom DropdownIndicator
const DropdownIndicator = (hideIndicator: boolean) => <A, isMulti extends boolean = false>(props: DropdownIndicatorProps<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>>): JSX.Element => {
  return props.isMulti || hideIndicator ? <Empty /> : (
    <components.DropdownIndicator {...props}>
      <Svg src={triangleSmallDown} {...rotateWithDefaults(["icon"])(props.selectProps.menuIsOpen)} />
    </components.DropdownIndicator>
  );
};

const SearchIndicator = <A, isMulti extends boolean = false>(props: DropdownIndicatorProps<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>>) =>
  <components.DropdownIndicator {...props}>
    <Svg src={searchIcon} />
  </components.DropdownIndicator>;

const MultiValueRemove = <A, isMulti extends boolean = false>(props: MultiValueRemoveProps<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>>) =>
  <components.MultiValueRemove {...props}>
    <Svg src={closeX} />
  </components.MultiValueRemove>;

const ClearIndicator = <A, isMulti extends boolean = false>(props: ClearIndicatorProps<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>>) => {
  return (
    <components.ClearIndicator {...props}>
      <Svg src={closeX} />
    </components.ClearIndicator>
  );
};

const NoOptionsMessage = (text: string | undefined) => <A, isMulti extends boolean = false>(props: NoticeProps<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>>): JSX.Element => {
  const children = text ? text : props.children;
  return (
    <components.NoOptionsMessage {...props}>
      {children}
    </components.NoOptionsMessage>
  );
};

const Option = <A, isMulti extends boolean = false>({ children, ...props }: OptionProps<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>>) =>
  <components.Option
    {...{ ...props, innerProps: { ...props.innerProps, onMouseMove: constVoid, onMouseOver: constVoid } }}
  >
    {children}
  </components.Option>;

const optionLabelFormatter = (config: BLConfigWithLog) =>
  <A, isMulti extends boolean = false>(op: SelectOption<A, isMulti>, lb: FormatOptionLabelMeta<SelectOption<A, isMulti>>) => {
    return pipe(
      O.fromNullable(op.formatLabelOrValueOrAll),
      O.chain(
        E.fold(
          c => {
            switch (lb.context) {
              case "menu": return TH.getLeft(c);
              case "value": return TH.getRight(c);
            }
            return config.exhaustive(lb.context);
          },
          O.some
        )
      ),
      O.map(d => d(op, lb)),
      O.getOrElse<ReactNode>(() => op.label)
    );
  };

const groupLabelFormatter = <A, isMulti extends boolean = false>(group: SelectGroup<A, isMulti>) => {
  return group.formatGroupLabel ? group.formatGroupLabel(group) : group.label;
};

const splitPasted = (val: string): string[] => val.trim().split(/[,\s]+/);

export const parsePastedOptions = (value: ReadonlyArray<SelectOption<string, true>>) => pipe(
  value,
  RA.last,
  O.filterMap(last => splitPasted(last.value).length > 1 ? O.some(splitPasted(last.value)) : O.none),
  E.fromOption(() => value),
  E.map(arr => pipe(
    value,
    RA.dropRight(1),
    RA.concat(arr.map((email) => ({ value: email, label: email }))),
  )),
  E.fold(
    (noPaste) => noPaste.filter((option) => /\w/.test(option.value)),
    (pasted) => pasted
  ),
  RA.map(g => g.value),
  RNEA.fromReadonlyArray,
);

export type SelectOptionArray<A, isMulti extends boolean = false> = ReadonlyArray<SelectOptionTypeUnion<A, isMulti>>;
export type SelectValueOnChange<A, isMulti extends boolean = false> = isMulti extends true
  ? ReadonlyArray<SelectOption<A, isMulti>>
  : SelectOption<A, isMulti>;

export type ActionMetaU<Option> = SelectOptionActionMeta<Option> | DeselectOptionActionMeta<Option> | RemoveValueActionMeta<Option> | PopValueActionMeta<Option> | CreateOptionActionMeta<Option>;
type _SelectActionOnChange<A, isMulti extends boolean = false> = ActionMeta<SelectOption<A, isMulti>>;
export type SelectActionOnChange<A, isMulti extends boolean = false> = ActionMetaU<SelectOption<A, isMulti>>;
export type SelectActionOnClear<A, isMulti extends boolean = false> = ClearActionMeta<SelectOption<A, isMulti>>;

export type SelectOnChange<A, isMulti extends boolean = false> = (value: SelectValueOnChange<A, isMulti>, action: SelectActionOnChange<A, isMulti>) => void;
export type SelectOnClear<A, isMulti extends boolean = false> = (action: SelectActionOnClear<A, isMulti>) => void;

export type SelectRawProps<A, isMulti extends boolean = false> = {
  value: O.Option<SelectValueOnChange<A, isMulti>>;
  disabled?: boolean;
  error: O.Option<ReactElement>;
  isCreatable?: true;
  isSearchable: boolean;
  isMulti?: isMulti;
  disableRemove?: boolean;
  disableSelectOnBlur?: true;
  klasses?: KlassList;
  labelOrAriaLabel: E.Either<string, string>;
  noOptionsText?: string;
  onChange: SelectOnChange<A, isMulti>;
  formatCreateLabel?: (inputValue: string) => ReactNode;
  placeholder: O.Option<string>;
  required: boolean;
  tooltip?: TooltipProps;
  multiValueKlass?: (props: MultiValueProps<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>>) => string;
  options: ReadonlyArray<SelectOptionTypeUnion<A, isMulti>>;
  isMinimal?: true;
  width?: string;
  searchIcon?: true;
  tabIndex?: number;
  contentEditable?: true;
  requireInputToOpen?: boolean;
  maxOptionsShowing?: number;
  onClear?: SelectOnClear<A, isMulti>;
  dropdownIndicator?: ComponentType<DropdownIndicatorProps<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>>>;
  placeholderComponent?: ComponentType<PlaceholderProps<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>>>;
  valueContainer?: ComponentType<ValueContainerProps<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>>>;
  indicatorSeparator?: ComponentType<IndicatorSeparatorProps<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>>>;
  controlShouldRenderValue?: boolean;
  isFormInput?: boolean;
};

const isClearableSelect = <A, isMulti extends boolean = false>(props: object): props is { onClear: SelectOnClear<A, isMulti> } =>
  "onClear" in props && typeof props.onClear === "function";

type ReactSelectElementRef<A, isMulti extends boolean = false> = ReactSelectElement<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>> | null;

export const SelectRaw = <A, isMulti extends boolean = false>(props: SelectRawProps<A, isMulti>) => {
  const config = useConfig();
  const selectEl = useRef<ReactSelectElementRef<A, isMulti>>(null);
  const instanceId = useId();
  const isClearable = isClearableSelect(props);
  const { fuzzyResult, fuzzySearch } = useFuzzySearch(props.options, (op) => op.label);
  const [currentInput, setCurrentInput] = useState("");

  const filterOption = useCallback(
    (option: FilterOptionOption<SelectOptionTypeUnion<A, isMulti>>, inputValue: string): boolean => {
      if (V.isEmpty(inputValue)) {
        return true;
      }

      if (props.isCreatable && ("__isNew__" in option.data && option.data.__isNew__)) {
        return true;
      }

      return fuzzyResult.some(_ => _.item.label === option.label);
    }, [fuzzyResult, props.isCreatable]
  );
  const onInputChange = (newValue: string) => {
    setCurrentInput(newValue);
    fuzzySearch(newValue);
  };

  const onChange = (
    value: OnChangeValue<SelectOption<A, isMulti>, isMulti>,
    action: _SelectActionOnChange<A, isMulti>,
  ) => {
    if (action.action === "clear" && isClearable) {
      props.onClear(action);
    } else if (value !== null) {
      // Discriminating conditional types does not work
      // This is an issue that is known https://github.com/microsoft/TypeScript/issues/30581
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      props.onChange(value as SelectValueOnChange<A, isMulti>, action as SelectActionOnChange<A, isMulti>);
    }
  };
  const hideIndicator = (props.requireInputToOpen ?? false) && V.isEmpty(currentInput);
  const selectComponents: SelectComponentsConfig<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>> = {
    DropdownIndicator: props.searchIcon ? SearchIndicator : DropdownIndicator(hideIndicator),
    MultiValueRemove,
    Option,
    ...(props.dropdownIndicator && { DropdownIndicator: props.dropdownIndicator }),
    ...(props.placeholderComponent && { Placeholder: props.placeholderComponent }),
    ...(props.valueContainer && { ValueContainer: props.valueContainer }),
    ...(isClearable && { ClearIndicator }),
    ...{ NoOptionsMessage: NoOptionsMessage(props.noOptionsText) },
    ...((props.isMulti || hideIndicator) && { IndicatorSeparator: Empty }),
    ...(props.indicatorSeparator && { IndicatorSeparator: props.indicatorSeparator }),
  };

  const options: ReadonlyArray<SelectOptionTypeUnion<A, isMulti>> = props.isSearchable
    ? (V.isEmpty(currentInput)
      ? props.options
      : fuzzyResult.map(_ => ({
        ..._.item,
        formatLabelOrValueOrAll: E.left(TH.left(__ =>
          <UnsafeContent content={fuzzyHighlight(__.label, _.highlightRanges)} />
        )),
      }))
    ) : props.options;

  const controlShouldRenderValue = props.controlShouldRenderValue ?? true;

  const localProps: Props<SelectOption<A, isMulti>, isMulti, SelectGroup<A, isMulti>> & { ref: MutableRefObject<ReactSelectElementRef<A, isMulti>> } = {
    "aria-label": toLabelString(props.labelOrAriaLabel),
    "aria-labelledby": O.toUndefined(getLabel(props.labelOrAriaLabel)),
    className: `bl-react-select-container ${props.disableRemove ? "remove-disabled" : ""}`,
    classNamePrefix: "bl-react-select",
    components: selectComponents,
    controlShouldRenderValue,
    value: O.toNullable(props.value),
    filterOption,
    formatOptionLabel: optionLabelFormatter(config),
    formatGroupLabel: groupLabelFormatter,
    isClearable: isClearable,
    isDisabled: props.disabled,
    isMulti: props.isMulti,
    isSearchable: props.isSearchable,
    onChange,
    onInputChange,
    onBlur: props.disableSelectOnBlur ? constVoid : () => {
      // Update in onBlur clobbers touch event on mobile https://github.com/JedWatson/react-select/issues/3737
      globalThis.setTimeout(() => {
        if (!props.isMulti && selectEl.current !== null) {
          const selectState = selectEl.current.state;

          if (selectState.focusedOption) {
            const focusedOption = selectState.focusedOption;

            pipe(
              props.options,
              RA.findFirst(option => option.label === focusedOption.label),
              O.fold(
                () => {
                  if (props.isCreatable && ("__isNew__" in focusedOption && focusedOption.__isNew__)) {
                    const value = { value: currentInput, label: currentInput };

                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    onChange(value as OnChangeValue<SelectOption<A, isMulti>, isMulti>, { action: "create-option", option: value } as SelectActionOnChange<A, isMulti>);
                  }
                },
                (value) =>
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  onChange(value as OnChangeValue<SelectOption<A, isMulti>, isMulti>, { action: "select-option", option: value } as SelectActionOnChange<A, isMulti>)
              )
            );
          }
        }
      }, 0);
    },
    options: pipe(
      props.maxOptionsShowing,
      O.fromNullable,
      O.fold(
        () => options,
        (n: number) => RA.takeLeft(n)(options)
      )
    ),
    placeholder: O.getOrElse(() => "")(props.placeholder),
    ref: selectEl,
    theme: (theme: Theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: colors.blue[100],
        primary50: colors.blue[100],
        primary: colors.blue[700],
      },
    }),
    tabIndex: props.tabIndex,
    menuPlacement: "auto",
    menuIsOpen: pipe(
      props.requireInputToOpen,
      O.fromNullable,
      O.map((r) => r && !V.isEmpty(currentInput)),
      O.toUndefined
    ),
    getOptionValue: opt => JSON.stringify(opt.value),
  };

  const widthStyle = props.width ? { width: props.width } : {};
  const isFormInput = props.isFormInput ?? true;

  return (
    <div
      {...klassPropO([
        "bl-react-select-wrapper",
        O.map(() => f[".form-input"].attrs[".has-danger"])(props.error),
        props.isMinimal ? "minimal" : isFormInput ? "form-input" : "",
      ])(props.klasses)}
      style={{ ...widthStyle }}
      contentEditable={props.contentEditable}
      suppressContentEditableWarning
    >
      {pipe(
        getLabel(props.labelOrAriaLabel),
        mapOrEmpty(l =>
          <LabelEl label={l} id={uniqueAriaId(l)} required={props.required} tooltip={props.tooltip} />
        )
      )}
      {props.isCreatable
        ? (
          <Creatable
            {...localProps}
            classNames={{ multiValue: props.multiValueKlass }}
            formatCreateLabel={props.formatCreateLabel}
            instanceId={instanceId}
          />
        ) : (
          <ReactSelect
            {...localProps}
            instanceId={instanceId}
          />
        )}
      {toEmpty(props.error)}
    </div>
  );
};

export type SelectPropsBase<PC extends DataCodec, KV, InnerKV = KV, KVLinked = unknown, isMulti extends boolean = false> =
  BaseInputProps<PC, NonArray<KV>, KVLinked>
  & Omit<SelectRawProps<NonArray<KV>, isMulti>, "value" | "required" | "onChange" | "isMulti" | "error" | "options" | "onClear">
  & {
    options: ReadonlyArray<SelectOptionTypeUnion<NonArray<InnerKV>, isMulti>>;
    requireInputToOpen?: boolean;
    maxOptionsShowing?: number;
  } & ({ isClearable?: true, onClear?: Lazy<void> } | { isClearable: false, onClear?: never });

export type SelectProps<PC extends DataCodec, KV, InnerKV = KV, KVLinked = unknown> =
  SelectPropsBase<PC, KV, InnerKV, KVLinked, false>;

export type SelectMultiProps<PC extends DataCodec, KV, OKV, InnerKV = KV, KVLinked = unknown> =
  Omit<SelectPropsBase<PC, KV, InnerKV, KVLinked, true>, "lens"> & {
    outerCodec: (ic: Codec<NonArray<KV>>) => Codec<OKV>;
    lens: UnsafeDataLens<PC, UnsafeFormProp<UnsafeFormDataNoCodec<OKV>>>;
    someFn: (pred: Predicate<NonArray<KV>>) => (as: UnsafeFormDataNoCodec<OKV>) => boolean;
    makeOKV: (c: ReadonlyArray<SelectOption<NonArray<KV>, true>>) => UnsafeFormDataNoCodec<OKV>;
  };

function isSelectOption<A, isMulti extends boolean = false>(option: unknown): option is SelectOption<A, isMulti> {
  return Struct.is(option) && option["value"] != null && option["label"] != null;
}

const flattenOptions = <KV,>(options: ReadonlyArray<SelectOptionTypeUnion<KV>>) => {
  let localFlatOptions: ReadonlyArray<SelectOption<KV>> = [];

  options.forEach((o: SelectOptionTypeUnion<KV>) => {
    if (isSelectOption(o)) {
      localFlatOptions = [...localFlatOptions, o];
    } else {
      localFlatOptions = [...localFlatOptions, ...o.options];
    }
  });
  return localFlatOptions;
};

const useSelectedOption = <PC extends DataCodec, KV>(
  codecEq: Eq.Eq<UnsafeFormProp<NonArray<KV>>>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<NonArray<KV>>>,
  options: ReadonlyArray<SelectOptionTypeUnion<NonArray<KV>, false>>,
  state: FormState<PC>,
) => {
  const value = getValue(state, lens);
  const [localValue, updateStateTransition] = useUpdateAndSetStateViaCodecTransition<NonArray<KV>>(value, codecEq);

  const selectedOption = useStableMemo((): O.Option<SelectValueOnChange<NonArray<KV>>> => pipe(
    localValue,
    O.chain(
      v => pipe(
        flattenOptions(options),
        RA.findFirst(g => codecEq.equals(v, g.value)),
      )
    )
  ), [codecEq, localValue, options], Eq.tuple(Eq.eqStrict, O.getEq(codecEq), RA.getEq(Eq.eqStrict)));

  return [selectedOption, updateStateTransition] as const;
};

export const someFnBase = <KV,>(p: Predicate<KV>): (as: TypeOrOptionalType<UnsafeData<ReadonlyArray<KV>>>) => boolean => flow(
  fromNullableOrOption,
  O.exists(RA.some(p))
);

const useSelectedOptions = <PC extends DataCodec, KV, OKV>(
  outerEq: Eq.Eq<UnsafeFormDataNoCodec<OKV>>,
  innerEq: Eq.Eq<UnsafeFormProp<NonArray<KV>>>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<UnsafeFormDataNoCodec<OKV>>>,
  someFn: (predicate: Predicate<NonArray<KV>>) => (as: UnsafeFormDataNoCodec<OKV>) => boolean,
  options: ReadonlyArray<SelectOptionTypeUnion<NonArray<KV>, true>>,
  state: FormState<PC>,
) => {
  const value = getValue(state, lens);
  const [localValue, updateStateTransition] = useUpdateAndSetStateViaCodecTransition(value, outerEq);

  const selectedOption = useStableMemo((): O.Option<SelectValueOnChange<NonArray<KV>, true>> => pipe(
    localValue,
    O.map(
      v => pipe(
        flattenOptions(options),
        RA.filter(g => someFn((a: NonArray<KV>) => innerEq.equals(a, g.value))(v)),
      )
    ),
    O.filter(RA.isNonEmpty)
  ), [innerEq, localValue, options, someFn], Eq.tuple(Eq.eqStrict, O.getEq(outerEq), RA.getEq(Eq.eqStrict), Eq.eqStrict));

  return [selectedOption, updateStateTransition] as const;
};

const useHandleChange = <PC extends DataCodec, KV, KVLinked>(
  codec: Codec<NonArray<KV>>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<NonArray<KV>>>,
  setState: React.Dispatch<React.SetStateAction<FormState<PC>>>,
  updateStateTransition: UpdateStateViaCodecTransitionFn<NonArray<KV>>,
  effect?: (c: SelectOption<NonArray<KV>>, a: SelectActionOnChange<KV>) => void,
  linked?: UnsafeDataLens<PC, KVLinked>
) => {
  const handleChange = useCallback(
    (c: SelectOption<NonArray<KV>>, a: SelectActionOnChange<KV>) => {
      updateStateTransition(setState, lens, codec, linked)(c.value);
      effect?.(c, a);
    },
    [codec, effect, lens, linked, setState, updateStateTransition]
  );

  return handleChange;
};

type CreatableEffectFn<KV> = (c: ReadonlyArray<SelectOption<NonArray<KV>, true>>, a: SelectActionOnChange<NonArray<KV>, true>) => void;
const useHandleChangeMulti = <PC extends DataCodec, KV, OKV>(
  codec: Codec<UnsafeFormDataNoCodec<OKV>>,
  lens: UnsafeDataLens<PC, UnsafeFormProp<UnsafeFormDataNoCodec<OKV>>>,
  setState: React.Dispatch<React.SetStateAction<FormState<PC>>>,
  updateStateTransition: UpdateStateViaCodecTransitionFn<UnsafeFormDataNoCodec<OKV>>,
  makeOKV: (c: ReadonlyArray<SelectOption<NonArray<KV>, true>>) => UnsafeFormDataNoCodec<OKV>,
  handleClear: Lazy<void>,
  effect?: CreatableEffectFn<KV>,
) => {
  const handleChange = useCallback(
    (c: ReadonlyArray<SelectOption<NonArray<KV>, true>>, a: SelectActionOnChange<NonArray<KV>, true>) => {
      O.fold(
        handleClear,
        (r: RNEA.ReadonlyNonEmptyArray<SelectOption<NonArray<KV>, true>>) => updateStateTransition(setState, lens, codec)(makeOKV(r))
      )(RNEA.fromReadonlyArray(c));
      effect?.(c, a);
    },
    [makeOKV, handleClear, updateStateTransition, setState, lens, codec, effect]
  );

  return handleChange;
};

export const handleClear = <PC extends DataCodec, KV>(
  lens: UnsafeDataLens<PC, UnsafeFormProp<KV>>,
  setState: React.Dispatch<React.SetStateAction<FormState<PC>>>,
  onClear?: Lazy<void>,
) => () => {
  clearAndSetState(setState, lens);
  onClear?.();
};

export const onClearFromPredicate = <A, isMulti extends boolean = false>(required: boolean, onClear: SelectOnClear<A, isMulti>, overrideClearable?: boolean) => pipe(
  overrideClearable,
  O.fromNullable,
  O.getOrElse(() => not(required))
) ? { onClear } : {};

export function Select<PC extends DataCodec, InnerKV, KVLinked = unknown>(props: SelectProps<PC, O.Option<InnerKV>, InnerKV, KVLinked>): JSX.Element;
export function Select<PC extends DataCodec, KV, KVLinked = unknown>(props: SelectProps<PC, NonOption<KV>, NonOption<KV>, KVLinked>): JSX.Element;
export function Select<PC extends DataCodec, KV, KVLinked = unknown>(props: SelectProps<PC, KV, KV, KVLinked>): JSX.Element {
  const config = useConfig();
  const ve = getValErrViaCodec(config)(props.state, props.lens, props.codec, "label");
  const [selectedOption, updateStateTransition] =
    useSelectedOption(props.codec.eq, props.lens, props.options, props.state);

  const handleChange = useHandleChange(props.codec, props.lens, props.setState, updateStateTransition, constVoid, props.linked);
  const required = props.requiredOverride ?? isRequiredC(props.codec);
  return (
    <SelectRaw
      {...props}
      {...onClearFromPredicate(required, handleClear(props.lens, props.setState, props.onClear), props.isClearable)}
      options={props.options}
      value={selectedOption}
      onChange={handleChange}
      error={valErrMsgElsO(O.getLeft(props.labelOrAriaLabel), ve.val)(ve.err)}
      required={required}
    />
  );
}

const decodeOption = <KV, isMulti extends boolean>(option: SelectOption<unknown, isMulti>, codec: Codec<NonArray<KV>>): ParseValue<SelectOption<NonArray<KV>, isMulti>> => pipe(
  parseValue(codec)(option.value),
  E.map(v => ({
    ...option,
    value: v,
  }))
);

export function SelectMulti<PC extends DataCodec, InnerKV, OKV>(props: SelectMultiProps<PC, O.Option<InnerKV>, OKV, InnerKV>): JSX.Element;
export function SelectMulti<PC extends DataCodec, KV, OKV>(props: SelectMultiProps<PC, NonOption<KV>, OKV>): JSX.Element;
export function SelectMulti<PC extends DataCodec, KV, OKV>(props: SelectMultiProps<PC, KV, OKV>): JSX.Element {
  const config = useConfig();
  const codec = pipe(props.codec, props.outerCodec, codecToUnsafeCodec);
  const ve = getValErrViaCodec(config)(props.state, props.lens, codec, "label");

  const [options, setOptions] = useState(props.options);
  const [selectedOptions, updateStateTransition] =
    useSelectedOptions(codec.eq, props.codec.eq, props.lens, props.someFn, options, props.state);

  const effectIfCreatable: CreatableEffectFn<KV> = props.isCreatable ? (_, a) => {
    if (a.action === "create-option") {
      pipe(
        decodeOption(a.option, props.codec),
        E.fold(
          RA.map(setErrorViaLens(props.setState, props.lens)),
          option => [setOptions(RA.append<SelectOptionTypeUnion<NonArray<KV>, true>>(option))]
        )
      );
    }
  } : constVoid;

  const clearFn = handleClear(props.lens, props.setState);

  const handleChange = useHandleChangeMulti(
    codec,
    props.lens,
    props.setState,
    updateStateTransition,
    props.makeOKV,
    clearFn,
    effectIfCreatable
  );

  const required = props.requiredOverride ?? (O.isSome(RA.findFirst((_: string) => _ === rneaKind)(codec.kind)) && !isOptionC(codec));

  return (
    <SelectRaw
      {...props}
      {...onClearFromPredicate(required, clearFn, props.isClearable)}
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      isMulti={true}
      error={valErrMsgElsO(O.getLeft(props.labelOrAriaLabel), ve.val)(ve.err)}
      required={required}
    />
  );
}

type SelectCreatableProps<PC extends DataCodec, KV, InnerKV = KV> = Omit<SelectProps<PC, KV, InnerKV>, "isCreatable"> & {
  resetOptions: SelectProps<PC, KV, InnerKV>["options"];
};

export function SelectCreatable<PC extends DataCodec, InnerKV>(props: SelectCreatableProps<PC, O.Option<InnerKV>, InnerKV>): JSX.Element;
export function SelectCreatable<PC extends DataCodec, KV>(props: SelectCreatableProps<PC, NonOption<KV>>): JSX.Element;
export function SelectCreatable<PC extends DataCodec, KV>(props: SelectCreatableProps<PC, KV>): JSX.Element {
  const config = useConfig();
  const ve = getValErrViaCodec(config)(props.state, props.lens, props.codec, "label");
  const [options, setOptions] = useState(props.options);

  const [selectedOption, updateStateTransition] =
    useSelectedOption(props.codec.eq, props.lens, options, props.state);

  const handleChange = useHandleChange(
    props.codec,
    props.lens,
    props.setState,
    updateStateTransition,
    (c, a) => {
      if (a.action === "create-option") {
        setOptions(RA.concat<SelectOptionTypeUnion<NonArray<KV>>>([c]));
      }
    }
  );

  const createableSelectHandleClear = () => {
    setOptions(props.resetOptions);
    handleClear(props.lens, props.setState)();
  };

  const required = props.requiredOverride ?? isRequiredC(props.codec);

  return (
    <SelectRaw
      {...props}
      {...onClearFromPredicate(required, createableSelectHandleClear, props.isClearable)}
      isCreatable={true}
      options={options}
      value={selectedOption}
      onChange={handleChange}
      error={valErrMsgElsO(O.getLeft(props.labelOrAriaLabel), ve.val)(ve.err)}
      required={required}
    />
  );
}
