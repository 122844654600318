import { addressC as imported7_addressC } from "./address";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { SectorCU as imported11_SectorCU } from "../domaintables/sectors";

export const issuerPreferencesC = t.type({
  invoiceNotifyEmail: optionFromNullable(t.string),
  customHeaderText1: optionFromNullable(t.string),
  linkedinUrl: optionFromNullable(t.string),
  twitterUrl: optionFromNullable(t.string),
  facebookUrl: optionFromNullable(t.string),
  instagramUrl: optionFromNullable(t.string),
  youtubeUrl: optionFromNullable(t.string),
  customPages: t.number,
  outstandingDebt: optionFromNullable(t.number),
  customFiscalYear: optionFromNullable(t.number)
});
export type IssuerPreferencesC = typeof issuerPreferencesC;
export type IssuerPreferences = t.TypeOf<IssuerPreferencesC>;


export const issuerSettingsC = t.type({
  name: t.string,
  shortName: optionFromNullable(t.string),
  subheader: optionFromNullable(t.string),
  issuerType: imported11_SectorCU,
  email: t.string,
  website: optionFromNullable(t.string),
  phoneNumber: t.string,
  phoneExtension: optionFromNullable(t.string)
});
export type IssuerSettingsC = typeof issuerSettingsC;
export type IssuerSettings = t.TypeOf<IssuerSettingsC>;


export const issuerSocialC = t.type({
  linkedinUrl: optionFromNullable(t.string),
  twitterUrl: optionFromNullable(t.string),
  facebookUrl: optionFromNullable(t.string),
  instagramUrl: optionFromNullable(t.string),
  youtubeUrl: optionFromNullable(t.string)
});
export type IssuerSocialC = typeof issuerSocialC;
export type IssuerSocial = t.TypeOf<IssuerSocialC>;


export const issuerPreferenceDataC = t.type({
  issuer: issuerSettingsC,
  address: optionFromNullable(imported7_addressC),
  social: issuerSocialC
});
export type IssuerPreferenceDataC = typeof issuerPreferenceDataC;
export type IssuerPreferenceData = t.TypeOf<IssuerPreferenceDataC>;


export const issuerPreferencePostDataC = t.type({
  issuer: issuerSettingsC,
  social: issuerSocialC,
  address: optionFromNullable(t.string),
  city: optionFromNullable(t.string),
  state: optionFromNullable(t.number),
  postalCode: optionFromNullable(t.string)
});
export type IssuerPreferencePostDataC = typeof issuerPreferencePostDataC;
export type IssuerPreferencePostData = t.TypeOf<IssuerPreferencePostDataC>;


export const publishPreferencesC = t.type({
  password: optionFromNullable(t.string),
  confirmPassword: optionFromNullable(t.string),
  publish: t.boolean
});
export type PublishPreferencesC = typeof publishPreferencesC;
export type PublishPreferences = t.TypeOf<PublishPreferencesC>;


