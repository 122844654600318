import { WithStatusCU as imported353_WithStatusCU, WithStatusCU as imported352_WithStatusCU, WithStatusCU as imported351_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { mediaC as imported80_mediaC, mediaUploadResponseC as imported27_mediaUploadResponseC } from "./media";
import { bigNumberC } from "../../Big/bigNumberC";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { dataAndIdC as imported11_dataAndIdC, dataAndIdC as imported10_dataAndIdC } from "./relatedContent";

export const bidSubmissionC = t.type({
  rfpId: t.number,
  userId: t.number,
  bidSubmissionTemplateId: t.number,
  firstName: t.string,
  lastName: t.string,
  organization: t.string,
  email: t.string,
  interestRate: optionFromNullable(bigNumberC),
  fees: optionFromNullable(bigNumberC),
  additionalComments: optionFromNullable(t.string)
});
export type BidSubmissionC = typeof bidSubmissionC;
export type BidSubmission = t.TypeOf<BidSubmissionC>;


export const bidSubmissionAnswerC = t.type({
  bidSubmissionId: t.number,
  bidSubmissionTemplateQuestionId: t.number,
  yesOrNo: optionFromNullable(t.boolean),
  text: optionFromNullable(t.string)
});
export type BidSubmissionAnswerC = typeof bidSubmissionAnswerC;
export type BidSubmissionAnswer = t.TypeOf<BidSubmissionAnswerC>;


export const bidSubmissionDocumentC = t.type({
  bidSubmissionId: t.number,
  bidSubmissionTemplateDocumentId: t.number,
  mediaId: optionFromNullable(t.number)
});
export type BidSubmissionDocumentC = typeof bidSubmissionDocumentC;
export type BidSubmissionDocument = t.TypeOf<BidSubmissionDocumentC>;


export const bidSubmissionDocumentDataC = t.type({
  bidSubmissionDocument: bidSubmissionDocumentC,
  media: optionFromNullable(imported353_WithStatusCU(imported80_mediaC))
});
export type BidSubmissionDocumentDataC = typeof bidSubmissionDocumentDataC;
export type BidSubmissionDocumentData = t.TypeOf<BidSubmissionDocumentDataC>;


export const bidSubmissionDataC = t.type({
  bidSubmission: bidSubmissionC,
  answers: t.readonlyArray(imported351_WithStatusCU(bidSubmissionAnswerC)),
  documents: t.readonlyArray(imported352_WithStatusCU(bidSubmissionDocumentDataC))
});
export type BidSubmissionDataC = typeof bidSubmissionDataC;
export type BidSubmissionData = t.TypeOf<BidSubmissionDataC>;


export const bidSubmissionDocumentPostC = t.type({
  media: optionFromNullable(imported27_mediaUploadResponseC),
  bidSubmissionTemplateDocumentId: t.number
});
export type BidSubmissionDocumentPostC = typeof bidSubmissionDocumentPostC;
export type BidSubmissionDocumentPost = t.TypeOf<BidSubmissionDocumentPostC>;


export const bidSubmissionAnswerPostC = t.type({
  bidSubmissionTemplateQuestionId: t.number,
  yesOrNo: optionFromNullable(t.boolean),
  text: optionFromNullable(t.string)
});
export type BidSubmissionAnswerPostC = typeof bidSubmissionAnswerPostC;
export type BidSubmissionAnswerPost = t.TypeOf<BidSubmissionAnswerPostC>;


export const bidSubmissionPostRelatedContentC = t.type({
  documents: t.readonlyArray(imported10_dataAndIdC(bidSubmissionDocumentPostC)),
  answers: t.readonlyArray(imported11_dataAndIdC(bidSubmissionAnswerPostC))
});
export type BidSubmissionPostRelatedContentC = typeof bidSubmissionPostRelatedContentC;
export type BidSubmissionPostRelatedContent = t.TypeOf<BidSubmissionPostRelatedContentC>;


export const bidSubmissionPostC = t.type({
  id: optionFromNullable(t.number),
  rfpId: t.number,
  userId: t.number,
  bidSubmissionTemplateId: t.number,
  firstName: t.string,
  lastName: t.string,
  organization: t.string,
  email: t.string,
  interestRate: optionFromNullable(bigNumberC),
  fees: optionFromNullable(bigNumberC),
  additionalComments: optionFromNullable(t.string),
  relatedContent: bidSubmissionPostRelatedContentC
});
export type BidSubmissionPostC = typeof bidSubmissionPostC;
export type BidSubmissionPost = t.TypeOf<BidSubmissionPostC>;


