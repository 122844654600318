import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { issuerRatingC as imported11_issuerRatingC, programRatingC as imported11_programRatingC } from "./ratingBase";
import { RatingAgencyCU as imported31_RatingAgencyCU, RatingAgencyCU as imported32_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { mediaC as imported49_mediaC } from "./media";
import { bondProgramC as imported14_bondProgramC } from "./bondProgramBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { WithStatusCU as imported212_WithStatusCU, WithStatusCU as imported213_WithStatusCU, withIdC as imported203_withIdC } from "./threadThrough";
import { issuerC as imported35_issuerC } from "./issuer";

export const issuerOverviewCardC = t.type({
  issuer: imported35_issuerC,
  offerings: t.number,
  issuerRatings: t.readonlyArray(t.tuple([imported31_RatingAgencyCU, readonlyNonEmptyArrayC(imported212_WithStatusCU(imported11_issuerRatingC))])),
  programRatings: t.readonlyArray(t.tuple([imported203_withIdC(imported14_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported32_RatingAgencyCU, readonlyNonEmptyArrayC(imported213_WithStatusCU(imported11_programRatingC))]))])),
  subscribed: t.boolean,
  media: optionFromNullable(imported49_mediaC),
  outstandingDebt: optionFromNullable(t.number)
});
export type IssuerOverviewCardC = typeof issuerOverviewCardC;
export type IssuerOverviewCard = t.TypeOf<IssuerOverviewCardC>;


export const issuerOverviewCardsC = t.type({
  cards: t.readonlyArray(issuerOverviewCardC),
  nextPage: t.boolean
});
export type IssuerOverviewCardsC = typeof issuerOverviewCardsC;
export type IssuerOverviewCards = t.TypeOf<IssuerOverviewCardsC>;


