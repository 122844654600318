import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const address = {
  _tag: `Address`,
  name: `Address`
} as const;

export const addressTaggedC = t.type({
  _tag: t.literal(`Address`)
});
export type AddressTaggedC = typeof addressTaggedC;
export type AddressTagged = t.TypeOf<AddressTaggedC>;
export type Address = AddressTagged & typeof address;
export const addressC = pipe(addressTaggedC, c => new t.Type<Address, AddressTagged>(
  `Address`,
  (u: unknown): u is Address => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Address> => pipe(c.decode(u), E.map(x => ({ ...x, ...address }))),
  (x: Address): AddressTagged => ({ ...x, _tag: `Address`}),
));
export type AddressC = typeof addressC;


export const media = {
  _tag: `Media`,
  name: `Media`
} as const;

export const mediaTaggedC = t.type({
  _tag: t.literal(`Media`)
});
export type MediaTaggedC = typeof mediaTaggedC;
export type MediaTagged = t.TypeOf<MediaTaggedC>;
export type Media = MediaTagged & typeof media;
export const mediaC = pipe(mediaTaggedC, c => new t.Type<Media, MediaTagged>(
  `Media`,
  (u: unknown): u is Media => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Media> => pipe(c.decode(u), E.map(x => ({ ...x, ...media }))),
  (x: Media): MediaTagged => ({ ...x, _tag: `Media`}),
));
export type MediaC = typeof mediaC;


export const emmaDoc = {
  _tag: `EmmaDoc`,
  name: `EmmaDocument`
} as const;

export const emmaDocTaggedC = t.type({
  _tag: t.literal(`EmmaDoc`)
});
export type EmmaDocTaggedC = typeof emmaDocTaggedC;
export type EmmaDocTagged = t.TypeOf<EmmaDocTaggedC>;
export type EmmaDoc = EmmaDocTagged & typeof emmaDoc;
export const emmaDocC = pipe(emmaDocTaggedC, c => new t.Type<EmmaDoc, EmmaDocTagged>(
  `EmmaDoc`,
  (u: unknown): u is EmmaDoc => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmmaDoc> => pipe(c.decode(u), E.map(x => ({ ...x, ...emmaDoc }))),
  (x: EmmaDoc): EmmaDocTagged => ({ ...x, _tag: `EmmaDoc`}),
));
export type EmmaDocC = typeof emmaDocC;


export const homepageImg = {
  _tag: `HomepageImg`,
  name: `HomepageImg`
} as const;

export const homepageImgTaggedC = t.type({
  _tag: t.literal(`HomepageImg`)
});
export type HomepageImgTaggedC = typeof homepageImgTaggedC;
export type HomepageImgTagged = t.TypeOf<HomepageImgTaggedC>;
export type HomepageImg = HomepageImgTagged & typeof homepageImg;
export const homepageImgC = pipe(homepageImgTaggedC, c => new t.Type<HomepageImg, HomepageImgTagged>(
  `HomepageImg`,
  (u: unknown): u is HomepageImg => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HomepageImg> => pipe(c.decode(u), E.map(x => ({ ...x, ...homepageImg }))),
  (x: HomepageImg): HomepageImgTagged => ({ ...x, _tag: `HomepageImg`}),
));
export type HomepageImgC = typeof homepageImgC;


export const calloutField = {
  _tag: `CalloutField`,
  name: `CalloutField`
} as const;

export const calloutFieldTaggedC = t.type({
  _tag: t.literal(`CalloutField`)
});
export type CalloutFieldTaggedC = typeof calloutFieldTaggedC;
export type CalloutFieldTagged = t.TypeOf<CalloutFieldTaggedC>;
export type CalloutField = CalloutFieldTagged & typeof calloutField;
export const calloutFieldC = pipe(calloutFieldTaggedC, c => new t.Type<CalloutField, CalloutFieldTagged>(
  `CalloutField`,
  (u: unknown): u is CalloutField => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CalloutField> => pipe(c.decode(u), E.map(x => ({ ...x, ...calloutField }))),
  (x: CalloutField): CalloutFieldTagged => ({ ...x, _tag: `CalloutField`}),
));
export type CalloutFieldC = typeof calloutFieldC;


export const textItem = {
  _tag: `TextItem`,
  name: `TextItem`
} as const;

export const textItemTaggedC = t.type({
  _tag: t.literal(`TextItem`)
});
export type TextItemTaggedC = typeof textItemTaggedC;
export type TextItemTagged = t.TypeOf<TextItemTaggedC>;
export type TextItem = TextItemTagged & typeof textItem;
export const textItemC = pipe(textItemTaggedC, c => new t.Type<TextItem, TextItemTagged>(
  `TextItem`,
  (u: unknown): u is TextItem => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TextItem> => pipe(c.decode(u), E.map(x => ({ ...x, ...textItem }))),
  (x: TextItem): TextItemTagged => ({ ...x, _tag: `TextItem`}),
));
export type TextItemC = typeof textItemC;


export const disclaimer = {
  _tag: `Disclaimer`,
  name: `Disclaimer`
} as const;

export const disclaimerTaggedC = t.type({
  _tag: t.literal(`Disclaimer`)
});
export type DisclaimerTaggedC = typeof disclaimerTaggedC;
export type DisclaimerTagged = t.TypeOf<DisclaimerTaggedC>;
export type Disclaimer = DisclaimerTagged & typeof disclaimer;
export const disclaimerC = pipe(disclaimerTaggedC, c => new t.Type<Disclaimer, DisclaimerTagged>(
  `Disclaimer`,
  (u: unknown): u is Disclaimer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Disclaimer> => pipe(c.decode(u), E.map(x => ({ ...x, ...disclaimer }))),
  (x: Disclaimer): DisclaimerTagged => ({ ...x, _tag: `Disclaimer`}),
));
export type DisclaimerC = typeof disclaimerC;


export const rating = {
  _tag: `Rating`,
  name: `Rating`
} as const;

export const ratingTaggedC = t.type({
  _tag: t.literal(`Rating`)
});
export type RatingTaggedC = typeof ratingTaggedC;
export type RatingTagged = t.TypeOf<RatingTaggedC>;
export type Rating = RatingTagged & typeof rating;
export const ratingC = pipe(ratingTaggedC, c => new t.Type<Rating, RatingTagged>(
  `Rating`,
  (u: unknown): u is Rating => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Rating> => pipe(c.decode(u), E.map(x => ({ ...x, ...rating }))),
  (x: Rating): RatingTagged => ({ ...x, _tag: `Rating`}),
));
export type RatingC = typeof ratingC;


export const analyst = {
  _tag: `Analyst`,
  name: `Analyst`
} as const;

export const analystTaggedC = t.type({
  _tag: t.literal(`Analyst`)
});
export type AnalystTaggedC = typeof analystTaggedC;
export type AnalystTagged = t.TypeOf<AnalystTaggedC>;
export type Analyst = AnalystTagged & typeof analyst;
export const analystC = pipe(analystTaggedC, c => new t.Type<Analyst, AnalystTagged>(
  `Analyst`,
  (u: unknown): u is Analyst => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Analyst> => pipe(c.decode(u), E.map(x => ({ ...x, ...analyst }))),
  (x: Analyst): AnalystTagged => ({ ...x, _tag: `Analyst`}),
));
export type AnalystC = typeof analystC;


export const cusip6 = {
  _tag: `Cusip6`,
  name: `Cusip6`
} as const;

export const cusip6TaggedC = t.type({
  _tag: t.literal(`Cusip6`)
});
export type Cusip6TaggedC = typeof cusip6TaggedC;
export type Cusip6Tagged = t.TypeOf<Cusip6TaggedC>;
export type Cusip6 = Cusip6Tagged & typeof cusip6;
export const cusip6C = pipe(cusip6TaggedC, c => new t.Type<Cusip6, Cusip6Tagged>(
  `Cusip6`,
  (u: unknown): u is Cusip6 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip6> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip6 }))),
  (x: Cusip6): Cusip6Tagged => ({ ...x, _tag: `Cusip6`}),
));
export type Cusip6C = typeof cusip6C;


export const emma = {
  _tag: `Emma`,
  name: `Emma`
} as const;

export const emmaTaggedC = t.type({
  _tag: t.literal(`Emma`)
});
export type EmmaTaggedC = typeof emmaTaggedC;
export type EmmaTagged = t.TypeOf<EmmaTaggedC>;
export type Emma = EmmaTagged & typeof emma;
export const emmaC = pipe(emmaTaggedC, c => new t.Type<Emma, EmmaTagged>(
  `Emma`,
  (u: unknown): u is Emma => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Emma> => pipe(c.decode(u), E.map(x => ({ ...x, ...emma }))),
  (x: Emma): EmmaTagged => ({ ...x, _tag: `Emma`}),
));
export type EmmaC = typeof emmaC;


export const relatedIssuer = {
  _tag: `RelatedIssuer`,
  name: `RelatedIssuer`
} as const;

export const relatedIssuerTaggedC = t.type({
  _tag: t.literal(`RelatedIssuer`)
});
export type RelatedIssuerTaggedC = typeof relatedIssuerTaggedC;
export type RelatedIssuerTagged = t.TypeOf<RelatedIssuerTaggedC>;
export type RelatedIssuer = RelatedIssuerTagged & typeof relatedIssuer;
export const relatedIssuerC = pipe(relatedIssuerTaggedC, c => new t.Type<RelatedIssuer, RelatedIssuerTagged>(
  `RelatedIssuer`,
  (u: unknown): u is RelatedIssuer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RelatedIssuer> => pipe(c.decode(u), E.map(x => ({ ...x, ...relatedIssuer }))),
  (x: RelatedIssuer): RelatedIssuerTagged => ({ ...x, _tag: `RelatedIssuer`}),
));
export type RelatedIssuerC = typeof relatedIssuerC;


export const calendar = {
  _tag: `Calendar`,
  name: `Calendar`
} as const;

export const calendarTaggedC = t.type({
  _tag: t.literal(`Calendar`)
});
export type CalendarTaggedC = typeof calendarTaggedC;
export type CalendarTagged = t.TypeOf<CalendarTaggedC>;
export type Calendar = CalendarTagged & typeof calendar;
export const calendarC = pipe(calendarTaggedC, c => new t.Type<Calendar, CalendarTagged>(
  `Calendar`,
  (u: unknown): u is Calendar => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Calendar> => pipe(c.decode(u), E.map(x => ({ ...x, ...calendar }))),
  (x: Calendar): CalendarTagged => ({ ...x, _tag: `Calendar`}),
));
export type CalendarC = typeof calendarC;


export const news = {
  _tag: `News`,
  name: `News`
} as const;

export const newsTaggedC = t.type({
  _tag: t.literal(`News`)
});
export type NewsTaggedC = typeof newsTaggedC;
export type NewsTagged = t.TypeOf<NewsTaggedC>;
export type News = NewsTagged & typeof news;
export const newsC = pipe(newsTaggedC, c => new t.Type<News, NewsTagged>(
  `News`,
  (u: unknown): u is News => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, News> => pipe(c.decode(u), E.map(x => ({ ...x, ...news }))),
  (x: News): NewsTagged => ({ ...x, _tag: `News`}),
));
export type NewsC = typeof newsC;


export const faq = {
  _tag: `Faq`,
  name: `Faq`
} as const;

export const faqTaggedC = t.type({
  _tag: t.literal(`Faq`)
});
export type FaqTaggedC = typeof faqTaggedC;
export type FaqTagged = t.TypeOf<FaqTaggedC>;
export type Faq = FaqTagged & typeof faq;
export const faqC = pipe(faqTaggedC, c => new t.Type<Faq, FaqTagged>(
  `Faq`,
  (u: unknown): u is Faq => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Faq> => pipe(c.decode(u), E.map(x => ({ ...x, ...faq }))),
  (x: Faq): FaqTagged => ({ ...x, _tag: `Faq`}),
));
export type FaqC = typeof faqC;


export const href = {
  _tag: `Href`,
  name: `Href`
} as const;

export const hrefTaggedC = t.type({
  _tag: t.literal(`Href`)
});
export type HrefTaggedC = typeof hrefTaggedC;
export type HrefTagged = t.TypeOf<HrefTaggedC>;
export type Href = HrefTagged & typeof href;
export const hrefC = pipe(hrefTaggedC, c => new t.Type<Href, HrefTagged>(
  `Href`,
  (u: unknown): u is Href => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Href> => pipe(c.decode(u), E.map(x => ({ ...x, ...href }))),
  (x: Href): HrefTagged => ({ ...x, _tag: `Href`}),
));
export type HrefC = typeof hrefC;


export const issuerOfficer = {
  _tag: `IssuerOfficer`,
  name: `IssuerOfficer`
} as const;

export const issuerOfficerTaggedC = t.type({
  _tag: t.literal(`IssuerOfficer`)
});
export type IssuerOfficerTaggedC = typeof issuerOfficerTaggedC;
export type IssuerOfficerTagged = t.TypeOf<IssuerOfficerTaggedC>;
export type IssuerOfficer = IssuerOfficerTagged & typeof issuerOfficer;
export const issuerOfficerC = pipe(issuerOfficerTaggedC, c => new t.Type<IssuerOfficer, IssuerOfficerTagged>(
  `IssuerOfficer`,
  (u: unknown): u is IssuerOfficer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerOfficer> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerOfficer }))),
  (x: IssuerOfficer): IssuerOfficerTagged => ({ ...x, _tag: `IssuerOfficer`}),
));
export type IssuerOfficerC = typeof issuerOfficerC;


export const paymentToken = {
  _tag: `PaymentToken`,
  name: `PaymentToken`
} as const;

export const paymentTokenTaggedC = t.type({
  _tag: t.literal(`PaymentToken`)
});
export type PaymentTokenTaggedC = typeof paymentTokenTaggedC;
export type PaymentTokenTagged = t.TypeOf<PaymentTokenTaggedC>;
export type PaymentToken = PaymentTokenTagged & typeof paymentToken;
export const paymentTokenC = pipe(paymentTokenTaggedC, c => new t.Type<PaymentToken, PaymentTokenTagged>(
  `PaymentToken`,
  (u: unknown): u is PaymentToken => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PaymentToken> => pipe(c.decode(u), E.map(x => ({ ...x, ...paymentToken }))),
  (x: PaymentToken): PaymentTokenTagged => ({ ...x, _tag: `PaymentToken`}),
));
export type PaymentTokenC = typeof paymentTokenC;


export const project = {
  _tag: `Project`,
  name: `Project`
} as const;

export const projectTaggedC = t.type({
  _tag: t.literal(`Project`)
});
export type ProjectTaggedC = typeof projectTaggedC;
export type ProjectTagged = t.TypeOf<ProjectTaggedC>;
export type Project = ProjectTagged & typeof project;
export const projectC = pipe(projectTaggedC, c => new t.Type<Project, ProjectTagged>(
  `Project`,
  (u: unknown): u is Project => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Project> => pipe(c.decode(u), E.map(x => ({ ...x, ...project }))),
  (x: Project): ProjectTagged => ({ ...x, _tag: `Project`}),
));
export type ProjectC = typeof projectC;


export const invoice = {
  _tag: `Invoice`,
  name: `Invoice`
} as const;

export const invoiceTaggedC = t.type({
  _tag: t.literal(`Invoice`)
});
export type InvoiceTaggedC = typeof invoiceTaggedC;
export type InvoiceTagged = t.TypeOf<InvoiceTaggedC>;
export type Invoice = InvoiceTagged & typeof invoice;
export const invoiceC = pipe(invoiceTaggedC, c => new t.Type<Invoice, InvoiceTagged>(
  `Invoice`,
  (u: unknown): u is Invoice => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Invoice> => pipe(c.decode(u), E.map(x => ({ ...x, ...invoice }))),
  (x: Invoice): InvoiceTagged => ({ ...x, _tag: `Invoice`}),
));
export type InvoiceC = typeof invoiceC;


export const offeringParticipant = {
  _tag: `OfferingParticipant`,
  name: `OfferingParticipant`
} as const;

export const offeringParticipantTaggedC = t.type({
  _tag: t.literal(`OfferingParticipant`)
});
export type OfferingParticipantTaggedC = typeof offeringParticipantTaggedC;
export type OfferingParticipantTagged = t.TypeOf<OfferingParticipantTaggedC>;
export type OfferingParticipant = OfferingParticipantTagged & typeof offeringParticipant;
export const offeringParticipantC = pipe(offeringParticipantTaggedC, c => new t.Type<OfferingParticipant, OfferingParticipantTagged>(
  `OfferingParticipant`,
  (u: unknown): u is OfferingParticipant => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingParticipant> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringParticipant }))),
  (x: OfferingParticipant): OfferingParticipantTagged => ({ ...x, _tag: `OfferingParticipant`}),
));
export type OfferingParticipantC = typeof offeringParticipantC;


export const offeringData = {
  _tag: `OfferingData`,
  name: `OfferingData`
} as const;

export const offeringDataTaggedC = t.type({
  _tag: t.literal(`OfferingData`)
});
export type OfferingDataTaggedC = typeof offeringDataTaggedC;
export type OfferingDataTagged = t.TypeOf<OfferingDataTaggedC>;
export type OfferingData = OfferingDataTagged & typeof offeringData;
export const offeringDataC = pipe(offeringDataTaggedC, c => new t.Type<OfferingData, OfferingDataTagged>(
  `OfferingData`,
  (u: unknown): u is OfferingData => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingData> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringData }))),
  (x: OfferingData): OfferingDataTagged => ({ ...x, _tag: `OfferingData`}),
));
export type OfferingDataC = typeof offeringDataC;


export const offeringSeries = {
  _tag: `OfferingSeries`,
  name: `OfferingSeries`
} as const;

export const offeringSeriesTaggedC = t.type({
  _tag: t.literal(`OfferingSeries`)
});
export type OfferingSeriesTaggedC = typeof offeringSeriesTaggedC;
export type OfferingSeriesTagged = t.TypeOf<OfferingSeriesTaggedC>;
export type OfferingSeries = OfferingSeriesTagged & typeof offeringSeries;
export const offeringSeriesC = pipe(offeringSeriesTaggedC, c => new t.Type<OfferingSeries, OfferingSeriesTagged>(
  `OfferingSeries`,
  (u: unknown): u is OfferingSeries => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingSeries> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringSeries }))),
  (x: OfferingSeries): OfferingSeriesTagged => ({ ...x, _tag: `OfferingSeries`}),
));
export type OfferingSeriesC = typeof offeringSeriesC;


export const siteTemplate = {
  _tag: `SiteTemplate`,
  name: `SiteTemplate`
} as const;

export const siteTemplateTaggedC = t.type({
  _tag: t.literal(`SiteTemplate`)
});
export type SiteTemplateTaggedC = typeof siteTemplateTaggedC;
export type SiteTemplateTagged = t.TypeOf<SiteTemplateTaggedC>;
export type SiteTemplate = SiteTemplateTagged & typeof siteTemplate;
export const siteTemplateC = pipe(siteTemplateTaggedC, c => new t.Type<SiteTemplate, SiteTemplateTagged>(
  `SiteTemplate`,
  (u: unknown): u is SiteTemplate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SiteTemplate> => pipe(c.decode(u), E.map(x => ({ ...x, ...siteTemplate }))),
  (x: SiteTemplate): SiteTemplateTagged => ({ ...x, _tag: `SiteTemplate`}),
));
export type SiteTemplateC = typeof siteTemplateC;


export const headerPhoto = {
  _tag: `HeaderPhoto`,
  name: `HeaderPhoto`
} as const;

export const headerPhotoTaggedC = t.type({
  _tag: t.literal(`HeaderPhoto`)
});
export type HeaderPhotoTaggedC = typeof headerPhotoTaggedC;
export type HeaderPhotoTagged = t.TypeOf<HeaderPhotoTaggedC>;
export type HeaderPhoto = HeaderPhotoTagged & typeof headerPhoto;
export const headerPhotoC = pipe(headerPhotoTaggedC, c => new t.Type<HeaderPhoto, HeaderPhotoTagged>(
  `HeaderPhoto`,
  (u: unknown): u is HeaderPhoto => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HeaderPhoto> => pipe(c.decode(u), E.map(x => ({ ...x, ...headerPhoto }))),
  (x: HeaderPhoto): HeaderPhotoTagged => ({ ...x, _tag: `HeaderPhoto`}),
));
export type HeaderPhotoC = typeof headerPhotoC;


export const marketInsight = {
  _tag: `MarketInsight`,
  name: `MarketInsight`
} as const;

export const marketInsightTaggedC = t.type({
  _tag: t.literal(`MarketInsight`)
});
export type MarketInsightTaggedC = typeof marketInsightTaggedC;
export type MarketInsightTagged = t.TypeOf<MarketInsightTaggedC>;
export type MarketInsight = MarketInsightTagged & typeof marketInsight;
export const marketInsightC = pipe(marketInsightTaggedC, c => new t.Type<MarketInsight, MarketInsightTagged>(
  `MarketInsight`,
  (u: unknown): u is MarketInsight => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketInsight> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketInsight }))),
  (x: MarketInsight): MarketInsightTagged => ({ ...x, _tag: `MarketInsight`}),
));
export type MarketInsightC = typeof marketInsightC;


export const nonIceDebtInstrument = {
  _tag: `NonIceDebtInstrument`,
  name: `NonIceDebtInstrument`
} as const;

export const nonIceDebtInstrumentTaggedC = t.type({
  _tag: t.literal(`NonIceDebtInstrument`)
});
export type NonIceDebtInstrumentTaggedC = typeof nonIceDebtInstrumentTaggedC;
export type NonIceDebtInstrumentTagged = t.TypeOf<NonIceDebtInstrumentTaggedC>;
export type NonIceDebtInstrument = NonIceDebtInstrumentTagged & typeof nonIceDebtInstrument;
export const nonIceDebtInstrumentC = pipe(nonIceDebtInstrumentTaggedC, c => new t.Type<NonIceDebtInstrument, NonIceDebtInstrumentTagged>(
  `NonIceDebtInstrument`,
  (u: unknown): u is NonIceDebtInstrument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NonIceDebtInstrument> => pipe(c.decode(u), E.map(x => ({ ...x, ...nonIceDebtInstrument }))),
  (x: NonIceDebtInstrument): NonIceDebtInstrumentTagged => ({ ...x, _tag: `NonIceDebtInstrument`}),
));
export type NonIceDebtInstrumentC = typeof nonIceDebtInstrumentC;


export const esgImg = {
  _tag: `EsgImg`,
  name: `EsgImg`
} as const;

export const esgImgTaggedC = t.type({
  _tag: t.literal(`EsgImg`)
});
export type EsgImgTaggedC = typeof esgImgTaggedC;
export type EsgImgTagged = t.TypeOf<EsgImgTaggedC>;
export type EsgImg = EsgImgTagged & typeof esgImg;
export const esgImgC = pipe(esgImgTaggedC, c => new t.Type<EsgImg, EsgImgTagged>(
  `EsgImg`,
  (u: unknown): u is EsgImg => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgImg> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgImg }))),
  (x: EsgImg): EsgImgTagged => ({ ...x, _tag: `EsgImg`}),
));
export type EsgImgC = typeof esgImgC;


export const issuerLink = {
  _tag: `IssuerLink`,
  name: `IssuerLink`
} as const;

export const issuerLinkTaggedC = t.type({
  _tag: t.literal(`IssuerLink`)
});
export type IssuerLinkTaggedC = typeof issuerLinkTaggedC;
export type IssuerLinkTagged = t.TypeOf<IssuerLinkTaggedC>;
export type IssuerLink = IssuerLinkTagged & typeof issuerLink;
export const issuerLinkC = pipe(issuerLinkTaggedC, c => new t.Type<IssuerLink, IssuerLinkTagged>(
  `IssuerLink`,
  (u: unknown): u is IssuerLink => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerLink> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerLink }))),
  (x: IssuerLink): IssuerLinkTagged => ({ ...x, _tag: `IssuerLink`}),
));
export type IssuerLinkC = typeof issuerLinkC;


export const rfp = {
  _tag: `Rfp`,
  name: `Rfp`
} as const;

export const rfpTaggedC = t.type({
  _tag: t.literal(`Rfp`)
});
export type RfpTaggedC = typeof rfpTaggedC;
export type RfpTagged = t.TypeOf<RfpTaggedC>;
export type Rfp = RfpTagged & typeof rfp;
export const rfpC = pipe(rfpTaggedC, c => new t.Type<Rfp, RfpTagged>(
  `Rfp`,
  (u: unknown): u is Rfp => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Rfp> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfp }))),
  (x: Rfp): RfpTagged => ({ ...x, _tag: `Rfp`}),
));
export type RfpC = typeof rfpC;


export const reminder = {
  _tag: `Reminder`,
  name: `Reminder`
} as const;

export const reminderTaggedC = t.type({
  _tag: t.literal(`Reminder`)
});
export type ReminderTaggedC = typeof reminderTaggedC;
export type ReminderTagged = t.TypeOf<ReminderTaggedC>;
export type Reminder = ReminderTagged & typeof reminder;
export const reminderC = pipe(reminderTaggedC, c => new t.Type<Reminder, ReminderTagged>(
  `Reminder`,
  (u: unknown): u is Reminder => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Reminder> => pipe(c.decode(u), E.map(x => ({ ...x, ...reminder }))),
  (x: Reminder): ReminderTagged => ({ ...x, _tag: `Reminder`}),
));
export type ReminderC = typeof reminderC;


export const externalLink = {
  _tag: `ExternalLink`,
  name: `ExternalLink`
} as const;

export const externalLinkTaggedC = t.type({
  _tag: t.literal(`ExternalLink`)
});
export type ExternalLinkTaggedC = typeof externalLinkTaggedC;
export type ExternalLinkTagged = t.TypeOf<ExternalLinkTaggedC>;
export type ExternalLink = ExternalLinkTagged & typeof externalLink;
export const externalLinkC = pipe(externalLinkTaggedC, c => new t.Type<ExternalLink, ExternalLinkTagged>(
  `ExternalLink`,
  (u: unknown): u is ExternalLink => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ExternalLink> => pipe(c.decode(u), E.map(x => ({ ...x, ...externalLink }))),
  (x: ExternalLink): ExternalLinkTagged => ({ ...x, _tag: `ExternalLink`}),
));
export type ExternalLinkC = typeof externalLinkC;


export const contentNote = {
  _tag: `ContentNote`,
  name: `ContentNote`
} as const;

export const contentNoteTaggedC = t.type({
  _tag: t.literal(`ContentNote`)
});
export type ContentNoteTaggedC = typeof contentNoteTaggedC;
export type ContentNoteTagged = t.TypeOf<ContentNoteTaggedC>;
export type ContentNote = ContentNoteTagged & typeof contentNote;
export const contentNoteC = pipe(contentNoteTaggedC, c => new t.Type<ContentNote, ContentNoteTagged>(
  `ContentNote`,
  (u: unknown): u is ContentNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ContentNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...contentNote }))),
  (x: ContentNote): ContentNoteTagged => ({ ...x, _tag: `ContentNote`}),
));
export type ContentNoteC = typeof contentNoteC;


export const bondProgram = {
  _tag: `BondProgram`,
  name: `BondProgram`
} as const;

export const bondProgramTaggedC = t.type({
  _tag: t.literal(`BondProgram`)
});
export type BondProgramTaggedC = typeof bondProgramTaggedC;
export type BondProgramTagged = t.TypeOf<BondProgramTaggedC>;
export type BondProgram = BondProgramTagged & typeof bondProgram;
export const bondProgramC = pipe(bondProgramTaggedC, c => new t.Type<BondProgram, BondProgramTagged>(
  `BondProgram`,
  (u: unknown): u is BondProgram => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondProgram> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondProgram }))),
  (x: BondProgram): BondProgramTagged => ({ ...x, _tag: `BondProgram`}),
));
export type BondProgramC = typeof bondProgramC;


export const bidSubmissionTemplate = {
  _tag: `BidSubmissionTemplate`,
  name: `BidSubmissionTemplate`
} as const;

export const bidSubmissionTemplateTaggedC = t.type({
  _tag: t.literal(`BidSubmissionTemplate`)
});
export type BidSubmissionTemplateTaggedC = typeof bidSubmissionTemplateTaggedC;
export type BidSubmissionTemplateTagged = t.TypeOf<BidSubmissionTemplateTaggedC>;
export type BidSubmissionTemplate = BidSubmissionTemplateTagged & typeof bidSubmissionTemplate;
export const bidSubmissionTemplateC = pipe(bidSubmissionTemplateTaggedC, c => new t.Type<BidSubmissionTemplate, BidSubmissionTemplateTagged>(
  `BidSubmissionTemplate`,
  (u: unknown): u is BidSubmissionTemplate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmissionTemplate> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmissionTemplate }))),
  (x: BidSubmissionTemplate): BidSubmissionTemplateTagged => ({ ...x, _tag: `BidSubmissionTemplate`}),
));
export type BidSubmissionTemplateC = typeof bidSubmissionTemplateC;


export const bidSubmissionTemplateDocument = {
  _tag: `BidSubmissionTemplateDocument`,
  name: `BidSubmissionTemplateDocument`
} as const;

export const bidSubmissionTemplateDocumentTaggedC = t.type({
  _tag: t.literal(`BidSubmissionTemplateDocument`)
});
export type BidSubmissionTemplateDocumentTaggedC = typeof bidSubmissionTemplateDocumentTaggedC;
export type BidSubmissionTemplateDocumentTagged = t.TypeOf<BidSubmissionTemplateDocumentTaggedC>;
export type BidSubmissionTemplateDocument = BidSubmissionTemplateDocumentTagged & typeof bidSubmissionTemplateDocument;
export const bidSubmissionTemplateDocumentC = pipe(bidSubmissionTemplateDocumentTaggedC, c => new t.Type<BidSubmissionTemplateDocument, BidSubmissionTemplateDocumentTagged>(
  `BidSubmissionTemplateDocument`,
  (u: unknown): u is BidSubmissionTemplateDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmissionTemplateDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmissionTemplateDocument }))),
  (x: BidSubmissionTemplateDocument): BidSubmissionTemplateDocumentTagged => ({ ...x, _tag: `BidSubmissionTemplateDocument`}),
));
export type BidSubmissionTemplateDocumentC = typeof bidSubmissionTemplateDocumentC;


export const bidSubmissionTemplateQuestion = {
  _tag: `BidSubmissionTemplateQuestion`,
  name: `BidSubmissionTemplateQuestion`
} as const;

export const bidSubmissionTemplateQuestionTaggedC = t.type({
  _tag: t.literal(`BidSubmissionTemplateQuestion`)
});
export type BidSubmissionTemplateQuestionTaggedC = typeof bidSubmissionTemplateQuestionTaggedC;
export type BidSubmissionTemplateQuestionTagged = t.TypeOf<BidSubmissionTemplateQuestionTaggedC>;
export type BidSubmissionTemplateQuestion = BidSubmissionTemplateQuestionTagged & typeof bidSubmissionTemplateQuestion;
export const bidSubmissionTemplateQuestionC = pipe(bidSubmissionTemplateQuestionTaggedC, c => new t.Type<BidSubmissionTemplateQuestion, BidSubmissionTemplateQuestionTagged>(
  `BidSubmissionTemplateQuestion`,
  (u: unknown): u is BidSubmissionTemplateQuestion => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmissionTemplateQuestion> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmissionTemplateQuestion }))),
  (x: BidSubmissionTemplateQuestion): BidSubmissionTemplateQuestionTagged => ({ ...x, _tag: `BidSubmissionTemplateQuestion`}),
));
export type BidSubmissionTemplateQuestionC = typeof bidSubmissionTemplateQuestionC;


export const bidSubmission = {
  _tag: `BidSubmission`,
  name: `BidSubmissioTemplate`
} as const;

export const bidSubmissionTaggedC = t.type({
  _tag: t.literal(`BidSubmission`)
});
export type BidSubmissionTaggedC = typeof bidSubmissionTaggedC;
export type BidSubmissionTagged = t.TypeOf<BidSubmissionTaggedC>;
export type BidSubmission = BidSubmissionTagged & typeof bidSubmission;
export const bidSubmissionC = pipe(bidSubmissionTaggedC, c => new t.Type<BidSubmission, BidSubmissionTagged>(
  `BidSubmission`,
  (u: unknown): u is BidSubmission => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmission> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmission }))),
  (x: BidSubmission): BidSubmissionTagged => ({ ...x, _tag: `BidSubmission`}),
));
export type BidSubmissionC = typeof bidSubmissionC;


export const bidSubmissionDocument = {
  _tag: `BidSubmissionDocument`,
  name: `BidSubmissionDocument`
} as const;

export const bidSubmissionDocumentTaggedC = t.type({
  _tag: t.literal(`BidSubmissionDocument`)
});
export type BidSubmissionDocumentTaggedC = typeof bidSubmissionDocumentTaggedC;
export type BidSubmissionDocumentTagged = t.TypeOf<BidSubmissionDocumentTaggedC>;
export type BidSubmissionDocument = BidSubmissionDocumentTagged & typeof bidSubmissionDocument;
export const bidSubmissionDocumentC = pipe(bidSubmissionDocumentTaggedC, c => new t.Type<BidSubmissionDocument, BidSubmissionDocumentTagged>(
  `BidSubmissionDocument`,
  (u: unknown): u is BidSubmissionDocument => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmissionDocument> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmissionDocument }))),
  (x: BidSubmissionDocument): BidSubmissionDocumentTagged => ({ ...x, _tag: `BidSubmissionDocument`}),
));
export type BidSubmissionDocumentC = typeof bidSubmissionDocumentC;


export const bidSubmissionAnswer = {
  _tag: `BidSubmissionAnswer`,
  name: `BidSubmissionAnswer`
} as const;

export const bidSubmissionAnswerTaggedC = t.type({
  _tag: t.literal(`BidSubmissionAnswer`)
});
export type BidSubmissionAnswerTaggedC = typeof bidSubmissionAnswerTaggedC;
export type BidSubmissionAnswerTagged = t.TypeOf<BidSubmissionAnswerTaggedC>;
export type BidSubmissionAnswer = BidSubmissionAnswerTagged & typeof bidSubmissionAnswer;
export const bidSubmissionAnswerC = pipe(bidSubmissionAnswerTaggedC, c => new t.Type<BidSubmissionAnswer, BidSubmissionAnswerTagged>(
  `BidSubmissionAnswer`,
  (u: unknown): u is BidSubmissionAnswer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidSubmissionAnswer> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidSubmissionAnswer }))),
  (x: BidSubmissionAnswer): BidSubmissionAnswerTagged => ({ ...x, _tag: `BidSubmissionAnswer`}),
));
export type BidSubmissionAnswerC = typeof bidSubmissionAnswerC;


export const allDraftTypeC = [addressC, mediaC, emmaDocC, homepageImgC, calloutFieldC, textItemC, disclaimerC, ratingC, analystC, cusip6C, emmaC, relatedIssuerC, calendarC, newsC, faqC, hrefC, issuerOfficerC, paymentTokenC, projectC, invoiceC, offeringParticipantC, offeringDataC, offeringSeriesC, siteTemplateC, headerPhotoC, marketInsightC, nonIceDebtInstrumentC, esgImgC, issuerLinkC, rfpC, reminderC, externalLinkC, contentNoteC, bondProgramC, bidSubmissionTemplateC, bidSubmissionTemplateDocumentC, bidSubmissionTemplateQuestionC, bidSubmissionC, bidSubmissionDocumentC, bidSubmissionAnswerC] as const;
export const allDraftTypeNames = [`Address`, `Media`, `EmmaDoc`, `HomepageImg`, `CalloutField`, `TextItem`, `Disclaimer`, `Rating`, `Analyst`, `Cusip6`, `Emma`, `RelatedIssuer`, `Calendar`, `News`, `Faq`, `Href`, `IssuerOfficer`, `PaymentToken`, `Project`, `Invoice`, `OfferingParticipant`, `OfferingData`, `OfferingSeries`, `SiteTemplate`, `HeaderPhoto`, `MarketInsight`, `NonIceDebtInstrument`, `EsgImg`, `IssuerLink`, `Rfp`, `Reminder`, `ExternalLink`, `ContentNote`, `BondProgram`, `BidSubmissionTemplate`, `BidSubmissionTemplateDocument`, `BidSubmissionTemplateQuestion`, `BidSubmission`, `BidSubmissionDocument`, `BidSubmissionAnswer`] as const;
export type DraftTypeName = (typeof allDraftTypeNames)[number];

export const DraftTypeCU = t.union([addressC, mediaC, emmaDocC, homepageImgC, calloutFieldC, textItemC, disclaimerC, ratingC, analystC, cusip6C, emmaC, relatedIssuerC, calendarC, newsC, faqC, hrefC, issuerOfficerC, paymentTokenC, projectC, invoiceC, offeringParticipantC, offeringDataC, offeringSeriesC, siteTemplateC, headerPhotoC, marketInsightC, nonIceDebtInstrumentC, esgImgC, issuerLinkC, rfpC, reminderC, externalLinkC, contentNoteC, bondProgramC, bidSubmissionTemplateC, bidSubmissionTemplateDocumentC, bidSubmissionTemplateQuestionC, bidSubmissionC, bidSubmissionDocumentC, bidSubmissionAnswerC]);
export type DraftTypeCU = typeof DraftTypeCU;
export type DraftTypeU = t.TypeOf<DraftTypeCU>;

export const draftTypeOrd: Ord.Ord<DraftTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDraftType = [address, media, emmaDoc, homepageImg, calloutField, textItem, disclaimer, rating, analyst, cusip6, emma, relatedIssuer, calendar, news, faq, href, issuerOfficer, paymentToken, project, invoice, offeringParticipant, offeringData, offeringSeries, siteTemplate, headerPhoto, marketInsight, nonIceDebtInstrument, esgImg, issuerLink, rfp, reminder, externalLink, contentNote, bondProgram, bidSubmissionTemplate, bidSubmissionTemplateDocument, bidSubmissionTemplateQuestion, bidSubmission, bidSubmissionDocument, bidSubmissionAnswer] as const;
export type DraftTypeMap<A> = { [K in DraftTypeName]: A };


