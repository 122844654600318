import { WithStatusCU as imported228_WithStatusCU, hasManyLinkC as imported95_hasManyLinkC, hasManyLinkC as imported93_hasManyLinkC, WithStatusCU as imported226_WithStatusCU, hasManyLinkC as imported98_hasManyLinkC, hasManyLinkC as imported96_hasManyLinkC, hasManyLinkC as imported94_hasManyLinkC, hasManyLinkC as imported97_hasManyLinkC, WithStatusCU as imported227_WithStatusCU, WithStatusCU as imported225_WithStatusCU, WithStatusCU as imported223_WithStatusCU, WithStatusCU as imported224_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { externalLinkC as imported13_externalLinkC, externalLinkC as imported14_externalLinkC } from "./externalLink";
import { projectC as imported14_projectC } from "./project";
import { taggedContentC as imported121_taggedContentC, taggedContentC as imported122_taggedContentC, taggedContentC as imported124_taggedContentC, taggedContentC as imported123_taggedContentC } from "./taggedContent";
import { bondProgramC as imported16_bondProgramC } from "./bondProgramBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { eventC as imported11_eventC } from "./event";
import { sortOrderC as imported20_sortOrderC, sortOrderC as imported21_sortOrderC } from "./sortOrder";
import { postRelatedC as imported35_postRelatedC, isAttachedC as imported12_isAttachedC, relatedRfpLinkC as imported8_relatedRfpLinkC, postRelatedC as imported36_postRelatedC, relatedDocumentLinkC as imported21_relatedDocumentLinkC, postRelatedC as imported34_postRelatedC, postRelatedAndAttachedC as imported8_postRelatedAndAttachedC, relatedProjectLinkC as imported15_relatedProjectLinkC, relatedProgramLinkC as imported6_relatedProgramLinkC, postRelatedC as imported37_postRelatedC, postAttachedC as imported14_postAttachedC, relatedOfferingLinkC as imported8_relatedOfferingLinkC, relatedExternalLinkLinkC as imported7_relatedExternalLinkLinkC, relatedProjectLinkC as imported16_relatedProjectLinkC, relatedProgramLinkC as imported7_relatedProgramLinkC, relatedOfferingLinkC as imported7_relatedOfferingLinkC, relatedDocumentLinkC as imported20_relatedDocumentLinkC, relatedRfpLinkC as imported7_relatedRfpLinkC } from "./relatedContent";
import { markdownC } from "../../codecs/markdown";
import { mediaC as imported51_mediaC, mediaUploadResponseWithCategoryC as imported8_mediaUploadResponseWithCategoryC } from "./media";
import { LocalDateC } from "../../codecs/localDate";
import { bondOfferingC as imported20_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported47_rfpC } from "./rfpBase";

export const eventRelatedContentC = t.type({
  projects: t.readonlyArray(imported93_hasManyLinkC(imported223_WithStatusCU(imported121_taggedContentC(imported14_projectC)), imported15_relatedProjectLinkC)),
  offerings: t.readonlyArray(imported94_hasManyLinkC(imported224_WithStatusCU(imported122_taggedContentC(imported20_bondOfferingC)), imported7_relatedOfferingLinkC)),
  documents: t.readonlyArray(imported95_hasManyLinkC(imported225_WithStatusCU(imported123_taggedContentC(imported51_mediaC)), imported12_isAttachedC(imported20_relatedDocumentLinkC))),
  externalLinks: t.readonlyArray(imported96_hasManyLinkC(imported226_WithStatusCU(imported13_externalLinkC), imported7_relatedExternalLinkLinkC)),
  rfps: t.readonlyArray(imported97_hasManyLinkC(imported227_WithStatusCU(imported124_taggedContentC(imported47_rfpC)), imported7_relatedRfpLinkC)),
  programs: t.readonlyArray(imported98_hasManyLinkC(imported228_WithStatusCU(imported16_bondProgramC), imported6_relatedProgramLinkC))
});
export type EventRelatedContentC = typeof eventRelatedContentC;
export type EventRelatedContent = t.TypeOf<EventRelatedContentC>;


export const eventWithRelatedContentC = t.type({
  event: imported11_eventC,
  relatedContent: eventRelatedContentC
});
export type EventWithRelatedContentC = typeof eventWithRelatedContentC;
export type EventWithRelatedContent = t.TypeOf<EventWithRelatedContentC>;


export const eventPostRelatedContentC = t.type({
  projects: imported34_postRelatedC(imported16_relatedProjectLinkC),
  offerings: imported35_postRelatedC(imported8_relatedOfferingLinkC),
  documents: imported8_postRelatedAndAttachedC(imported21_relatedDocumentLinkC, imported8_mediaUploadResponseWithCategoryC, imported20_sortOrderC),
  externalLinks: imported14_postAttachedC(imported14_externalLinkC, imported21_sortOrderC),
  rfps: imported36_postRelatedC(imported8_relatedRfpLinkC),
  programs: imported37_postRelatedC(imported7_relatedProgramLinkC)
});
export type EventPostRelatedContentC = typeof eventPostRelatedContentC;
export type EventPostRelatedContent = t.TypeOf<EventPostRelatedContentC>;


export const eventPostBodyC = t.type({
  id: optionFromNullable(t.number),
  eventDate: optionFromNullable(LocalDateC),
  endDate: optionFromNullable(LocalDateC),
  eventTitle: t.string,
  eventBlurb: optionFromNullable(markdownC),
  relatedContent: eventPostRelatedContentC
});
export type EventPostBodyC = typeof eventPostBodyC;
export type EventPostBody = t.TypeOf<EventPostBodyC>;


