import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import type * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { type Match } from "fp-ts-routing/lib";
import { Lens } from "monocle-ts";

import type { R } from "@scripts/fp-ts";
import type { FeatureU } from "@scripts/generated/domaintables/featureFlags";
import type { RoleU } from "@scripts/generated/domaintables/roles";
import type { DataMetaBase } from "@scripts/meta/dataMeta";
import { displayName } from "@scripts/meta/dataMeta";

import type { PageMeta, RouteAuth } from "./base";
import { format, makeTag } from "./base";

type RequiredPageMeta<S extends string> = {
  rolesAllowed: RNEA.ReadonlyNonEmptyArray<RoleU>;
  _tag: S;
};

export type PortalPageMeta<S extends string> = RequiredPageMeta<S> & {
  featuresRequired: ReadonlyArray<FeatureU>;
  bankAllowed: boolean;
};

const mkAuthMeta = <S extends string>(meta: PortalPageMeta<S>): RouteAuth => ({
  banksAllowed: meta.bankAllowed || true,
  rolesAllowed: meta.rolesAllowed,
  featuresRequired: RA.toArray(meta.featuresRequired),
});


type TitleRenderFn<S extends string> = (_: DataMetaBase<S>) => string;

type CustomDisplayNameProp = { customDisplayName: string };
type TitleRenderFnProp<S extends string> = { titleRenderFn: TitleRenderFn<S> };
type ModifierProps<S extends string> = CustomDisplayNameProp & TitleRenderFnProp<S>;

type TitleModifierU<S extends string> = CustomDisplayNameProp | TitleRenderFnProp<S> | ModifierProps<S>;

type TitleModifier<S extends string> = O.Option<TitleModifierU<S>>;

const modifierPropsGuard = <S extends string>(_: TitleModifierU<S>): _ is ModifierProps<S> =>
  "titleRenderFn" in _ && "customDisplayName" in _;

const customDisplayNameGuard = <S extends string>(_: TitleModifierU<S>): _ is CustomDisplayNameProp =>
  ("customDisplayName" in _ && !("titleRender" in _)) || modifierPropsGuard(_);

const titleRenderFnGuard = <S extends string>(_: TitleModifierU<S>): _ is TitleRenderFnProp<S> =>
  ("titleRenderFn" in _ && !("customDisplayName" in _)) || modifierPropsGuard(_);

const getTitleModifier = <S extends string>(meta: DataMetaBase<S>, titleModifier: TitleModifier<S>) =>
  pipe(
    titleModifier,
    O.map((mod: TitleModifierU<S>) => pipe(
      customDisplayNameGuard(mod) ? O.some(mod.customDisplayName) : O.none,
      Lens.fromProp<DataMetaBase<S>>()("customDisplayName").set,
      l => l(meta),
      titleRenderFnGuard(mod) ? mod.titleRenderFn : displayName,
    )),
    O.getOrElse(() => displayName(meta))
  );

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const noParams = {} as never;
type ParamsOrNever<P> = P extends Record<PropertyKey, never> ? never : Required<P>;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const matchParamsOrNever = <P>(a: Match<P>) => a as Match<ParamsOrNever<Required<P>>>;


/*
  TODO: Improvements for makePageMeta:
    - Re-arrage parameters so that we can call this function directly, instead of wrapping it in a separate funtion to pass in the req/optional params
      * This would remove the need to specify types for req/optional params. They should be inferred from the Match type.
    - Remove unnecessary currying
*/

type MakePageMetaParams<
  Params extends object,
  ReqToPartialKeys extends keyof Params,
  PartializedReqParams extends R.PartiallyPartialize<Params, ReqToPartialKeys>,
  RequiredFields extends R.NonNullableFields<PartializedReqParams>,
  QueryFields extends Required<R.NullableFields<PartializedReqParams>>,
> =
  PartializedReqParams extends Record<PropertyKey, never>
    ? []
    : R.NonNullableFields<PartializedReqParams> extends Partial<Record<string, never>>
      ? [q: QueryFields]
      : R.NullableFields<PartializedReqParams> extends Partial<Record<string, never>>
        ? [r: RequiredFields]
        : [q: QueryFields, r: RequiredFields];

export const makePageMeta = <
  Params extends object,
  ReqToPartialKeys extends keyof Params
    = never,
  PartializedReqParams extends R.PartiallyPartialize<Params, ReqToPartialKeys>
    = R.PartiallyPartialize<Params, ReqToPartialKeys>,
  RequiredFields extends R.NonNullableFields<PartializedReqParams>
    = R.NonNullableFields<PartializedReqParams>,
  QueryFields extends Required<R.NullableFields<PartializedReqParams>>
    = Required<R.NullableFields<PartializedReqParams>>,
>(
  match: {
    match: Match<Params>;
    pathParts: ReadonlyArray<string>;
  },
  ...params: MakePageMetaParams<Params, ReqToPartialKeys, PartializedReqParams, RequiredFields, QueryFields>
) => <S extends string>(
  titleModifier: TitleModifier<S>
) => (
  ppm: PortalPageMeta<S>,
  meta: DataMetaBase<S>,
): PageMeta<ParamsOrNever<Params>> => {

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const p = { ...params[0], ...params[1] } as Params;

  return ({
    _tag: makeTag(match.pathParts),
    url: () => format(match.match, p),
    title: () => getTitleModifier(meta, titleModifier),
    dataMeta: O.some(meta),
    route: matchParamsOrNever(match.match),
    authDetails: mkAuthMeta(ppm),
  });
};
