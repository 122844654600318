import * as t from "io-ts";
import { mediaC as imported50_mediaC } from "./media";
import { NonEmailSendActivityCU as imported1_NonEmailSendActivityCU } from "./dealPortalActivity";
import { activityFC as imported2_activityFC, activityFC as imported3_activityFC } from "./dealPortalAnalytics";
import { bondOfferingC as imported19_bondOfferingC } from "./bondOfferingBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { PhotoEditableCU as imported7_PhotoEditableCU } from "../domaintables/pages";
import { emailContactIdC as imported4_emailContactIdC, emailContactIdC as imported3_emailContactIdC, minimalActorC as imported3_minimalActorC, minimalActorC as imported4_minimalActorC, minimalActorC as imported5_minimalActorC } from "./actor";
import { WithStatusCU as imported220_WithStatusCU, WithStatusCU as imported221_WithStatusCU, withModInfoC as imported147_withModInfoC } from "./threadThrough";
import { withIssuerC as imported7_withIssuerC } from "./issuer";
import { ipreoHoldingsC as imported8_ipreoHoldingsC, ipreoHoldingsC as imported9_ipreoHoldingsC } from "./ipreoHoldings";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";

export const activeDealViewDataC = t.type({
  last14DaysAnalytics: imported2_activityFC(imported3_emailContactIdC, imported3_minimalActorC),
  lifetimeAnalytics: imported3_activityFC(imported4_emailContactIdC, imported4_minimalActorC),
  recentActivity: t.readonlyArray(t.tuple([imported5_minimalActorC, imported1_NonEmailSendActivityCU])),
  topHoldersByCusip6: t.readonlyArray(imported8_ipreoHoldingsC),
  topHoldersBySector: t.readonlyArray(imported9_ipreoHoldingsC),
  ipreoToBLCompanies: t.record(t.string, readonlyNonEmptyArrayC(t.string))
});
export type ActiveDealViewDataC = typeof activeDealViewDataC;
export type ActiveDealViewData = t.TypeOf<ActiveDealViewDataC>;


export const dealViewDataC = t.type({
  offering: imported7_withIssuerC(imported220_WithStatusCU(imported147_withModInfoC(imported19_bondOfferingC))),
  issuerHeaderPhotos: t.readonlyArray(t.tuple([imported7_PhotoEditableCU, imported221_WithStatusCU(imported50_mediaC)])),
  activeData: optionFromNullable(activeDealViewDataC)
});
export type DealViewDataC = typeof dealViewDataC;
export type DealViewData = t.TypeOf<DealViewDataC>;


