import { WithStatusCU as imported354_WithStatusCU } from "./threadThrough";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import { LocalDateC } from "../../codecs/localDate";
import { dayToDayC as imported5_dayToDayC, dayToDayC as imported6_dayToDayC, dayToDayC as imported7_dayToDayC } from "../domaintables/dateQualifiers";
import { MethodOfSaleCU as imported2_MethodOfSaleCU } from "../domaintables/methodsOfSale";
import { either } from "io-ts-types/lib/either";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { StateInfoCU as imported9_StateInfoCU } from "../domaintables/states";
import { dateQualifierRangeC as imported6_dateQualifierRangeC, dateQualifierRangeC as imported8_dateQualifierRangeC, dateQualifierRangeC as imported7_dateQualifierRangeC, dateQualifierRangeC as imported9_dateQualifierRangeC, dateQualifierRangeC as imported10_dateQualifierRangeC, dateQualifierC as imported25_dateQualifierC, dateQualifierRangeC as imported11_dateQualifierRangeC } from "./dateQualifier";
import { SectorCU as imported18_SectorCU } from "../domaintables/sectors";
import { markdownC } from "../../codecs/markdown";
import { OfferingTypeCU as imported1_OfferingTypeCU } from "../domaintables/offeringTypes";
import * as t from "io-ts";

export const bondOfferingSeriesC = t.type({
  order: t.number,
  name: t.string,
  parAmount: optionFromNullable(t.number),
  taxStatus: optionFromNullable(t.string),
  creditEnhancement: optionFromNullable(t.string),
  offeringType: optionFromNullable(imported1_OfferingTypeCU),
  methodOfSale: optionFromNullable(imported2_MethodOfSaleCU),
  saleDate: optionFromNullable(either(imported6_dateQualifierRangeC, imported5_dayToDayC)),
  closingDate: optionFromNullable(imported7_dateQualifierRangeC),
  retailOrderDate: optionFromNullable(imported25_dateQualifierC),
  datedDate: optionFromNullable(LocalDateC),
  callDate: optionFromNullable(LocalDateC),
  firstInterestDate: optionFromNullable(LocalDateC),
  firstPrincipalDate: optionFromNullable(LocalDateC),
  freeToTradeDate: optionFromNullable(LocalDateC),
  callFeatures: optionFromNullable(t.string)
});
export type BondOfferingSeriesC = typeof bondOfferingSeriesC;
export type BondOfferingSeries = t.TypeOf<BondOfferingSeriesC>;


export const bondOfferingC = t.type({
  order: t.number,
  name: t.string,
  sector: optionFromNullable(imported18_SectorCU),
  state: optionFromNullable(imported9_StateInfoCU),
  notes: optionFromNullable(markdownC),
  contactEmail: optionFromNullable(t.string),
  hidden: t.boolean,
  cusip6: optionFromNullable(t.string),
  bondProgramId: optionFromNullable(t.number),
  loginRequired: t.boolean,
  isArchived: t.boolean,
  archiveDate: optionFromNullable(LocalDateC),
  series: readonlyNonEmptyArrayC(imported354_WithStatusCU(bondOfferingSeriesC)),
  totalParAmount: optionFromNullable(t.number),
  firstSaleDate: optionFromNullable(either(imported8_dateQualifierRangeC, imported6_dayToDayC)),
  lastSaleDate: optionFromNullable(either(imported9_dateQualifierRangeC, imported7_dayToDayC)),
  firstClosingDate: optionFromNullable(imported10_dateQualifierRangeC),
  lastClosingDate: optionFromNullable(imported11_dateQualifierRangeC),
  displayProgramRatings: t.boolean,
  displayIssuerRatings: t.boolean
});
export type BondOfferingC = typeof bondOfferingC;
export type BondOffering = t.TypeOf<BondOfferingC>;


