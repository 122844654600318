import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import * as t from "io-ts";
import { BankerRoleCU as imported1_BankerRoleCU } from "../domaintables/roles";

export const dealPortalUserC = t.type({
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  lastActive: optionFromNullable(LocalDateTimeFromIsoStringC),
  lastIssuer: optionFromNullable(t.string),
  role: imported1_BankerRoleCU
});
export type DealPortalUserC = typeof dealPortalUserC;
export type DealPortalUser = t.TypeOf<DealPortalUserC>;


export const dealPortalUserCreateC = t.type({
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  company: t.string,
  title: optionFromNullable(t.string),
  roleId: t.number,
  personaId: t.number,
  investorTypeId: optionFromNullable(t.number)
});
export type DealPortalUserCreateC = typeof dealPortalUserCreateC;
export type DealPortalUserCreate = t.TypeOf<DealPortalUserCreateC>;


export const bankerExistingUserUpdateC = t.type({
  userId: t.number,
  roleId: t.number
});
export type BankerExistingUserUpdateC = typeof bankerExistingUserUpdateC;
export type BankerExistingUserUpdate = t.TypeOf<BankerExistingUserUpdateC>;


