import * as t from "io-ts";

import { LocalDateC } from "@scripts/codecs/localDate";
import { E, pipe } from "@scripts/fp-ts";
import { localDateNow } from "@scripts/syntax/date/joda";
import { localStorageSafeGet, localStorageSafeRemove } from "@scripts/syntax/sessionStorage";

import { bidSubmissionStoragePrefix } from "../components/form/submit-bid/BidSubmissionSyntax";

const expirationDateC = t.type({
  expirationDate: LocalDateC,
});

export const cleanupExpiredBids = () => {
  if (typeof localStorage !== "undefined") {
    for (const i of (new Array(localStorage.length).keys())) {
      const currentKey = localStorage.key(i);
      if (currentKey?.startsWith(bidSubmissionStoragePrefix)) {
        const item = localStorageSafeGet(expirationDateC)(currentKey);
        pipe(
          item,
          E.filterOrElseW(a => a.expirationDate.isBefore(localDateNow()), () => ""),
          E.map(() => localStorageSafeRemove(currentKey))
        );
      }
    }
  }
};
