import * as t from "io-ts";
import { UserPersonaCU as imported3_UserPersonaCU } from "../domaintables/userPersonas";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { InvestorTypeCU as imported5_InvestorTypeCU } from "../domaintables/investorTypes";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BondLinkRoleCU as imported1_BondLinkRoleCU } from "../domaintables/roles";

export const clientRolesC = t.type({
  clientId: t.number,
  clientTypeId: t.number,
  roles: t.readonlyArray(t.number)
});
export type ClientRolesC = typeof clientRolesC;
export type ClientRoles = t.TypeOf<ClientRolesC>;


export const userC = t.type({
  id: t.number,
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  company: optionFromNullable(t.string),
  title: optionFromNullable(t.string),
  primaryClientId: t.number,
  subscribedToHubspot: t.boolean,
  persona: optionFromNullable(imported3_UserPersonaCU),
  investorType: optionFromNullable(imported5_InvestorTypeCU),
  created: LocalDateTimeFromIsoStringC,
  termsAccepted: optionFromNullable(LocalDateTimeFromIsoStringC),
  mfaPhone: optionFromNullable(t.string),
  mfaVerified: t.boolean
});
export type UserC = typeof userC;
export type User = t.TypeOf<UserC>;


export const userWithRolesC = t.type({
  user: userC,
  clientType: t.string,
  roleIds: t.readonlyArray(clientRolesC),
  bondlinkAdmin: t.boolean,
  bondlinkAdminRole: optionFromNullable(imported1_BondLinkRoleCU)
});
export type UserWithRolesC = typeof userWithRolesC;
export type UserWithRoles = t.TypeOf<UserWithRolesC>;


export const confirmAccountDataC = t.type({
  user: userC,
  email: t.string,
  hash: t.string
});
export type ConfirmAccountDataC = typeof confirmAccountDataC;
export type ConfirmAccountData = t.TypeOf<ConfirmAccountDataC>;


export const confirmAccountPostC = t.type({
  password: t.string,
  confirmPassword: t.string,
  email: t.string,
  hash: t.string,
  termsAccepted: t.boolean
});
export type ConfirmAccountPostC = typeof confirmAccountPostC;
export type ConfirmAccountPost = t.TypeOf<ConfirmAccountPostC>;


export const userClientRolePostC = t.type({
  id: t.number,
  role: t.number
});
export type UserClientRolePostC = typeof userClientRolePostC;
export type UserClientRolePost = t.TypeOf<UserClientRolePostC>;


