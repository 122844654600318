import { issuerC as imported53_issuerC } from "./issuer";
import * as t from "io-ts";
import { contactFormSubmissionC as imported25_contactFormSubmissionC } from "./contact";
import { bankC as imported20_bankC } from "./bank";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { LoginReasonCU as imported1_LoginReasonCU } from "../domaintables/loginReasons";

export const loginPageDataC = t.type({
  bank: optionFromNullable(imported20_bankC),
  issuer: optionFromNullable(imported53_issuerC),
  contactSubmission: optionFromNullable(imported25_contactFormSubmissionC),
  reason: optionFromNullable(imported1_LoginReasonCU),
  redirect: optionFromNullable(t.string),
  commonCompanies: t.readonlyArray(t.string)
});
export type LoginPageDataC = typeof loginPageDataC;
export type LoginPageData = t.TypeOf<LoginPageDataC>;


export const loginPostC = t.type({
  email: t.string,
  password: t.string,
  redirect: optionFromNullable(t.string)
});
export type LoginPostC = typeof loginPostC;
export type LoginPost = t.TypeOf<LoginPostC>;


export const loginAsPostC = t.type({
  password: t.string
});
export type LoginAsPostC = typeof loginAsPostC;
export type LoginAsPost = t.TypeOf<LoginAsPostC>;


export const forgotPasswordPostC = t.type({
  email: t.string
});
export type ForgotPasswordPostC = typeof forgotPasswordPostC;
export type ForgotPasswordPost = t.TypeOf<ForgotPasswordPostC>;


export const refreshSessionC = t.type({
  jws: optionFromNullable(t.string)
});
export type RefreshSessionC = typeof refreshSessionC;
export type RefreshSession = t.TypeOf<RefreshSessionC>;


export const resetPasswordC = t.type({
  email: t.string,
  hash: t.string
});
export type ResetPasswordC = typeof resetPasswordC;
export type ResetPassword = t.TypeOf<ResetPasswordC>;


export const expiredPasswordC = t.type({
  expirationDuration: t.string
});
export type ExpiredPasswordC = typeof expiredPasswordC;
export type ExpiredPassword = t.TypeOf<ExpiredPasswordC>;


export const resetPasswordDataC = t.type({
  reset: resetPasswordC,
  expired: optionFromNullable(expiredPasswordC)
});
export type ResetPasswordDataC = typeof resetPasswordDataC;
export type ResetPasswordData = t.TypeOf<ResetPasswordDataC>;


export const resetPasswordMfaDataC = t.type({
  reset: resetPasswordDataC,
  phoneLineNumber: t.string
});
export type ResetPasswordMfaDataC = typeof resetPasswordMfaDataC;
export type ResetPasswordMfaData = t.TypeOf<ResetPasswordMfaDataC>;


export const resetForgottenPasswordPostC = t.type({
  password: t.string,
  confirmPassword: t.string,
  email: t.string,
  hash: t.string,
  verificationId: optionFromNullable(t.string)
});
export type ResetForgottenPasswordPostC = typeof resetForgottenPasswordPostC;
export type ResetForgottenPasswordPost = t.TypeOf<ResetForgottenPasswordPostC>;


