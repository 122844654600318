import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const fundSlashETF = {
  _tag: `FundSlashETF`,
  id: 1,
  name: `Fund / ETF (Exchange-Traded Fund)`
} as const;

export const fundSlashETFTaggedC = t.type({
  _tag: t.literal(`FundSlashETF`)
});
export type FundSlashETFTaggedC = typeof fundSlashETFTaggedC;
export type FundSlashETFTagged = t.TypeOf<FundSlashETFTaggedC>;
export type FundSlashETF = FundSlashETFTagged & typeof fundSlashETF;
export const fundSlashETFC = pipe(fundSlashETFTaggedC, c => new t.Type<FundSlashETF, FundSlashETFTagged>(
  `FundSlashETF`,
  (u: unknown): u is FundSlashETF => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FundSlashETF> => pipe(c.decode(u), E.map(x => ({ ...x, ...fundSlashETF }))),
  (x: FundSlashETF): FundSlashETFTagged => ({ ...x, _tag: `FundSlashETF`}),
));
export type FundSlashETFC = typeof fundSlashETFC;


export const SMA = {
  _tag: `SMA`,
  id: 2,
  name: `SMA (Separately Managed Accounts)`
} as const;

export const SMATaggedC = t.type({
  _tag: t.literal(`SMA`)
});
export type SMATaggedC = typeof SMATaggedC;
export type SMATagged = t.TypeOf<SMATaggedC>;
export type SMA = SMATagged & typeof SMA;
export const SMAC = pipe(SMATaggedC, c => new t.Type<SMA, SMATagged>(
  `SMA`,
  (u: unknown): u is SMA => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SMA> => pipe(c.decode(u), E.map(x => ({ ...x, ...SMA }))),
  (x: SMA): SMATagged => ({ ...x, _tag: `SMA`}),
));
export type SMAC = typeof SMAC;


export const insurance = {
  _tag: `Insurance`,
  id: 3,
  name: `Insurance`
} as const;

export const insuranceTaggedC = t.type({
  _tag: t.literal(`Insurance`)
});
export type InsuranceTaggedC = typeof insuranceTaggedC;
export type InsuranceTagged = t.TypeOf<InsuranceTaggedC>;
export type Insurance = InsuranceTagged & typeof insurance;
export const insuranceC = pipe(insuranceTaggedC, c => new t.Type<Insurance, InsuranceTagged>(
  `Insurance`,
  (u: unknown): u is Insurance => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Insurance> => pipe(c.decode(u), E.map(x => ({ ...x, ...insurance }))),
  (x: Insurance): InsuranceTagged => ({ ...x, _tag: `Insurance`}),
));
export type InsuranceC = typeof insuranceC;


export const bankPortfolio = {
  _tag: `BankPortfolio`,
  id: 4,
  name: `Bank Portfolio`
} as const;

export const bankPortfolioTaggedC = t.type({
  _tag: t.literal(`BankPortfolio`)
});
export type BankPortfolioTaggedC = typeof bankPortfolioTaggedC;
export type BankPortfolioTagged = t.TypeOf<BankPortfolioTaggedC>;
export type BankPortfolio = BankPortfolioTagged & typeof bankPortfolio;
export const bankPortfolioC = pipe(bankPortfolioTaggedC, c => new t.Type<BankPortfolio, BankPortfolioTagged>(
  `BankPortfolio`,
  (u: unknown): u is BankPortfolio => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankPortfolio> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankPortfolio }))),
  (x: BankPortfolio): BankPortfolioTagged => ({ ...x, _tag: `BankPortfolio`}),
));
export type BankPortfolioC = typeof bankPortfolioC;


export const esgGreenBonds = {
  _tag: `EsgGreenBonds`,
  id: 5,
  name: `ESG / Green Bonds`
} as const;

export const esgGreenBondsTaggedC = t.type({
  _tag: t.literal(`EsgGreenBonds`)
});
export type EsgGreenBondsTaggedC = typeof esgGreenBondsTaggedC;
export type EsgGreenBondsTagged = t.TypeOf<EsgGreenBondsTaggedC>;
export type EsgGreenBonds = EsgGreenBondsTagged & typeof esgGreenBonds;
export const esgGreenBondsC = pipe(esgGreenBondsTaggedC, c => new t.Type<EsgGreenBonds, EsgGreenBondsTagged>(
  `EsgGreenBonds`,
  (u: unknown): u is EsgGreenBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgGreenBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgGreenBonds }))),
  (x: EsgGreenBonds): EsgGreenBondsTagged => ({ ...x, _tag: `EsgGreenBonds`}),
));
export type EsgGreenBondsC = typeof esgGreenBondsC;


export const highYield = {
  _tag: `HighYield`,
  id: 6,
  name: `High Yield`
} as const;

export const highYieldTaggedC = t.type({
  _tag: t.literal(`HighYield`)
});
export type HighYieldTaggedC = typeof highYieldTaggedC;
export type HighYieldTagged = t.TypeOf<HighYieldTaggedC>;
export type HighYield = HighYieldTagged & typeof highYield;
export const highYieldC = pipe(highYieldTaggedC, c => new t.Type<HighYield, HighYieldTagged>(
  `HighYield`,
  (u: unknown): u is HighYield => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HighYield> => pipe(c.decode(u), E.map(x => ({ ...x, ...highYield }))),
  (x: HighYield): HighYieldTagged => ({ ...x, _tag: `HighYield`}),
));
export type HighYieldC = typeof highYieldC;


export const other = {
  _tag: `Other`,
  id: 7,
  name: `Other (Pension, Foundation, Endowment, Government)`
} as const;

export const otherTaggedC = t.type({
  _tag: t.literal(`Other`)
});
export type OtherTaggedC = typeof otherTaggedC;
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export const otherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `Other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `Other`}),
));
export type OtherC = typeof otherC;


export const privateSlashCommercialLoans = {
  _tag: `PrivateSlashCommercialLoans`,
  id: 8,
  name: `Private / Commercial Loans`
} as const;

export const privateSlashCommercialLoansTaggedC = t.type({
  _tag: t.literal(`PrivateSlashCommercialLoans`)
});
export type PrivateSlashCommercialLoansTaggedC = typeof privateSlashCommercialLoansTaggedC;
export type PrivateSlashCommercialLoansTagged = t.TypeOf<PrivateSlashCommercialLoansTaggedC>;
export type PrivateSlashCommercialLoans = PrivateSlashCommercialLoansTagged & typeof privateSlashCommercialLoans;
export const privateSlashCommercialLoansC = pipe(privateSlashCommercialLoansTaggedC, c => new t.Type<PrivateSlashCommercialLoans, PrivateSlashCommercialLoansTagged>(
  `PrivateSlashCommercialLoans`,
  (u: unknown): u is PrivateSlashCommercialLoans => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PrivateSlashCommercialLoans> => pipe(c.decode(u), E.map(x => ({ ...x, ...privateSlashCommercialLoans }))),
  (x: PrivateSlashCommercialLoans): PrivateSlashCommercialLoansTagged => ({ ...x, _tag: `PrivateSlashCommercialLoans`}),
));
export type PrivateSlashCommercialLoansC = typeof privateSlashCommercialLoansC;


export const commercialLinesOfCredit = {
  _tag: `CommercialLinesOfCredit`,
  id: 9,
  name: `Commercial Lines of Credit`
} as const;

export const commercialLinesOfCreditTaggedC = t.type({
  _tag: t.literal(`CommercialLinesOfCredit`)
});
export type CommercialLinesOfCreditTaggedC = typeof commercialLinesOfCreditTaggedC;
export type CommercialLinesOfCreditTagged = t.TypeOf<CommercialLinesOfCreditTaggedC>;
export type CommercialLinesOfCredit = CommercialLinesOfCreditTagged & typeof commercialLinesOfCredit;
export const commercialLinesOfCreditC = pipe(commercialLinesOfCreditTaggedC, c => new t.Type<CommercialLinesOfCredit, CommercialLinesOfCreditTagged>(
  `CommercialLinesOfCredit`,
  (u: unknown): u is CommercialLinesOfCredit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CommercialLinesOfCredit> => pipe(c.decode(u), E.map(x => ({ ...x, ...commercialLinesOfCredit }))),
  (x: CommercialLinesOfCredit): CommercialLinesOfCreditTagged => ({ ...x, _tag: `CommercialLinesOfCredit`}),
));
export type CommercialLinesOfCreditC = typeof commercialLinesOfCreditC;


export const financialServices = {
  _tag: `FinancialServices`,
  id: 10,
  name: `Financial Services (Underwriting, Advisory, Counsel, Banking)`
} as const;

export const financialServicesTaggedC = t.type({
  _tag: t.literal(`FinancialServices`)
});
export type FinancialServicesTaggedC = typeof financialServicesTaggedC;
export type FinancialServicesTagged = t.TypeOf<FinancialServicesTaggedC>;
export type FinancialServices = FinancialServicesTagged & typeof financialServices;
export const financialServicesC = pipe(financialServicesTaggedC, c => new t.Type<FinancialServices, FinancialServicesTagged>(
  `FinancialServices`,
  (u: unknown): u is FinancialServices => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinancialServices> => pipe(c.decode(u), E.map(x => ({ ...x, ...financialServices }))),
  (x: FinancialServices): FinancialServicesTagged => ({ ...x, _tag: `FinancialServices`}),
));
export type FinancialServicesC = typeof financialServicesC;


export const allInvestorFocusC = [fundSlashETFC, SMAC, insuranceC, bankPortfolioC, esgGreenBondsC, highYieldC, otherC, privateSlashCommercialLoansC, commercialLinesOfCreditC, financialServicesC] as const;
export const allInvestorFocusNames = [`FundSlashETF`, `SMA`, `Insurance`, `BankPortfolio`, `EsgGreenBonds`, `HighYield`, `Other`, `PrivateSlashCommercialLoans`, `CommercialLinesOfCredit`, `FinancialServices`] as const;
export type InvestorFocusName = (typeof allInvestorFocusNames)[number];

export const InvestorFocusCU = t.union([fundSlashETFC, SMAC, insuranceC, bankPortfolioC, esgGreenBondsC, highYieldC, otherC, privateSlashCommercialLoansC, commercialLinesOfCreditC, financialServicesC]);
export type InvestorFocusCU = typeof InvestorFocusCU;
export type InvestorFocusU = t.TypeOf<InvestorFocusCU>;

export const investorFocusOrd: Ord.Ord<InvestorFocusU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allInvestorFocus = [fundSlashETF, SMA, insurance, bankPortfolio, esgGreenBonds, highYield, other, privateSlashCommercialLoans, commercialLinesOfCredit, financialServices] as const;
export type InvestorFocusMap<A> = { [K in InvestorFocusName]: A };


