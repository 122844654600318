import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const disabled = {
  _tag: `Disabled`,
  id: 1,
  name: `Disabled`
} as const;

export const disabledTaggedC = t.type({
  _tag: t.literal(`Disabled`)
});
export type DisabledTaggedC = typeof disabledTaggedC;
export type DisabledTagged = t.TypeOf<DisabledTaggedC>;
export type Disabled = DisabledTagged & typeof disabled;
export const disabledC = pipe(disabledTaggedC, c => new t.Type<Disabled, DisabledTagged>(
  `Disabled`,
  (u: unknown): u is Disabled => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Disabled> => pipe(c.decode(u), E.map(x => ({ ...x, ...disabled }))),
  (x: Disabled): DisabledTagged => ({ ...x, _tag: `Disabled`}),
));
export type DisabledC = typeof disabledC;


export const enabledRequired = {
  _tag: `EnabledRequired`,
  id: 2,
  name: `EnabledRequired`
} as const;

export const enabledRequiredTaggedC = t.type({
  _tag: t.literal(`EnabledRequired`)
});
export type EnabledRequiredTaggedC = typeof enabledRequiredTaggedC;
export type EnabledRequiredTagged = t.TypeOf<EnabledRequiredTaggedC>;
export type EnabledRequired = EnabledRequiredTagged & typeof enabledRequired;
export const enabledRequiredC = pipe(enabledRequiredTaggedC, c => new t.Type<EnabledRequired, EnabledRequiredTagged>(
  `EnabledRequired`,
  (u: unknown): u is EnabledRequired => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EnabledRequired> => pipe(c.decode(u), E.map(x => ({ ...x, ...enabledRequired }))),
  (x: EnabledRequired): EnabledRequiredTagged => ({ ...x, _tag: `EnabledRequired`}),
));
export type EnabledRequiredC = typeof enabledRequiredC;


export const enabledOptional = {
  _tag: `EnabledOptional`,
  id: 3,
  name: `EnabledOptional`
} as const;

export const enabledOptionalTaggedC = t.type({
  _tag: t.literal(`EnabledOptional`)
});
export type EnabledOptionalTaggedC = typeof enabledOptionalTaggedC;
export type EnabledOptionalTagged = t.TypeOf<EnabledOptionalTaggedC>;
export type EnabledOptional = EnabledOptionalTagged & typeof enabledOptional;
export const enabledOptionalC = pipe(enabledOptionalTaggedC, c => new t.Type<EnabledOptional, EnabledOptionalTagged>(
  `EnabledOptional`,
  (u: unknown): u is EnabledOptional => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EnabledOptional> => pipe(c.decode(u), E.map(x => ({ ...x, ...enabledOptional }))),
  (x: EnabledOptional): EnabledOptionalTagged => ({ ...x, _tag: `EnabledOptional`}),
));
export type EnabledOptionalC = typeof enabledOptionalC;


export const allBidSubmissionTemplateDetailStateC = [disabledC, enabledRequiredC, enabledOptionalC] as const;
export const allBidSubmissionTemplateDetailStateNames = [`Disabled`, `EnabledRequired`, `EnabledOptional`] as const;
export type BidSubmissionTemplateDetailStateName = (typeof allBidSubmissionTemplateDetailStateNames)[number];

export const BidSubmissionTemplateDetailStateCU = t.union([disabledC, enabledRequiredC, enabledOptionalC]);
export type BidSubmissionTemplateDetailStateCU = typeof BidSubmissionTemplateDetailStateCU;
export type BidSubmissionTemplateDetailStateU = t.TypeOf<BidSubmissionTemplateDetailStateCU>;

export const bidSubmissionTemplateDetailStateOrd: Ord.Ord<BidSubmissionTemplateDetailStateU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBidSubmissionTemplateDetailState = [disabled, enabledRequired, enabledOptional] as const;
export type BidSubmissionTemplateDetailStateMap<A> = { [K in BidSubmissionTemplateDetailStateName]: A };


