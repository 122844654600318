import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { addressC as imported10_addressC } from "./address";
import { SectorCU as imported16_SectorCU } from "../domaintables/sectors";

export const suspended = {
  _tag: `Suspended`,
  text: `Suspended`,
  value: `Suspended`
} as const;

export const suspendedTaggedC = t.type({
  _tag: t.literal(`Suspended`)
});
export type SuspendedTaggedC = typeof suspendedTaggedC;
export type SuspendedTagged = t.TypeOf<SuspendedTaggedC>;
export type Suspended = SuspendedTagged & typeof suspended;
export const suspendedC = pipe(suspendedTaggedC, c => new t.Type<Suspended, SuspendedTagged>(
  `Suspended`,
  (u: unknown): u is Suspended => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Suspended> => pipe(c.decode(u), E.map(x => ({ ...x, ...suspended }))),
  (x: Suspended): SuspendedTagged => ({ ...x, _tag: `Suspended`}),
));
export type SuspendedC = typeof suspendedC;


export const issuerC = t.type({
  id: t.number,
  clientId: t.number,
  bankId: optionFromNullable(t.number),
  issuerType: imported16_SectorCU,
  addressId: optionFromNullable(t.number),
  primaryOfficerId: optionFromNullable(t.number),
  welcomeSigneeId: optionFromNullable(t.number),
  slug: t.string,
  name: t.string,
  shortName: optionFromNullable(t.string),
  subheader: optionFromNullable(t.string),
  email: t.string,
  phoneNumber: t.string,
  phoneExtension: optionFromNullable(t.string),
  logoUrl: optionFromNullable(t.string),
  logoIsRectangle: t.boolean,
  website: optionFromNullable(t.string),
  customDomain: optionFromNullable(t.string),
  published: t.boolean,
  publishDate: optionFromNullable(LocalDateTimeFromIsoStringC),
  hasSitePassword: t.boolean,
  domainOwner: t.boolean,
  suspended: t.boolean
});
export type IssuerC = typeof issuerC;
export type Issuer = t.TypeOf<IssuerC>;


export class withIssuerCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  issuer: issuerC,
  unWithIssuer: A1
})}
export const withIssuerC = <A1 extends t.Mixed>(A1: A1) => new withIssuerCC<A1>().codec(A1);
export type WithIssuerC<A1 extends t.Mixed> = ReturnType<withIssuerCC<A1>["codec"]>;
export type WithIssuer<A1> = t.TypeOf<WithIssuerC<t.Type<A1>>>;

export const issuerWithAddressC = t.type({
  issuer: issuerC,
  address: optionFromNullable(imported10_addressC)
});
export type IssuerWithAddressC = typeof issuerWithAddressC;
export type IssuerWithAddress = t.TypeOf<IssuerWithAddressC>;


export const createIssuerPostC = t.type({
  name: t.string,
  email: t.string,
  phoneNumber: t.string,
  shortName: optionFromNullable(t.string),
  subheader: optionFromNullable(t.string),
  issuerType: t.number,
  address: optionFromNullable(t.string),
  city: optionFromNullable(t.string),
  state: optionFromNullable(t.number),
  postalCode: optionFromNullable(t.string)
});
export type CreateIssuerPostC = typeof createIssuerPostC;
export type CreateIssuerPost = t.TypeOf<CreateIssuerPostC>;


export const inactive = {
  _tag: `Inactive`,
  text: `Inactive`,
  value: `Inactive`
} as const;

export const inactiveTaggedC = t.type({
  _tag: t.literal(`Inactive`)
});
export type InactiveTaggedC = typeof inactiveTaggedC;
export type InactiveTagged = t.TypeOf<InactiveTaggedC>;
export type Inactive = InactiveTagged & typeof inactive;
export const inactiveC = pipe(inactiveTaggedC, c => new t.Type<Inactive, InactiveTagged>(
  `Inactive`,
  (u: unknown): u is Inactive => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Inactive> => pipe(c.decode(u), E.map(x => ({ ...x, ...inactive }))),
  (x: Inactive): InactiveTagged => ({ ...x, _tag: `Inactive`}),
));
export type InactiveC = typeof inactiveC;


export const activePrivate = {
  _tag: `ActivePrivate`,
  text: `Active: Private`,
  value: `ActivePrivate`
} as const;

export const activePrivateTaggedC = t.type({
  _tag: t.literal(`ActivePrivate`)
});
export type ActivePrivateTaggedC = typeof activePrivateTaggedC;
export type ActivePrivateTagged = t.TypeOf<ActivePrivateTaggedC>;
export type ActivePrivate = ActivePrivateTagged & typeof activePrivate;
export const activePrivateC = pipe(activePrivateTaggedC, c => new t.Type<ActivePrivate, ActivePrivateTagged>(
  `ActivePrivate`,
  (u: unknown): u is ActivePrivate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ActivePrivate> => pipe(c.decode(u), E.map(x => ({ ...x, ...activePrivate }))),
  (x: ActivePrivate): ActivePrivateTagged => ({ ...x, _tag: `ActivePrivate`}),
));
export type ActivePrivateC = typeof activePrivateC;


export const activePublic = {
  _tag: `ActivePublic`,
  text: `Active: Public`,
  value: `ActivePublic`
} as const;

export const activePublicTaggedC = t.type({
  _tag: t.literal(`ActivePublic`)
});
export type ActivePublicTaggedC = typeof activePublicTaggedC;
export type ActivePublicTagged = t.TypeOf<ActivePublicTaggedC>;
export type ActivePublic = ActivePublicTagged & typeof activePublic;
export const activePublicC = pipe(activePublicTaggedC, c => new t.Type<ActivePublic, ActivePublicTagged>(
  `ActivePublic`,
  (u: unknown): u is ActivePublic => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ActivePublic> => pipe(c.decode(u), E.map(x => ({ ...x, ...activePublic }))),
  (x: ActivePublic): ActivePublicTagged => ({ ...x, _tag: `ActivePublic`}),
));
export type ActivePublicC = typeof activePublicC;


export const allIssuerStatusC = [inactiveC, activePrivateC, activePublicC, suspendedC] as const;
export const allIssuerStatusNames = [`Inactive`, `ActivePrivate`, `ActivePublic`, `Suspended`] as const;
export type IssuerStatusName = (typeof allIssuerStatusNames)[number];

export const IssuerStatusCU = t.union([inactiveC, activePrivateC, activePublicC, suspendedC]);
export type IssuerStatusCU = typeof IssuerStatusCU;
export type IssuerStatusU = t.TypeOf<IssuerStatusCU>;

export const issuerStatusOrd: Ord.Ord<IssuerStatusU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allIssuerStatus = [inactive, activePrivate, activePublic, suspended] as const;
export type IssuerStatusMap<A> = { [K in IssuerStatusName]: A };


export const issuerStatusPostC = t.type({
  status: IssuerStatusCU
});
export type IssuerStatusPostC = typeof issuerStatusPostC;
export type IssuerStatusPost = t.TypeOf<IssuerStatusPostC>;


