import { WithStatusCU as imported295_WithStatusCU, WithStatusCU as imported294_WithStatusCU, withModInfoC as imported174_withModInfoC, withIdC as imported237_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { taggedContentC as imported150_taggedContentC, taggedContentC as imported151_taggedContentC, taggedContentC as imported149_taggedContentC } from "./taggedContent";
import { roadShowDataC as imported26_roadShowDataC } from "./roadshow";
import { bondOfferingC as imported22_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported57_rfpC } from "./rfpBase";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export const roadShowsListC = t.type({
  shows: t.readonlyArray(imported237_withIdC(imported174_withModInfoC(imported149_taggedContentC(imported26_roadShowDataC)))),
  offerings: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported294_WithStatusCU(imported150_taggedContentC(imported22_bondOfferingC)))),
  rfps: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported295_WithStatusCU(imported151_taggedContentC(imported57_rfpC))))
});
export type RoadShowsListC = typeof roadShowsListC;
export type RoadShowsList = t.TypeOf<RoadShowsListC>;


