import * as t from "io-ts";

import { E, pipe, s } from "@scripts/fp-ts";



const nonExistentStorageItem = "ItemDoesNotExistInStorage";
const storageGetParseError = "JSONParseError";
const noLocalStorageError = "NoLocalStorage";

type StorageSafeGetErrors = typeof nonExistentStorageItem | typeof storageGetParseError | typeof noLocalStorageError;


const storageUnsafeGet = (storage: Storage) => <C extends t.Mixed>(key: string): E.Either<t.Errors | StorageSafeGetErrors, C["_A"]> => {
    return pipe(
      storage.getItem(key),
      E.fromNullable<StorageSafeGetErrors>(nonExistentStorageItem),
      E.chain<StorageSafeGetErrors, string, unknown>(E.tryCatchK(JSON.parse, () => storageGetParseError)),
  );
};

const storageSafeGet = (storage: Storage) => <C extends t.Mixed>(codec: C) => (key: string): E.Either<t.Errors | StorageSafeGetErrors, C["_A"]> => {
  return pipe(
    storageUnsafeGet(storage)(key),
      E.chainW(codec.decode),
    );
  };

const storageSafeSet = (storage: Storage) => <T>(key: string, value: T): void => {
    return storage.setItem(key, s.isString(value) ? value : JSON.stringify(value));
};

const storageSafeRemove = (storage: Storage) => (key: string): void => storage.removeItem(key);



export const sessionStorageSafeGet = <C extends t.Mixed>(codec: C) => (key: string): E.Either<t.Errors | StorageSafeGetErrors, C["_A"]> => storageSafeGet(sessionStorage)(codec)(key);

export const sessionStorageSafeSet = <T>(key: string, value: T): void => storageSafeSet(sessionStorage)(key, value);

export const sessionStorageSafeRemove = (key: string): void => storageSafeRemove(sessionStorage)(key);


export const localStorageUnsafeGet = <C extends t.Mixed>(key: string): E.Either<t.Errors | StorageSafeGetErrors, C["_A"]> => storageUnsafeGet(localStorage)(key);

export const localStorageSafeGet = <C extends t.Mixed>(codec: C) => (key: string): E.Either<t.Errors | StorageSafeGetErrors, C["_A"]> => storageSafeGet(localStorage)(codec)(key);

export const localStorageSafeSet = <T>(key: string, value: T): void => storageSafeSet(localStorage)(key, value);

export const localStorageSafeRemove = (key: string): void => storageSafeRemove(localStorage)(key);


export const opLit = t.literal("participants");
export const rsLit = t.literal("roadShows");
export const rpLit = t.literal("projects");
const _replicateOptions: [typeof opLit, typeof rsLit, typeof rpLit] = [opLit, rsLit, rpLit];
const replicateOptionsT = t.tuple(_replicateOptions);
export const replicateOptionsA = t.readonlyArray(t.union(_replicateOptions));
export type ReplicateOptionsA = t.TypeOf<typeof replicateOptionsA>;
export type ReplicateOptionsT = t.TypeOf<typeof replicateOptionsT>;
export const replicateBondSessionKey = "replicateBond";
export const replicateBondSessionStorage = t.type({ replicateOptions: replicateOptionsA, bondName: t.string, bondId: t.number });

export type ReplicateBondSessionStorage = t.TypeOf<typeof replicateBondSessionStorage>;

export const replicateRfpSessionStorage = t.type({ replicateOptions: replicateOptionsA, rfpName: t.string, rfpId: t.number });

export type ReplicateRfpSessionStorage = t.TypeOf<typeof replicateBondSessionStorage>;

export const replicateRfpSessionKey = "replicateRfp";
