/* eslint-disable @typescript-eslint/no-unused-vars */

import { pipe } from "fp-ts/lib/function";

import type { BLConfigWithLog } from "@scripts/bondlink";
import type { Option } from "@scripts/fp-ts";
import { O } from "@scripts/fp-ts";
import * as s from "@scripts/fp-ts/string";
import * as RC from "@scripts/react/form/responseCodecs";
import { FORM_CODEC_ERROR, FORM_CODEC_ERROR_NUMERIC } from "@scripts/react/form/responseCodecs";
import { parseInt } from "@scripts/util/parseInt";

export type ValidationErrorTag = ValidationError["_tag"];
export type ValidationError = typeof ValidationError[keyof typeof ValidationError];
export const ValidationError = {
  FORM_CODEC_ERROR,
  FORM_CODEC_ERROR_NUMERIC,
} as const;

export type ServerErrorTag = ServerError["_tag"];
export type ServerError = typeof ServerError[keyof typeof ServerError];
export const ServerError = {
  DUPLICATE_UNIQUE_VALUE: RC.DUPLICATE_UNIQUE_VALUE,
  DUPLICATE_USER_EMAIL: RC.DUPLICATE_USER_EMAIL,
  DOES_NOT_MATCH: RC.DOES_NOT_MATCH,
  EMPTY_FIELD: RC.EMPTY_FIELD,
  FIELD_MAX_LENGTH_VIOLATION: RC.FIELD_MAX_LENGTH_VIOLATION,
  FIELD_MIN_LENGTH_VIOLATION: RC.FIELD_MIN_LENGTH_VIOLATION,
  ID_NOT_FOUND: RC.ID_NOT_FOUND,
  INVALID_CUSIP: RC.INVALID_CUSIP,
  INVALID_DATE: RC.INVALID_DATE,
  INVALID_PAST_DATE: RC.INVALID_PAST_DATE,
  INVALID_DATE_ORDER: RC.INVALID_DATE_ORDER,
  INVALID_EMAIL: RC.INVALID_EMAIL,
  INVALID_EXTENSION: RC.INVALID_EXTENSION,
  INVALID_HEX_COLOR: RC.INVALID_HEX_COLOR,
  INVALID_PASSWORD_REQUIREMENTS: RC.INVALID_PASSWORD_REQUIREMENTS,
  INVALID_PHONE_NUMBER: RC.INVALID_PHONE_NUMBER,
  INVALID_SPACE_PREFIX: RC.INVALID_SPACE_PREFIX,
  INVALID_SPACE_SUFFIX: RC.INVALID_SPACE_SUFFIX,
  INVALID_URL: RC.INVALID_URL,
  URL_NOT_ALLOWED: RC.URL_NOT_ALLOWED,
  INVALID_VALUE: RC.INVALID_VALUE,
  MISSING_REQUIRED_FIELD: RC.MISSING_REQUIRED_FIELD,
  MALFORMED_INPUT: RC.MALFORMED_INPUT,
  PASSWORD_EXPIRED: RC.PASSWORD_EXPIRED,
  CUSTOM: RC.CUSTOM,
} as const;

export type ErrorCode = typeof ServerError & typeof ValidationError;
export const ErrorCode = {
  ...ServerError,
  ...ValidationError,
} as const;

export type ErrorTextContext = typeof ErrorTextContext[keyof typeof ErrorTextContext];
export const ErrorTextContext = {
  Value: "value",
  Label: "label",
} as const;

export type ValErrMsgFn = (label: Option<string>, value: Option<string>) => string;

const duplicateUniqueValue = (label: Option<string>, value: Option<string>) =>
  s.literal(`${O.orElse("Value")(value)} already exists. Please specify a unique ${O.orElse("value")(label)}`);

const duplicateUserEmail = (_label: Option<string>, _value: Option<string>) =>
  s.literal("There is already an account associated with this email address.");

const doesNotMatch = (label: Option<string>, _value: Option<string>) =>
  s.literal(`${O.orElse("Field")(label)} does not match`);

const emptyField = (label: Option<string>, _value: Option<string>) =>
  s.literal(`${O.orElse("Field")(label)} must not be empty`);

const fieldMaxLengthViolation = (ecu: RC.ServerValidationError) => (label: Option<string>, _value: Option<string>) =>
  pipe(
    ecu.extra,
    O.chain(parseInt),
    O.map(e => s.literal(`${O.orElse("Field")(label)} must not exceed ${e} characters`)),
    O.orElseW(s.literal(`${O.orElse("Field")(label)} exceeds the maximum length requirements`)));

const fieldMinLengthViolation = (ecu: RC.ServerValidationError) => (label: Option<string>, _value: Option<string>) =>
  pipe(
    ecu.extra,
    O.chain(parseInt),
    O.map(e => s.literal(`${O.orElse("Field")(label)} must be at least ${e} characters`)),
    O.orElseW(s.literal(`${O.orElse("Field")(label)} does not meet the minimum length requirements`)));

const idNotFound = (label: Option<string>, value: Option<string>) =>
  s.literal(`${pipe(value, O.alt(() => label), O.orElse("Field"))} could not be found`);

const invalidCusip =
  (_label: Option<string>, value: Option<string>) =>
    s.literal(`${O.orElse("Value")(value)} must be a valid CUSIP`);

const invalidDate =
  (_label: Option<string>, value: Option<string>) =>
    s.literal(`${O.orElse("Value")(value)} is not a valid date`);

const invalidDateOrder = (ecu: RC.ServerValidationError) =>
  (_label: Option<string>, _value: Option<string>) => pipe(
    ecu.extra,
    O.orElseW(s.literal("End date must be after start date")));

const invalidEmail =
  (_label: Option<string>, value: Option<string>) =>
    pipe(
      value,
      O.map(s.postfix(" is not a valid email address")),
      O.orElseW(s.literal("Invalid email address")));

const invalidHexColor =
  (_label: Option<string>, value: Option<string>) =>
    s.literal(`${O.orElse("Value")(value)} must be a valid 6 character hex color`);

const invalidPasswordRequirements = (ecu: RC.ServerValidationError) =>
  (_label: Option<string>, _value: Option<string>) => pipe(
    ecu.extra,
    O.map(s.prefix(" - ")),
    O.orElseW(s.literal("")),
    s.prefix("Password must be strong or better"));

const invalidPhoneNumber = (ecu: RC.ServerValidationError) => (_label: Option<string>, value: Option<string>) =>
  pipe(
    value,
    O.map(v => s.literal(`${v} is not a valid phone number`)),
    O.orElseW(s.literal("Invalid phone number")),
    msg => O.fold(() => msg, (extra: string) => `${msg}. ${extra}`)(ecu.extra));

const invalidSpacePrefix =
  (label: Option<string>, _value: Option<string>) =>
    s.literal(`${O.orElse("Field")(label)} cannot contain a leading space`);

const invalidSpaceSuffix =
  (label: Option<string>, _value: Option<string>) =>
    s.literal(`${O.orElse("Field")(label)} cannot contain a trailing space`);

const invalidUrl =
  (label: Option<string>, _value: Option<string>) =>
    s.literal(`${O.orElse("Field")(label)} must be a full URL, for example: https://www.example.com/page`);

const urlNotAllowed = (ecu: RC.ServerValidationError) =>
  (label: Option<string>, _value: Option<string>) => pipe(ecu.extra,
    O.map(s.prefix("Directly linking to ")),
    O.orElseW(s.literal("The URL you provided")),
    s.postfix(` is not permitted for ${O.orElse("this field")(label)}. Please try a different URL.`)
  );

export const invalidValue = (inputType: ErrorTextContext) =>
  (label: Option<string>, value: Option<string>) =>
    pipe(
      inputType === ErrorTextContext.Value ? value : label,
      O.orElse("Value"),
      s.postfix(" is invalid")
    );

const missingRequiredField =
  (label: Option<string>, _value: Option<string>) =>
    s.literal(`A response is required`);

const malformedInput =
  (label: Option<string>, _value: Option<string>) =>
    s.literal(`${O.orElse("Field")(label)} is an unexpected value`);

const passwordExpired =
  (_label: Option<string>, _value: Option<string>) =>
    s.literal("Your password has expired");

const invalidPastDate =
  (_label: Option<string>, value: Option<string>) =>
    s.literal(`${O.orElse("Value")(value)} cannot be in the past`);

const invalidExtension =
  (_label: Option<string>, _value: Option<string>) =>
    s.literal("Upload has an invalid extension");

const custom = (ecu: RC.ServerValidationError) =>
  (_label: Option<string>, _value: Option<string>) =>
    pipe(
      ecu.extra,
      // TODO: Figure out how to get this working for react
      O.map(e => e.replace(/\n/g, s.literal("<br />"))),
      O.orElseW(s.literal("Invalid value")));

const formCodecError = (ecu: FORM_CODEC_ERROR) =>
  (label: Option<string>, _value: Option<string>) =>
    pipe(
      ecu.val,
      O.map(JSON.stringify),
      O.alt(() => label),
      O.orElseW("Value"),
      s.postfix(" is not the expected type")
    );

const formCodecErrorNumeric =
  (_label: Option<string>, _value: Option<string>) => s.literal("This value must be a number");

export const validationErrorMessage = (config: BLConfigWithLog) =>
  (ecu: RC.ServerValidationError, errorTextContext?: ErrorTextContext): ValErrMsgFn => {
    const inputType = errorTextContext ?? ErrorTextContext.Value;
    switch (ecu.error._tag) {
      case ErrorCode.DUPLICATE_UNIQUE_VALUE._tag:
        return duplicateUniqueValue;
      case ErrorCode.DUPLICATE_USER_EMAIL._tag:
        return duplicateUserEmail;
      case ErrorCode.DOES_NOT_MATCH._tag:
        return doesNotMatch;
      case ErrorCode.EMPTY_FIELD._tag:
        return emptyField;
      case ErrorCode.FIELD_MAX_LENGTH_VIOLATION._tag:
        return fieldMaxLengthViolation(ecu);
      case ErrorCode.FIELD_MIN_LENGTH_VIOLATION._tag:
        return fieldMinLengthViolation(ecu);
      case ErrorCode.ID_NOT_FOUND._tag:
        return idNotFound;
      case ErrorCode.INVALID_CUSIP._tag:
        return invalidCusip;
      case ErrorCode.INVALID_DATE._tag:
        return invalidDate;
      case ErrorCode.INVALID_DATE_ORDER._tag:
        return invalidDateOrder(ecu);
      case ErrorCode.INVALID_EMAIL._tag:
        return invalidEmail;
      case ErrorCode.INVALID_HEX_COLOR._tag:
        return invalidHexColor;
      case ErrorCode.INVALID_PASSWORD_REQUIREMENTS._tag:
        return invalidPasswordRequirements(ecu);
      case ErrorCode.INVALID_PHONE_NUMBER._tag:
        return invalidPhoneNumber(ecu);
      case ErrorCode.INVALID_SPACE_PREFIX._tag:
        return invalidSpacePrefix;
      case ErrorCode.INVALID_SPACE_SUFFIX._tag:
        return invalidSpaceSuffix;
      case ErrorCode.INVALID_URL._tag:
        return invalidUrl;
      case ErrorCode.URL_NOT_ALLOWED._tag:
        return urlNotAllowed(ecu);
      case ErrorCode.INVALID_VALUE._tag:
        return invalidValue(inputType);
      case ErrorCode.MISSING_REQUIRED_FIELD._tag:
        return missingRequiredField;
      case ErrorCode.MALFORMED_INPUT._tag:
        return malformedInput;
      case ErrorCode.PASSWORD_EXPIRED._tag:
        return passwordExpired;
      case ErrorCode.INVALID_PAST_DATE._tag:
        return invalidPastDate;
      case ErrorCode.INVALID_EXTENSION._tag:
        return invalidExtension;
      case ErrorCode.CUSTOM._tag:
        return custom(ecu);
      // Frontend-only errors
      case ErrorCode.FORM_CODEC_ERROR._tag:
        return formCodecError(ecu.error);
      case ErrorCode.FORM_CODEC_ERROR_NUMERIC._tag:
        return formCodecErrorNumeric;
      default:
        return config.exhaustive(ecu.error);
    }
  };

export const existingErrors = () => ({ errors: [{ error: RC.EXISTING_ERRORS, extra: "", field: "" }] });
