import { issuerC as imported34_issuerC } from "./issuer";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { addressC as imported8_addressC } from "./address";
import { bondOfferingC as imported17_bondOfferingC, bondOfferingC as imported18_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported45_rfpC, rfpC as imported46_rfpC } from "./rfpBase";

export const issuerWithDealsC = t.type({
  issuer: imported34_issuerC,
  address: optionFromNullable(imported8_addressC),
  activeOfferings: t.readonlyArray(imported17_bondOfferingC),
  archivedOfferings: t.readonlyArray(imported18_bondOfferingC),
  activeRfps: t.readonlyArray(imported45_rfpC),
  archivedRfps: t.readonlyArray(imported46_rfpC)
});
export type IssuerWithDealsC = typeof issuerWithDealsC;
export type IssuerWithDeals = t.TypeOf<IssuerWithDealsC>;


