import { userC as imported11_userC } from "./user";
import * as t from "io-ts";

export const issuerAdminUserC = t.type({
  user: imported11_userC,
  draftNotifications: t.boolean
});
export type IssuerAdminUserC = typeof issuerAdminUserC;
export type IssuerAdminUser = t.TypeOf<IssuerAdminUserC>;


