import { lookup } from "fp-ts/lib/Array";
import * as Eq from "fp-ts/lib/Eq";
import { identity, pipe } from "fp-ts/lib/function";
import { fromNullable, getOrElse, map } from "fp-ts/lib/Option";
import * as s from "fp-ts/lib/string";
import * as t from "io-ts";

import { tap } from "@scripts/util/tap";

import { eq } from "../util/eq";
import { replaceState } from "./router";

export const methodC = t.union([t.literal("GET"), t.literal("POST"), t.literal("OPTIONS"), t.literal("DELETE")]);
export type MethodC = typeof methodC;
export type Method = t.TypeOf<MethodC>;

export class urlInterfaceCC<M extends Method>{
  codec = (M: M) => t.type({
    method: t.literal(M),
    url: t.string,
  });
}
export const urlInterfaceC = <M extends Method>(M: M) => new urlInterfaceCC<M>().codec(M);
export type UrlInterfaceC<M extends Method> = ReturnType<urlInterfaceCC<M>["codec"]>;
export type UrlInterface<M extends Method> = t.TypeOf<UrlInterfaceC<M>>;

export const urlInterface = <M extends Method>(method: M, url: string): UrlInterface<M> =>
  ({ method, url });

export interface UrlIO<I, O> {
  readonly input: I;
  readonly output: O;
}

export interface UrlInterfaceIO<M extends Method, I, O> extends UrlInterface<M>, UrlIO<I, O> { }

export const urlInterfaceIO = <M extends Method, I, O>(method: M, url: string, io: UrlIO<I, O>): UrlInterfaceIO<M, I, O> =>
  ({ method, url, input: io.input, output: io.output });

export const urlEq = Eq.struct<UrlInterface<Method>>({ url: s.Eq, method: Eq.fromEquals(eq) });
export const urlWoQs = <M extends Method>(u: UrlInterface<M>): UrlInterface<M> => ({ ...u, url: getOrElse(() => u.url)(lookup(0, u.url.split("?"))) });

export const prefixUrl = <M extends Method>(url: UrlInterface<M>, prefix: string): UrlInterface<M> =>
  Object.assign({}, url, { url: prefix + url.url });

export const urlWithQuery = (url: string, query: URLSearchParams): string => {
  const queryStr = query.toString();
  return `${url}${queryStr === "" ? "" : `?${queryStr}`}`;
};

export const modifyAbsoluteUrl = (
  modOrigin: (origin: string) => string,
  modPath: (path: string) => string,
  modQuery: (query: URLSearchParams) => URLSearchParams,
): string =>
  urlWithQuery(
    // The types say location isn't nullable but it returns null server side
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    `${modOrigin(globalThis.location?.origin)}${modPath(globalThis.location?.pathname)}`,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    modQuery(new URLSearchParams(globalThis.location?.search))
  );

export const getAbsoluteUrl = () => modifyAbsoluteUrl(identity, identity, identity);

export const replaceUrlState = (
  modOrigin: (origin: string) => string,
  modPath: (path: string) => string,
  modQuery: (query: URLSearchParams) => URLSearchParams,
) => replaceState(modifyAbsoluteUrl(modOrigin, modPath, modQuery));

export const removeUrlParam = (param: string) => pipe(
  fromNullable(new URLSearchParams(window.location.search).get(param)),
  map(() => replaceUrlState(identity, identity, tap(q => q.delete(param)))),
);
