// This file was generated by postcss-transpiler. Do not edit by hand.

export const buttons = {
  ".btn": {
    css: ".btn",
    html: "btn",
    attrs: {
      ".btn-small": {
        css: ".btn-small",
        html: "btn-small"
      },
      ".btn-no-transform": {
        css: ".btn-no-transform",
        html: "btn-no-transform"
      },
      ".t-transform-none": {
        css: ".t-transform-none",
        html: "t-transform-none"
      },
      ".is-loading": {
        css: ".is-loading",
        html: "is-loading"
      },
      ".btn-social": {
        css: ".btn-social",
        html: "btn-social"
      }
    },
    ".icon": {
      css: ".icon",
      html: "icon"
    },
    "svg": {
      css: "svg",
      html: "svg",
      attrs: {
        ".icon": {
          css: ".icon",
          html: "icon"
        }
      }
    },
    ":not": {
      css: ":not",
      html: "not",
      ":focus": {
        css: ":focus",
        html: "focus"
      }
    }
  },
  ".roadshow-container": {
    css: ".roadshow-container",
    html: "roadshow-container",
    ".progress-container": {
      css: ".progress-container",
      html: "progress-container",
      "button": {
        css: "button",
        html: "button",
        attrs: {
          ".btn": {
            css: ".btn",
            html: "btn"
          }
        }
      }
    }
  },
  ".nothing": {
    css: ".nothing",
    html: "nothing"
  },
  ".btn-primary": {
    css: ".btn-primary",
    html: "btn-primary",
    ".icon": {
      css: ".icon",
      html: "icon",
      ".color": {
        css: ".color",
        html: "color"
      },
      ".background": {
        css: ".background",
        html: "background"
      },
      ".border-color": {
        css: ".border-color",
        html: "border-color"
      }
    }
  },
  ".content-type-details-badge": {
    css: ".content-type-details-badge",
    html: "content-type-details-badge",
    ".btn-primary": {
      css: ".btn-primary",
      html: "btn-primary",
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background": {
          css: ".background",
          html: "background"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      }
    },
    ".btn-callout": {
      css: ".btn-callout",
      html: "btn-callout",
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background": {
          css: ".background",
          html: "background"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      }
    },
    ".btn-secondary": {
      css: ".btn-secondary",
      html: "btn-secondary",
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background": {
          css: ".background",
          html: "background"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      }
    },
    "[data-disabled]": {
      css: "[data-disabled]",
      html: "[data-disabled]",
      attrs: {
        ".btn-callout": {
          css: ".btn-callout",
          html: "btn-callout"
        }
      },
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background": {
          css: ".background",
          html: "background"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      }
    },
    "[disabled]": {
      css: "[disabled]",
      html: "[disabled]",
      attrs: {
        ".btn-callout": {
          css: ".btn-callout",
          html: "btn-callout"
        }
      },
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background": {
          css: ".background",
          html: "background"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      }
    },
    ".btn-close": {
      css: ".btn-close",
      html: "btn-close"
    }
  },
  ".content-type-details": {
    css: ".content-type-details",
    html: "content-type-details",
    attrs: {
      ".sites-featured-item": {
        css: ".sites-featured-item",
        html: "sites-featured-item"
      }
    },
    ".badge": {
      css: ".badge",
      html: "badge",
      ".btn-primary": {
        css: ".btn-primary",
        html: "btn-primary",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      ".btn-callout": {
        css: ".btn-callout",
        html: "btn-callout",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      ".btn-secondary": {
        css: ".btn-secondary",
        html: "btn-secondary",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      "[data-disabled]": {
        css: "[data-disabled]",
        html: "[data-disabled]",
        attrs: {
          ".btn-callout": {
            css: ".btn-callout",
            html: "btn-callout"
          }
        },
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      "[disabled]": {
        css: "[disabled]",
        html: "[disabled]",
        attrs: {
          ".btn-callout": {
            css: ".btn-callout",
            html: "btn-callout"
          }
        },
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      ".btn-close": {
        css: ".btn-close",
        html: "btn-close"
      }
    }
  },
  ".inverted": {
    css: ".inverted",
    html: "inverted",
    ".btn-primary": {
      css: ".btn-primary",
      html: "btn-primary",
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background": {
          css: ".background",
          html: "background"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      }
    },
    ".btn-callout": {
      css: ".btn-callout",
      html: "btn-callout",
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background": {
          css: ".background",
          html: "background"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      }
    },
    ".btn-secondary": {
      css: ".btn-secondary",
      html: "btn-secondary",
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background": {
          css: ".background",
          html: "background"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      }
    },
    "[data-disabled]": {
      css: "[data-disabled]",
      html: "[data-disabled]",
      attrs: {
        ".btn-callout": {
          css: ".btn-callout",
          html: "btn-callout"
        }
      },
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background": {
          css: ".background",
          html: "background"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      }
    },
    "[disabled]": {
      css: "[disabled]",
      html: "[disabled]",
      attrs: {
        ".btn-callout": {
          css: ".btn-callout",
          html: "btn-callout"
        }
      },
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background": {
          css: ".background",
          html: "background"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      }
    },
    ".btn-close": {
      css: ".btn-close",
      html: "btn-close"
    }
  },
  ".issuer-logo-container": {
    css: ".issuer-logo-container",
    html: "issuer-logo-container",
    ".related-issuers-filter": {
      css: ".related-issuers-filter",
      html: "related-issuers-filter",
      ".custom-option": {
        css: ".custom-option",
        html: "custom-option",
        attrs: {
          ".selected": {
            css: ".selected",
            html: "selected"
          }
        },
        ".related-issuer": {
          css: ".related-issuer",
          html: "related-issuer",
          ".btn-primary": {
            css: ".btn-primary",
            html: "btn-primary",
            ".icon": {
              css: ".icon",
              html: "icon",
              ".color": {
                css: ".color",
                html: "color"
              },
              ".background": {
                css: ".background",
                html: "background"
              },
              ".border-color": {
                css: ".border-color",
                html: "border-color"
              }
            }
          },
          ".btn-callout": {
            css: ".btn-callout",
            html: "btn-callout",
            ".icon": {
              css: ".icon",
              html: "icon",
              ".color": {
                css: ".color",
                html: "color"
              },
              ".background": {
                css: ".background",
                html: "background"
              },
              ".border-color": {
                css: ".border-color",
                html: "border-color"
              }
            }
          },
          ".btn-secondary": {
            css: ".btn-secondary",
            html: "btn-secondary",
            ".icon": {
              css: ".icon",
              html: "icon",
              ".color": {
                css: ".color",
                html: "color"
              },
              ".background": {
                css: ".background",
                html: "background"
              },
              ".border-color": {
                css: ".border-color",
                html: "border-color"
              }
            }
          },
          "[data-disabled]": {
            css: "[data-disabled]",
            html: "[data-disabled]",
            attrs: {
              ".btn-callout": {
                css: ".btn-callout",
                html: "btn-callout"
              }
            },
            ".icon": {
              css: ".icon",
              html: "icon",
              ".color": {
                css: ".color",
                html: "color"
              },
              ".background": {
                css: ".background",
                html: "background"
              },
              ".border-color": {
                css: ".border-color",
                html: "border-color"
              }
            }
          },
          "[disabled]": {
            css: "[disabled]",
            html: "[disabled]",
            attrs: {
              ".btn-callout": {
                css: ".btn-callout",
                html: "btn-callout"
              }
            },
            ".icon": {
              css: ".icon",
              html: "icon",
              ".color": {
                css: ".color",
                html: "color"
              },
              ".background": {
                css: ".background",
                html: "background"
              },
              ".border-color": {
                css: ".border-color",
                html: "border-color"
              }
            }
          },
          ".btn-close": {
            css: ".btn-close",
            html: "btn-close"
          }
        }
      }
    }
  },
  ".main-nav": {
    css: ".main-nav",
    html: "main-nav",
    attrs: {
      ".sticky": {
        css: ".sticky",
        html: "sticky"
      }
    },
    ".issuer-bondlink-bar": {
      css: ".issuer-bondlink-bar",
      html: "issuer-bondlink-bar",
      ".actions": {
        css: ".actions",
        html: "actions",
        ".btn-primary": {
          css: ".btn-primary",
          html: "btn-primary",
          ".icon": {
            css: ".icon",
            html: "icon",
            ".color": {
              css: ".color",
              html: "color"
            },
            ".background": {
              css: ".background",
              html: "background"
            },
            ".border-color": {
              css: ".border-color",
              html: "border-color"
            }
          }
        },
        ".btn-callout": {
          css: ".btn-callout",
          html: "btn-callout",
          ".icon": {
            css: ".icon",
            html: "icon",
            ".color": {
              css: ".color",
              html: "color"
            },
            ".background": {
              css: ".background",
              html: "background"
            },
            ".border-color": {
              css: ".border-color",
              html: "border-color"
            }
          }
        },
        ".btn-secondary": {
          css: ".btn-secondary",
          html: "btn-secondary",
          ".icon": {
            css: ".icon",
            html: "icon",
            ".color": {
              css: ".color",
              html: "color"
            },
            ".background": {
              css: ".background",
              html: "background"
            },
            ".border-color": {
              css: ".border-color",
              html: "border-color"
            }
          }
        },
        "[data-disabled]": {
          css: "[data-disabled]",
          html: "[data-disabled]",
          attrs: {
            ".btn-callout": {
              css: ".btn-callout",
              html: "btn-callout"
            }
          },
          ".icon": {
            css: ".icon",
            html: "icon",
            ".color": {
              css: ".color",
              html: "color"
            },
            ".background": {
              css: ".background",
              html: "background"
            },
            ".border-color": {
              css: ".border-color",
              html: "border-color"
            }
          }
        },
        "[disabled]": {
          css: "[disabled]",
          html: "[disabled]",
          attrs: {
            ".btn-callout": {
              css: ".btn-callout",
              html: "btn-callout"
            }
          },
          ".icon": {
            css: ".icon",
            html: "icon",
            ".color": {
              css: ".color",
              html: "color"
            },
            ".background": {
              css: ".background",
              html: "background"
            },
            ".border-color": {
              css: ".border-color",
              html: "border-color"
            }
          }
        },
        ".btn-close": {
          css: ".btn-close",
          html: "btn-close"
        }
      }
    }
  },
  ".sticky": {
    css: ".sticky",
    html: "sticky",
    ".sticky-headers": {
      css: ".sticky-headers",
      html: "sticky-headers",
      ".btn-primary": {
        css: ".btn-primary",
        html: "btn-primary",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      ".btn-callout": {
        css: ".btn-callout",
        html: "btn-callout",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      ".btn-secondary": {
        css: ".btn-secondary",
        html: "btn-secondary",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      "[data-disabled]": {
        css: "[data-disabled]",
        html: "[data-disabled]",
        attrs: {
          ".btn-callout": {
            css: ".btn-callout",
            html: "btn-callout"
          }
        },
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      "[disabled]": {
        css: "[disabled]",
        html: "[disabled]",
        attrs: {
          ".btn-callout": {
            css: ".btn-callout",
            html: "btn-callout"
          }
        },
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      ".btn-close": {
        css: ".btn-close",
        html: "btn-close"
      }
    }
  },
  ".masthead": {
    css: ".masthead",
    html: "masthead",
    attrs: {
      ".deal-masthead": {
        css: ".deal-masthead",
        html: "deal-masthead"
      }
    },
    "h1": {
      css: "h1",
      html: "h1",
      ".btn-primary": {
        css: ".btn-primary",
        html: "btn-primary",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      ".btn-callout": {
        css: ".btn-callout",
        html: "btn-callout",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      ".btn-secondary": {
        css: ".btn-secondary",
        html: "btn-secondary",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      "[data-disabled]": {
        css: "[data-disabled]",
        html: "[data-disabled]",
        attrs: {
          ".btn-callout": {
            css: ".btn-callout",
            html: "btn-callout"
          }
        },
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      "[disabled]": {
        css: "[disabled]",
        html: "[disabled]",
        attrs: {
          ".btn-callout": {
            css: ".btn-callout",
            html: "btn-callout"
          }
        },
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      },
      ".btn-close": {
        css: ".btn-close",
        html: "btn-close"
      }
    }
  },
  ".btn-callout": {
    css: ".btn-callout",
    html: "btn-callout",
    ".icon": {
      css: ".icon",
      html: "icon",
      ".color": {
        css: ".color",
        html: "color"
      },
      ".background": {
        css: ".background",
        html: "background"
      },
      ".border-color": {
        css: ".border-color",
        html: "border-color"
      }
    },
    ".callout-icon": {
      css: ".callout-icon",
      html: "callout-icon",
      ".loading-state": {
        css: ".loading-state",
        html: "loading-state",
        ".loading-message": {
          css: ".loading-message",
          html: "loading-message",
          ".loader": {
            css: ".loader",
            html: "loader"
          }
        }
      },
      ".icon": {
        css: ".icon",
        html: "icon"
      },
      ".loader": {
        css: ".loader",
        html: "loader"
      }
    },
    ".callout-plus": {
      css: ".callout-plus",
      html: "callout-plus",
      ".icon-plus": {
        css: ".icon-plus",
        html: "icon-plus"
      }
    }
  },
  ".btn-secondary": {
    css: ".btn-secondary",
    html: "btn-secondary",
    ".icon": {
      css: ".icon",
      html: "icon",
      ".color": {
        css: ".color",
        html: "color"
      },
      ".background": {
        css: ".background",
        html: "background"
      },
      ".border-color": {
        css: ".border-color",
        html: "border-color"
      }
    }
  },
  ".btn-danger-secondary": {
    css: ".btn-danger-secondary",
    html: "btn-danger-secondary",
    ".icon": {
      css: ".icon",
      html: "icon",
      ".color": {
        css: ".color",
        html: "color"
      },
      ".background": {
        css: ".background",
        html: "background"
      },
      ".border-color": {
        css: ".border-color",
        html: "border-color"
      }
    }
  },
  ".btn-gray": {
    css: ".btn-gray",
    html: "btn-gray",
    ".icon": {
      css: ".icon",
      html: "icon",
      ".color": {
        css: ".color",
        html: "color"
      },
      ".background": {
        css: ".background",
        html: "background"
      },
      ".border-color": {
        css: ".border-color",
        html: "border-color"
      }
    }
  },
  ".btn-danger": {
    css: ".btn-danger",
    html: "btn-danger",
    ".icon": {
      css: ".icon",
      html: "icon",
      ".color": {
        css: ".color",
        html: "color"
      },
      ".background": {
        css: ".background",
        html: "background"
      },
      ".border-color": {
        css: ".border-color",
        html: "border-color"
      }
    }
  },
  ".btn-toggle-subscription": {
    css: ".btn-toggle-subscription",
    html: "btn-toggle-subscription",
    "[data-checked=\"true\"]": {
      css: "[data-checked=\"true\"]",
      html: "[data-checked=\"true\"]",
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background": {
          css: ".background",
          html: "background"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      },
      ":hover": {
        css: ":hover",
        html: "hover",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      }
    }
  },
  ".subscription-toggle": {
    css: ".subscription-toggle",
    html: "subscription-toggle",
    attrs: {
      ".tooltip-open": {
        css: ".tooltip-open",
        html: "tooltip-open"
      }
    },
    ".btn-toggle-subscription": {
      css: ".btn-toggle-subscription",
      html: "btn-toggle-subscription",
      "[data-checked=\"true\"]": {
        css: "[data-checked=\"true\"]",
        html: "[data-checked=\"true\"]",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".background": {
            css: ".background",
            html: "background"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          }
        }
      }
    },
    "[data-disabled]": {
      css: "[data-disabled]",
      html: "[data-disabled]",
      attrs: {
        ".btn-toggle-subscription": {
          css: ".btn-toggle-subscription",
          html: "btn-toggle-subscription"
        }
      },
      "[data-checked=\"true\"]": {
        css: "[data-checked=\"true\"]",
        html: "[data-checked=\"true\"]",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          },
          ".background": {
            css: ".background",
            html: "background"
          }
        }
      }
    },
    "[disabled]": {
      css: "[disabled]",
      html: "[disabled]",
      attrs: {
        ".btn-toggle-subscription": {
          css: ".btn-toggle-subscription",
          html: "btn-toggle-subscription"
        }
      },
      "[data-checked=\"true\"]": {
        css: "[data-checked=\"true\"]",
        html: "[data-checked=\"true\"]",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".color": {
            css: ".color",
            html: "color"
          },
          ".border-color": {
            css: ".border-color",
            html: "border-color"
          },
          ".background": {
            css: ".background",
            html: "background"
          }
        }
      }
    },
    ".icon-toggle-subscription": {
      css: ".icon-toggle-subscription",
      html: "icon-toggle-subscription",
      ".icon": {
        css: ".icon",
        html: "icon",
        ".primary": {
          css: ".primary",
          html: "primary"
        },
        ".background": {
          css: ".background",
          html: "background"
        }
      },
      "[data-hoverable=\"true\"]": {
        css: "[data-hoverable=\"true\"]",
        html: "[data-hoverable=\"true\"]",
        ".icon": {
          css: ".icon",
          html: "icon",
          ".primary": {
            css: ".primary",
            html: "primary"
          },
          ".background": {
            css: ".background",
            html: "background"
          }
        }
      }
    }
  },
  ".btn-approve": {
    css: ".btn-approve",
    html: "btn-approve",
    attrs: {
      ".btn-small": {
        css: ".btn-small",
        html: "btn-small"
      }
    },
    ".icon": {
      css: ".icon",
      html: "icon",
      ".color": {
        css: ".color",
        html: "color"
      },
      ".background": {
        css: ".background",
        html: "background"
      },
      ".border-color": {
        css: ".border-color",
        html: "border-color"
      },
      ".background-color": {
        css: ".background-color",
        html: "background-color"
      }
    },
    ":hover": {
      css: ":hover",
      html: "hover",
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background-color": {
          css: ".background-color",
          html: "background-color"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      }
    },
    ":active": {
      css: ":active",
      html: "active",
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".background-color": {
          css: ".background-color",
          html: "background-color"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        }
      }
    },
    "[data-disabled]": {
      css: "[data-disabled]",
      html: "[data-disabled]",
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        },
        ".background": {
          css: ".background",
          html: "background"
        }
      }
    },
    "[disabled]": {
      css: "[disabled]",
      html: "[disabled]",
      ".icon": {
        css: ".icon",
        html: "icon",
        ".color": {
          css: ".color",
          html: "color"
        },
        ".border-color": {
          css: ".border-color",
          html: "border-color"
        },
        ".background": {
          css: ".background",
          html: "background"
        }
      }
    }
  },
  ".green": {
    css: ".green",
    html: "green"
  },
  ".btn-stacked": {
    css: ".btn-stacked",
    html: "btn-stacked",
    ".stacked-icon": {
      css: ".stacked-icon",
      html: "stacked-icon",
      ".icon": {
        css: ".icon",
        html: "icon"
      }
    }
  },
  ".btn-reject": {
    css: ".btn-reject",
    html: "btn-reject",
    ".icon": {
      css: ".icon",
      html: "icon",
      ".color": {
        css: ".color",
        html: "color"
      },
      ".background": {
        css: ".background",
        html: "background"
      },
      ".border-color": {
        css: ".border-color",
        html: "border-color"
      },
      ".background-color": {
        css: ".background-color",
        html: "background-color"
      }
    }
  },
  ".btn-review": {
    css: ".btn-review",
    html: "btn-review",
    attrs: {
      ".chevron-open": {
        css: ".chevron-open",
        html: "chevron-open"
      }
    },
    ".icon": {
      css: ".icon",
      html: "icon",
      ".color": {
        css: ".color",
        html: "color"
      },
      ".background": {
        css: ".background",
        html: "background"
      },
      ".border-color": {
        css: ".border-color",
        html: "border-color"
      }
    }
  },
  ".btn-arrow": {
    css: ".btn-arrow",
    html: "btn-arrow",
    ":focus": {
      css: ":focus",
      html: "focus"
    }
  },
  ".btn-close": {
    css: ".btn-close",
    html: "btn-close"
  },
  ".btn-link": {
    css: ".btn-link",
    html: "btn-link",
    ":focus": {
      css: ":focus",
      html: "focus"
    }
  },
  ".btn-link-multiline": {
    css: ".btn-link-multiline",
    html: "btn-link-multiline",
    "span": {
      css: "span",
      html: "span"
    },
    ":focus": {
      css: ":focus",
      html: "focus"
    }
  },
  ".disabled-allow-transform": {
    css: ".disabled-allow-transform",
    html: "disabled-allow-transform",
    attrs: {
      ".btn-link": {
        css: ".btn-link",
        html: "btn-link"
      },
      ".btn-link-multiline": {
        css: ".btn-link-multiline",
        html: "btn-link-multiline"
      }
    },
    ":focus": {
      css: ":focus",
      html: "focus"
    }
  },
  ".disabled": {
    css: ".disabled",
    html: "disabled",
    attrs: {
      ".btn-link": {
        css: ".btn-link",
        html: "btn-link"
      },
      ".btn-link-multiline": {
        css: ".btn-link-multiline",
        html: "btn-link-multiline"
      }
    },
    ":focus": {
      css: ":focus",
      html: "focus"
    }
  },
  ".icon-button": {
    css: ".icon-button",
    html: "icon-button",
    ":focus": {
      css: ":focus",
      html: "focus"
    },
    ".icon": {
      css: ".icon",
      html: "icon"
    },
    ".icon-close-x": {
      css: ".icon-close-x",
      html: "icon-close-x"
    }
  },
  ".icon-toggle-subscription": {
    css: ".icon-toggle-subscription",
    html: "icon-toggle-subscription",
    ":focus": {
      css: ":focus",
      html: "focus"
    },
    ".icon": {
      css: ".icon",
      html: "icon",
      ".primary": {
        css: ".primary",
        html: "primary"
      },
      ".background": {
        css: ".background",
        html: "background"
      }
    }
  },
  ".input-aligned": {
    css: ".input-aligned",
    html: "input-aligned",
    attrs: {
      ".btn-link": {
        css: ".btn-link",
        html: "btn-link"
      },
      ".btn-link-multiline": {
        css: ".btn-link-multiline",
        html: "btn-link-multiline"
      },
      ".no-min-height": {
        css: ".no-min-height",
        html: "no-min-height"
      },
      ".wrap-button": {
        css: ".wrap-button",
        html: "wrap-button"
      }
    }
  },
  ".btn-callout-container": {
    css: ".btn-callout-container",
    html: "btn-callout-container",
    "*": {
      css: "*",
      html: "*"
    }
  },
  ".dashboard-callouts": {
    css: ".dashboard-callouts",
    html: "dashboard-callouts",
    ".btn-callout": {
      css: ".btn-callout",
      html: "btn-callout",
      ".callout-icon": {
        css: ".callout-icon",
        html: "callout-icon",
        ".icon": {
          css: ".icon",
          html: "icon"
        },
        ".loader": {
          css: ".loader",
          html: "loader"
        },
        ".loading-state": {
          css: ".loading-state",
          html: "loading-state",
          ".loading-message": {
            css: ".loading-message",
            html: "loading-message",
            ".loader": {
              css: ".loader",
              html: "loader"
            }
          }
        }
      },
      ".callout-plus": {
        css: ".callout-plus",
        html: "callout-plus",
        ".icon-plus": {
          css: ".icon-plus",
          html: "icon-plus"
        }
      }
    }
  },
  ".callouts-row": {
    css: ".callouts-row",
    html: "callouts-row"
  },
  ".btn-crop": {
    css: ".btn-crop",
    html: "btn-crop",
    attrs: {
      ".btn": {
        css: ".btn",
        html: "btn"
      }
    },
    ".icon-container": {
      css: ".icon-container",
      html: "icon-container"
    },
    ".callout-plus": {
      css: ".callout-plus",
      html: "callout-plus"
    },
    ".icon-image": {
      css: ".icon-image",
      html: "icon-image"
    }
  },
  ".has-danger": {
    css: ".has-danger",
    html: "has-danger",
    ".btn-crop": {
      css: ".btn-crop",
      html: "btn-crop"
    }
  },
  ".btn-action-base": {
    css: ".btn-action-base",
    html: "btn-action-base",
    attrs: {
      ".no-hover": {
        css: ".no-hover",
        html: "no-hover"
      }
    },
    ".content": {
      css: ".content",
      html: "content"
    },
    ".btn": {
      css: ".btn",
      html: "btn"
    },
    "button": {
      css: "button",
      html: "button"
    },
    ".btn-link": {
      css: ".btn-link",
      html: "btn-link",
      attrs: {
        ".action-type": {
          css: ".action-type",
          html: "action-type"
        }
      },
      ".icon": {
        css: ".icon",
        html: "icon",
        attrs: {
          ".icon-close-x": {
            css: ".icon-close-x",
            html: "icon-close-x"
          }
        }
      },
      ".content-label": {
        css: ".content-label",
        html: "content-label"
      }
    }
  },
  ".btn-action-short": {
    css: ".btn-action-short",
    html: "btn-action-short",
    ".btn": {
      css: ".btn",
      html: "btn"
    },
    "button": {
      css: "button",
      html: "button"
    },
    ".btn-link": {
      css: ".btn-link",
      html: "btn-link",
      attrs: {
        ".action-type": {
          css: ".action-type",
          html: "action-type"
        },
        ".content": {
          css: ".content",
          html: "content"
        }
      },
      ".icon": {
        css: ".icon",
        html: "icon",
        attrs: {
          ".icon-close-x": {
            css: ".icon-close-x",
            html: "icon-close-x"
          }
        }
      },
      ".content-label": {
        css: ".content-label",
        html: "content-label",
        ".content-name": {
          css: ".content-name",
          html: "content-name"
        }
      }
    }
  },
  ".btn-action-tall": {
    css: ".btn-action-tall",
    html: "btn-action-tall",
    ".btn": {
      css: ".btn",
      html: "btn"
    },
    "button": {
      css: "button",
      html: "button"
    },
    ".btn-link": {
      css: ".btn-link",
      html: "btn-link",
      attrs: {
        ".action-type": {
          css: ".action-type",
          html: "action-type"
        }
      },
      ".icon": {
        css: ".icon",
        html: "icon",
        attrs: {
          ".icon-close-x": {
            css: ".icon-close-x",
            html: "icon-close-x"
          }
        }
      },
      ".content-label": {
        css: ".content-label",
        html: "content-label",
        ".content-name": {
          css: ".content-name",
          html: "content-name",
          attrs: {
            ".has-meta": {
              css: ".has-meta",
              html: "has-meta"
            }
          }
        },
        ".content-meta": {
          css: ".content-meta",
          html: "content-meta"
        }
      },
      ".content-type": {
        css: ".content-type",
        html: "content-type",
        ".icon": {
          css: ".icon",
          html: "icon"
        },
        "img": {
          css: "img",
          html: "img"
        }
      }
    }
  },
  ".no-hover": {
    css: ".no-hover",
    html: "no-hover",
    attrs: {
      ".btn-action-short": {
        css: ".btn-action-short",
        html: "btn-action-short"
      },
      ".btn-action-tall": {
        css: ".btn-action-tall",
        html: "btn-action-tall"
      }
    },
    ".content": {
      css: ".content",
      html: "content"
    }
  },
  ".btn-circle": {
    css: ".btn-circle",
    html: "btn-circle",
    ".icon": {
      css: ".icon",
      html: "icon"
    }
  },
  ".icon": {
    css: ".icon",
    html: "icon",
    attrs: {
      ".external-link-icon": {
        css: ".external-link-icon",
        html: "external-link-icon"
      }
    }
  },
  ".buttons-container": {
    css: ".buttons-container",
    html: "buttons-container",
    attrs: {
      ".divider": {
        css: ".divider",
        html: "divider"
      }
    },
    ".btn": {
      css: ".btn",
      html: "btn"
    }
  },
  ".form-section": {
    css: ".form-section",
    html: "form-section",
    ".buttons-container": {
      css: ".buttons-container",
      html: "buttons-container"
    }
  },
  ".inline-edit-buttons": {
    css: ".inline-edit-buttons",
    html: "inline-edit-buttons",
    ".form-input": {
      css: ".form-input",
      html: "form-input"
    },
    ".text-editor-skeleton": {
      css: ".text-editor-skeleton",
      html: "text-editor-skeleton"
    },
    ".list-select": {
      css: ".list-select",
      html: "list-select"
    },
    ".actions": {
      css: ".actions",
      html: "actions",
      attrs: {
        ".show": {
          css: ".show",
          html: "show"
        }
      },
      "button": {
        css: "button",
        html: "button"
      },
      ".discard": {
        css: ".discard",
        html: "discard"
      },
      ".save": {
        css: ".save",
        html: "save"
      },
      "svg": {
        css: "svg",
        html: "svg"
      }
    }
  },
  ".primary-2-color-icon": {
    css: ".primary-2-color-icon",
    html: "primary-2-color-icon",
    ".icon": {
      css: ".icon",
      html: "icon",
      ".primary": {
        css: ".primary",
        html: "primary"
      }
    }
  },
  ".icon-2-color-teal": {
    css: ".icon-2-color-teal",
    html: "icon-2-color-teal",
    ".icon": {
      css: ".icon",
      html: "icon",
      ".primary": {
        css: ".primary",
        html: "primary"
      }
    }
  },
  ".icon-2-color-disabled": {
    css: ".icon-2-color-disabled",
    html: "icon-2-color-disabled",
    ".icon": {
      css: ".icon",
      html: "icon",
      ".primary": {
        css: ".primary",
        html: "primary"
      },
      ".background": {
        css: ".background",
        html: "background"
      }
    }
  },
  ".subscription-count-container": {
    css: ".subscription-count-container",
    html: "subscription-count-container",
    attrs: {
      ".no-subscriptions": {
        css: ".no-subscriptions",
        html: "no-subscriptions"
      }
    },
    ".icon": {
      css: ".icon",
      html: "icon",
      ".primary": {
        css: ".primary",
        html: "primary"
      },
      ".background": {
        css: ".background",
        html: "background"
      }
    }
  }
};
