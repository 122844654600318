import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { mediaUploadResponseC as imported18_mediaUploadResponseC } from "./media";
import * as t from "io-ts";
import { markdownC } from "../../codecs/markdown";

export const bankSettingsPostC = t.type({
  name: t.string,
  email: t.string,
  logo: t.string,
  headerImage: optionFromNullable(imported18_mediaUploadResponseC),
  primaryColor: t.string,
  secondaryColor: t.string,
  primaryForBg: t.boolean,
  aboutText: optionFromNullable(markdownC)
});
export type BankSettingsPostC = typeof bankSettingsPostC;
export type BankSettingsPost = t.TypeOf<BankSettingsPostC>;


