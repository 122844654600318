import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { pipe } from "fp-ts/lib/function";
import { bankC as imported15_bankC } from "./bank";
import { either } from "io-ts-types/lib/either";
import { rfpC as imported52_rfpC } from "./rfpBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { bondOfferingDetailsC as imported36_bondOfferingDetailsC } from "./bondOffering";
import { emailContactActorC as imported1_emailContactActorC, actorC as imported8_actorC } from "./actor";
import { withModInfoC as imported156_withModInfoC, withIdC as imported225_withIdC, withIdC as imported224_withIdC, withIdC as imported223_withIdC, withModInfoC as imported157_withModInfoC } from "./threadThrough";
import { issuerC as imported40_issuerC, issuerC as imported41_issuerC } from "./issuer";

export const emailCampaignC = t.type({
  subject: t.string,
  sent: LocalDateTimeFromIsoStringC,
  associatedIssuer: optionFromNullable(imported40_issuerC),
  bondOffering: optionFromNullable(imported223_withIdC(imported156_withModInfoC(imported36_bondOfferingDetailsC))),
  rfp: optionFromNullable(imported224_withIdC(imported157_withModInfoC(imported52_rfpC)))
});
export type EmailCampaignC = typeof emailCampaignC;
export type EmailCampaign = t.TypeOf<EmailCampaignC>;


export class emailCampaignStatsCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  recipients: A1,
  suppressions: A1,
  unsubscribes: A1,
  opens: A1,
  clicks: A1,
  bounces: A1,
  drops: A1,
  blocks: A1,
  spamReports: A1
})}
export const emailCampaignStatsC = <A1 extends t.Mixed>(A1: A1) => new emailCampaignStatsCC<A1>().codec(A1);
export type EmailCampaignStatsC<A1 extends t.Mixed> = ReturnType<emailCampaignStatsCC<A1>["codec"]>;
export type EmailCampaignStats<A1> = t.TypeOf<EmailCampaignStatsC<t.Type<A1>>>;

export const emailCampaignWithStatsC = t.type({
  campaign: imported225_withIdC(emailCampaignC),
  stats: emailCampaignStatsC(t.number)
});
export type EmailCampaignWithStatsC = typeof emailCampaignWithStatsC;
export type EmailCampaignWithStats = t.TypeOf<EmailCampaignWithStatsC>;


export const emailCampaignRecipientC = t.type({
  actor: either(imported1_emailContactActorC, imported8_actorC),
  stats: emailCampaignStatsC(t.boolean)
});
export type EmailCampaignRecipientC = typeof emailCampaignRecipientC;
export type EmailCampaignRecipient = t.TypeOf<EmailCampaignRecipientC>;


export const emailCampaignWithRecipientsC = t.type({
  campaign: emailCampaignWithStatsC,
  recipients: readonlyNonEmptyArrayC(emailCampaignRecipientC)
});
export type EmailCampaignWithRecipientsC = typeof emailCampaignWithRecipientsC;
export type EmailCampaignWithRecipients = t.TypeOf<EmailCampaignWithRecipientsC>;


export const bankEmailCampaignPostC = t.type({
  id: t.number,
  associatedIssuerId: optionFromNullable(t.number),
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number)
});
export type BankEmailCampaignPostC = typeof bankEmailCampaignPostC;
export type BankEmailCampaignPost = t.TypeOf<BankEmailCampaignPostC>;


export const issuerEmailCampaignPostC = t.type({
  id: t.number,
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number)
});
export type IssuerEmailCampaignPostC = typeof issuerEmailCampaignPostC;
export type IssuerEmailCampaignPost = t.TypeOf<IssuerEmailCampaignPostC>;


export const emailUnsubscribeDataC = t.type({
  emailAddress: t.string,
  issuer: optionFromNullable(imported41_issuerC),
  bank: optionFromNullable(imported15_bankC)
});
export type EmailUnsubscribeDataC = typeof emailUnsubscribeDataC;
export type EmailUnsubscribeData = t.TypeOf<EmailUnsubscribeDataC>;


export const unsubscribeBankC = t.type({
  _tag: t.literal(`UnsubscribeBank`),
  id: t.number
});
export type UnsubscribeBankC = typeof unsubscribeBankC;
export type UnsubscribeBank = t.TypeOf<UnsubscribeBankC>;


export const unsubscribeAll = {
  _tag: `UnsubscribeAll`
} as const;

export const unsubscribeAllTaggedC = t.type({
  _tag: t.literal(`UnsubscribeAll`)
});
export type UnsubscribeAllTaggedC = typeof unsubscribeAllTaggedC;
export type UnsubscribeAllTagged = t.TypeOf<UnsubscribeAllTaggedC>;
export type UnsubscribeAll = UnsubscribeAllTagged & typeof unsubscribeAll;
export const unsubscribeAllC = pipe(unsubscribeAllTaggedC, c => new t.Type<UnsubscribeAll, UnsubscribeAllTagged>(
  `UnsubscribeAll`,
  (u: unknown): u is UnsubscribeAll => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UnsubscribeAll> => pipe(c.decode(u), E.map(x => ({ ...x, ...unsubscribeAll }))),
  (x: UnsubscribeAll): UnsubscribeAllTagged => ({ ...x, _tag: `UnsubscribeAll`}),
));
export type UnsubscribeAllC = typeof unsubscribeAllC;


export const unsubscribeIssuerC = t.type({
  _tag: t.literal(`UnsubscribeIssuer`),
  id: t.number
});
export type UnsubscribeIssuerC = typeof unsubscribeIssuerC;
export type UnsubscribeIssuer = t.TypeOf<UnsubscribeIssuerC>;


export const allUnsubscribeTypeC = [unsubscribeAllC, unsubscribeIssuerC, unsubscribeBankC] as const;
export const allUnsubscribeTypeNames = [`UnsubscribeAll`, `UnsubscribeIssuer`, `UnsubscribeBank`] as const;
export type UnsubscribeTypeName = (typeof allUnsubscribeTypeNames)[number];

export const UnsubscribeTypeCU = t.union([unsubscribeAllC, unsubscribeIssuerC, unsubscribeBankC]);
export type UnsubscribeTypeCU = typeof UnsubscribeTypeCU;
export type UnsubscribeTypeU = t.TypeOf<UnsubscribeTypeCU>;

export type UnsubscribeTypeMap<A> = { [K in UnsubscribeTypeName]: A };


export const emailUnsubscribePostC = t.type({
  email: t.string,
  type: UnsubscribeTypeCU
});
export type EmailUnsubscribePostC = typeof emailUnsubscribePostC;
export type EmailUnsubscribePost = t.TypeOf<EmailUnsubscribePostC>;


