import * as t from "io-ts";
import { IssuerRelationshipCU as imported1_IssuerRelationshipCU } from "../domaintables/issuerRelationships";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { markdownC } from "../../codecs/markdown";
import { WithStatusCU as imported222_WithStatusCU } from "./threadThrough";
import { issuerC as imported36_issuerC } from "./issuer";

export const relatedIssuerC = t.type({
  relatedIssuerId: optionFromNullable(t.number),
  issuerRelationship: imported1_IssuerRelationshipCU,
  outsideLink: optionFromNullable(t.string),
  notes: optionFromNullable(markdownC),
  issuerOrder: t.number,
  name: t.string
});
export type RelatedIssuerC = typeof relatedIssuerC;
export type RelatedIssuer = t.TypeOf<RelatedIssuerC>;


export const relatedIssuerPostBodyC = t.type({
  id: optionFromNullable(t.number),
  relatedIssuerId: optionFromNullable(t.number),
  outsideLink: optionFromNullable(t.string),
  notes: optionFromNullable(markdownC),
  name: t.string
});
export type RelatedIssuerPostBodyC = typeof relatedIssuerPostBodyC;
export type RelatedIssuerPostBody = t.TypeOf<RelatedIssuerPostBodyC>;


export const relatedIssuerWithIssuerC = t.type({
  related: relatedIssuerC,
  issuer: optionFromNullable(imported36_issuerC)
});
export type RelatedIssuerWithIssuerC = typeof relatedIssuerWithIssuerC;
export type RelatedIssuerWithIssuer = t.TypeOf<RelatedIssuerWithIssuerC>;


export const issuersTypeAheadC = t.type({
  id: t.number,
  name: t.string,
  slug: t.string,
  customDomain: optionFromNullable(t.string)
});
export type IssuersTypeAheadC = typeof issuersTypeAheadC;
export type IssuersTypeAhead = t.TypeOf<IssuersTypeAheadC>;


export const relatedIssuersC = t.type({
  relatedIssuers: t.readonlyArray(imported222_WithStatusCU(relatedIssuerWithIssuerC)),
  issuersTypeAhead: t.readonlyArray(issuersTypeAheadC)
});
export type RelatedIssuersC = typeof relatedIssuersC;
export type RelatedIssuers = t.TypeOf<RelatedIssuersC>;


