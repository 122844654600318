import { query, lit, int, end, Match } from "fp-ts-routing/lib";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

import { LocalDateC } from "../../codecs/localDate";
import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { Describe } from "../../fp-ts/lib/types/describe";
import { bankerAdmin as imported44_bankerAdmin, bankerAdmin as imported33_bankerAdmin, bankerAdmin as imported31_bankerAdmin, bankerUser as imported44_bankerUser, bankerUser as imported34_bankerUser, bankerAdmin as imported32_bankerAdmin, bankerAdmin as imported26_bankerAdmin, bankerUser as imported24_bankerUser, bankerUser as imported33_bankerUser, bankerUser as imported29_bankerUser, bankerAdmin as imported39_bankerAdmin, bankerUser as imported43_bankerUser, bankerUser as imported39_bankerUser, bankerUser as imported42_bankerUser, bankerAdmin as imported35_bankerAdmin, bankerAdmin as imported29_bankerAdmin, bankerAdmin as imported42_bankerAdmin, bankerUser as imported36_bankerUser, bankerUser as imported41_bankerUser, bankerAdmin as imported28_bankerAdmin, bankerUser as imported32_bankerUser, bankerUser as imported25_bankerUser, bankerAdmin as imported43_bankerAdmin, bankerUser as imported28_bankerUser, bankerAdmin as imported25_bankerAdmin, bankerUser as imported40_bankerUser, bankerAdmin as imported37_bankerAdmin, bankerAdmin as imported40_bankerAdmin, bankerAdmin as imported47_bankerAdmin, bankerAdmin as imported45_bankerAdmin, bankerUser as imported26_bankerUser, bankerAdmin as imported30_bankerAdmin, bankerAdmin as imported38_bankerAdmin, bankerAdmin as imported27_bankerAdmin, bankerUser as imported38_bankerUser, bankerAdmin as imported46_bankerAdmin, bankerUser as imported35_bankerUser, bankerUser as imported27_bankerUser, bankerAdmin as imported34_bankerAdmin, bankerUser as imported45_bankerUser, bankerAdmin as imported41_bankerAdmin, bankerUser as imported37_bankerUser, bankerAdmin as imported36_bankerAdmin, bankerUser as imported30_bankerUser, bankerUser as imported31_bankerUser } from "./roles";


export const dashboard = {
  _tag: `Dashboard`,
  rolesAllowed: [imported24_bankerUser, imported25_bankerAdmin]
} as const;

export const dashboardTaggedC = t.type({
  _tag: t.literal(`Dashboard`)
});
export type DashboardTaggedC = typeof dashboardTaggedC;
export type DashboardTagged = t.TypeOf<DashboardTaggedC>;
export type Dashboard = DashboardTagged & typeof dashboard;
export const dashboardC = pipe(dashboardTaggedC, c => new t.Type<Dashboard, DashboardTagged>(
  `Dashboard`,
  (u: unknown): u is Dashboard => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Dashboard> => pipe(c.decode(u), E.map(x => ({ ...x, ...dashboard }))),
  (x: Dashboard): DashboardTagged => ({ ...x, _tag: `Dashboard`}),
));
export type DashboardC = typeof dashboardC;


export const investorActivity = {
  _tag: `InvestorActivity`,
  rolesAllowed: [imported25_bankerUser, imported26_bankerAdmin]
} as const;

export const investorActivityTaggedC = t.type({
  _tag: t.literal(`InvestorActivity`)
});
export type InvestorActivityTaggedC = typeof investorActivityTaggedC;
export type InvestorActivityTagged = t.TypeOf<InvestorActivityTaggedC>;
export type InvestorActivity = InvestorActivityTagged & typeof investorActivity;
export const investorActivityC = pipe(investorActivityTaggedC, c => new t.Type<InvestorActivity, InvestorActivityTagged>(
  `InvestorActivity`,
  (u: unknown): u is InvestorActivity => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorActivity> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorActivity }))),
  (x: InvestorActivity): InvestorActivityTagged => ({ ...x, _tag: `InvestorActivity`}),
));
export type InvestorActivityC = typeof investorActivityC;


export const issuers = {
  _tag: `Issuers`,
  rolesAllowed: [imported26_bankerUser, imported27_bankerAdmin]
} as const;

export const issuersTaggedC = t.type({
  _tag: t.literal(`Issuers`)
});
export type IssuersTaggedC = typeof issuersTaggedC;
export type IssuersTagged = t.TypeOf<IssuersTaggedC>;
export type Issuers = IssuersTagged & typeof issuers;
export const issuersC = pipe(issuersTaggedC, c => new t.Type<Issuers, IssuersTagged>(
  `Issuers`,
  (u: unknown): u is Issuers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Issuers> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuers }))),
  (x: Issuers): IssuersTagged => ({ ...x, _tag: `Issuers`}),
));
export type IssuersC = typeof issuersC;


export const portalUsers = {
  _tag: `PortalUsers`,
  rolesAllowed: [imported28_bankerAdmin]
} as const;

export const portalUsersTaggedC = t.type({
  _tag: t.literal(`PortalUsers`)
});
export type PortalUsersTaggedC = typeof portalUsersTaggedC;
export type PortalUsersTagged = t.TypeOf<PortalUsersTaggedC>;
export type PortalUsers = PortalUsersTagged & typeof portalUsers;
export const portalUsersC = pipe(portalUsersTaggedC, c => new t.Type<PortalUsers, PortalUsersTagged>(
  `PortalUsers`,
  (u: unknown): u is PortalUsers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PortalUsers> => pipe(c.decode(u), E.map(x => ({ ...x, ...portalUsers }))),
  (x: PortalUsers): PortalUsersTagged => ({ ...x, _tag: `PortalUsers`}),
));
export type PortalUsersC = typeof portalUsersC;


export const bondOfferings = {
  _tag: `BondOfferings`,
  rolesAllowed: [imported27_bankerUser, imported29_bankerAdmin]
} as const;

export const bondOfferingsTaggedC = t.type({
  _tag: t.literal(`BondOfferings`)
});
export type BondOfferingsTaggedC = typeof bondOfferingsTaggedC;
export type BondOfferingsTagged = t.TypeOf<BondOfferingsTaggedC>;
export type BondOfferings = BondOfferingsTagged & typeof bondOfferings;
export const bondOfferingsC = pipe(bondOfferingsTaggedC, c => new t.Type<BondOfferings, BondOfferingsTagged>(
  `BondOfferings`,
  (u: unknown): u is BondOfferings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferings> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferings }))),
  (x: BondOfferings): BondOfferingsTagged => ({ ...x, _tag: `BondOfferings`}),
));
export type BondOfferingsC = typeof bondOfferingsC;


export const analyticsOverall = {
  _tag: `AnalyticsOverall`,
  rolesAllowed: [imported28_bankerUser, imported30_bankerAdmin]
} as const;

export const analyticsOverallTaggedC = t.type({
  _tag: t.literal(`AnalyticsOverall`)
});
export type AnalyticsOverallTaggedC = typeof analyticsOverallTaggedC;
export type AnalyticsOverallTagged = t.TypeOf<AnalyticsOverallTaggedC>;
export type AnalyticsOverall = AnalyticsOverallTagged & typeof analyticsOverall;
export const analyticsOverallC = pipe(analyticsOverallTaggedC, c => new t.Type<AnalyticsOverall, AnalyticsOverallTagged>(
  `AnalyticsOverall`,
  (u: unknown): u is AnalyticsOverall => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AnalyticsOverall> => pipe(c.decode(u), E.map(x => ({ ...x, ...analyticsOverall }))),
  (x: AnalyticsOverall): AnalyticsOverallTagged => ({ ...x, _tag: `AnalyticsOverall`}),
));
export type AnalyticsOverallC = typeof analyticsOverallC;


export const analyticsBonds = {
  _tag: `AnalyticsBonds`,
  rolesAllowed: [imported29_bankerUser, imported31_bankerAdmin]
} as const;

export const analyticsBondsTaggedC = t.type({
  _tag: t.literal(`AnalyticsBonds`)
});
export type AnalyticsBondsTaggedC = typeof analyticsBondsTaggedC;
export type AnalyticsBondsTagged = t.TypeOf<AnalyticsBondsTaggedC>;
export type AnalyticsBonds = AnalyticsBondsTagged & typeof analyticsBonds;
export const analyticsBondsC = pipe(analyticsBondsTaggedC, c => new t.Type<AnalyticsBonds, AnalyticsBondsTagged>(
  `AnalyticsBonds`,
  (u: unknown): u is AnalyticsBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AnalyticsBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...analyticsBonds }))),
  (x: AnalyticsBonds): AnalyticsBondsTagged => ({ ...x, _tag: `AnalyticsBonds`}),
));
export type AnalyticsBondsC = typeof analyticsBondsC;


export const analyticsEmails = {
  _tag: `AnalyticsEmails`,
  rolesAllowed: [imported30_bankerUser, imported32_bankerAdmin]
} as const;

export const analyticsEmailsTaggedC = t.type({
  _tag: t.literal(`AnalyticsEmails`)
});
export type AnalyticsEmailsTaggedC = typeof analyticsEmailsTaggedC;
export type AnalyticsEmailsTagged = t.TypeOf<AnalyticsEmailsTaggedC>;
export type AnalyticsEmails = AnalyticsEmailsTagged & typeof analyticsEmails;
export const analyticsEmailsC = pipe(analyticsEmailsTaggedC, c => new t.Type<AnalyticsEmails, AnalyticsEmailsTagged>(
  `AnalyticsEmails`,
  (u: unknown): u is AnalyticsEmails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AnalyticsEmails> => pipe(c.decode(u), E.map(x => ({ ...x, ...analyticsEmails }))),
  (x: AnalyticsEmails): AnalyticsEmailsTagged => ({ ...x, _tag: `AnalyticsEmails`}),
));
export type AnalyticsEmailsC = typeof analyticsEmailsC;


export const rfps = {
  _tag: `Rfps`,
  rolesAllowed: [imported31_bankerUser, imported33_bankerAdmin]
} as const;

export const rfpsTaggedC = t.type({
  _tag: t.literal(`Rfps`)
});
export type RfpsTaggedC = typeof rfpsTaggedC;
export type RfpsTagged = t.TypeOf<RfpsTaggedC>;
export type Rfps = RfpsTagged & typeof rfps;
export const rfpsC = pipe(rfpsTaggedC, c => new t.Type<Rfps, RfpsTagged>(
  `Rfps`,
  (u: unknown): u is Rfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Rfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfps }))),
  (x: Rfps): RfpsTagged => ({ ...x, _tag: `Rfps`}),
));
export type RfpsC = typeof rfpsC;


export const investorProfile = {
  _tag: `InvestorProfile`,
  rolesAllowed: [imported32_bankerUser, imported34_bankerAdmin]
} as const;

export const investorProfileTaggedC = t.type({
  _tag: t.literal(`InvestorProfile`)
});
export type InvestorProfileTaggedC = typeof investorProfileTaggedC;
export type InvestorProfileTagged = t.TypeOf<InvestorProfileTaggedC>;
export type InvestorProfile = InvestorProfileTagged & typeof investorProfile;
export const investorProfileC = pipe(investorProfileTaggedC, c => new t.Type<InvestorProfile, InvestorProfileTagged>(
  `InvestorProfile`,
  (u: unknown): u is InvestorProfile => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorProfile> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorProfile }))),
  (x: InvestorProfile): InvestorProfileTagged => ({ ...x, _tag: `InvestorProfile`}),
));
export type InvestorProfileC = typeof investorProfileC;


export const bondOfferingsDealFileSummary = {
  _tag: `BondOfferingsDealFileSummary`,
  rolesAllowed: [imported33_bankerUser, imported35_bankerAdmin]
} as const;

export const bondOfferingsDealFileSummaryTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDealFileSummary`)
});
export type BondOfferingsDealFileSummaryTaggedC = typeof bondOfferingsDealFileSummaryTaggedC;
export type BondOfferingsDealFileSummaryTagged = t.TypeOf<BondOfferingsDealFileSummaryTaggedC>;
export type BondOfferingsDealFileSummary = BondOfferingsDealFileSummaryTagged & typeof bondOfferingsDealFileSummary;
export const bondOfferingsDealFileSummaryC = pipe(bondOfferingsDealFileSummaryTaggedC, c => new t.Type<BondOfferingsDealFileSummary, BondOfferingsDealFileSummaryTagged>(
  `BondOfferingsDealFileSummary`,
  (u: unknown): u is BondOfferingsDealFileSummary => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDealFileSummary> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDealFileSummary }))),
  (x: BondOfferingsDealFileSummary): BondOfferingsDealFileSummaryTagged => ({ ...x, _tag: `BondOfferingsDealFileSummary`}),
));
export type BondOfferingsDealFileSummaryC = typeof bondOfferingsDealFileSummaryC;


export const bondOfferingsDealFileDetails = {
  _tag: `BondOfferingsDealFileDetails`,
  rolesAllowed: [imported34_bankerUser, imported36_bankerAdmin]
} as const;

export const bondOfferingsDealFileDetailsTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDealFileDetails`)
});
export type BondOfferingsDealFileDetailsTaggedC = typeof bondOfferingsDealFileDetailsTaggedC;
export type BondOfferingsDealFileDetailsTagged = t.TypeOf<BondOfferingsDealFileDetailsTaggedC>;
export type BondOfferingsDealFileDetails = BondOfferingsDealFileDetailsTagged & typeof bondOfferingsDealFileDetails;
export const bondOfferingsDealFileDetailsC = pipe(bondOfferingsDealFileDetailsTaggedC, c => new t.Type<BondOfferingsDealFileDetails, BondOfferingsDealFileDetailsTagged>(
  `BondOfferingsDealFileDetails`,
  (u: unknown): u is BondOfferingsDealFileDetails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDealFileDetails> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDealFileDetails }))),
  (x: BondOfferingsDealFileDetails): BondOfferingsDealFileDetailsTagged => ({ ...x, _tag: `BondOfferingsDealFileDetails`}),
));
export type BondOfferingsDealFileDetailsC = typeof bondOfferingsDealFileDetailsC;


export const bondOfferingsDealFileInvestors = {
  _tag: `BondOfferingsDealFileInvestors`,
  rolesAllowed: [imported35_bankerUser, imported37_bankerAdmin]
} as const;

export const bondOfferingsDealFileInvestorsTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDealFileInvestors`)
});
export type BondOfferingsDealFileInvestorsTaggedC = typeof bondOfferingsDealFileInvestorsTaggedC;
export type BondOfferingsDealFileInvestorsTagged = t.TypeOf<BondOfferingsDealFileInvestorsTaggedC>;
export type BondOfferingsDealFileInvestors = BondOfferingsDealFileInvestorsTagged & typeof bondOfferingsDealFileInvestors;
export const bondOfferingsDealFileInvestorsC = pipe(bondOfferingsDealFileInvestorsTaggedC, c => new t.Type<BondOfferingsDealFileInvestors, BondOfferingsDealFileInvestorsTagged>(
  `BondOfferingsDealFileInvestors`,
  (u: unknown): u is BondOfferingsDealFileInvestors => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDealFileInvestors> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDealFileInvestors }))),
  (x: BondOfferingsDealFileInvestors): BondOfferingsDealFileInvestorsTagged => ({ ...x, _tag: `BondOfferingsDealFileInvestors`}),
));
export type BondOfferingsDealFileInvestorsC = typeof bondOfferingsDealFileInvestorsC;


export const bondOfferingsDealFileAnalytics = {
  _tag: `BondOfferingsDealFileAnalytics`,
  rolesAllowed: [imported36_bankerUser, imported38_bankerAdmin]
} as const;

export const bondOfferingsDealFileAnalyticsTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDealFileAnalytics`)
});
export type BondOfferingsDealFileAnalyticsTaggedC = typeof bondOfferingsDealFileAnalyticsTaggedC;
export type BondOfferingsDealFileAnalyticsTagged = t.TypeOf<BondOfferingsDealFileAnalyticsTaggedC>;
export type BondOfferingsDealFileAnalytics = BondOfferingsDealFileAnalyticsTagged & typeof bondOfferingsDealFileAnalytics;
export const bondOfferingsDealFileAnalyticsC = pipe(bondOfferingsDealFileAnalyticsTaggedC, c => new t.Type<BondOfferingsDealFileAnalytics, BondOfferingsDealFileAnalyticsTagged>(
  `BondOfferingsDealFileAnalytics`,
  (u: unknown): u is BondOfferingsDealFileAnalytics => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDealFileAnalytics> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDealFileAnalytics }))),
  (x: BondOfferingsDealFileAnalytics): BondOfferingsDealFileAnalyticsTagged => ({ ...x, _tag: `BondOfferingsDealFileAnalytics`}),
));
export type BondOfferingsDealFileAnalyticsC = typeof bondOfferingsDealFileAnalyticsC;


export const bondOfferingsDealFileEmails = {
  _tag: `BondOfferingsDealFileEmails`,
  rolesAllowed: [imported37_bankerUser, imported39_bankerAdmin]
} as const;

export const bondOfferingsDealFileEmailsTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDealFileEmails`)
});
export type BondOfferingsDealFileEmailsTaggedC = typeof bondOfferingsDealFileEmailsTaggedC;
export type BondOfferingsDealFileEmailsTagged = t.TypeOf<BondOfferingsDealFileEmailsTaggedC>;
export type BondOfferingsDealFileEmails = BondOfferingsDealFileEmailsTagged & typeof bondOfferingsDealFileEmails;
export const bondOfferingsDealFileEmailsC = pipe(bondOfferingsDealFileEmailsTaggedC, c => new t.Type<BondOfferingsDealFileEmails, BondOfferingsDealFileEmailsTagged>(
  `BondOfferingsDealFileEmails`,
  (u: unknown): u is BondOfferingsDealFileEmails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDealFileEmails> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDealFileEmails }))),
  (x: BondOfferingsDealFileEmails): BondOfferingsDealFileEmailsTagged => ({ ...x, _tag: `BondOfferingsDealFileEmails`}),
));
export type BondOfferingsDealFileEmailsC = typeof bondOfferingsDealFileEmailsC;


export const bondOfferingsDealFileInfoRequests = {
  _tag: `BondOfferingsDealFileInfoRequests`,
  rolesAllowed: [imported38_bankerUser, imported40_bankerAdmin]
} as const;

export const bondOfferingsDealFileInfoRequestsTaggedC = t.type({
  _tag: t.literal(`BondOfferingsDealFileInfoRequests`)
});
export type BondOfferingsDealFileInfoRequestsTaggedC = typeof bondOfferingsDealFileInfoRequestsTaggedC;
export type BondOfferingsDealFileInfoRequestsTagged = t.TypeOf<BondOfferingsDealFileInfoRequestsTaggedC>;
export type BondOfferingsDealFileInfoRequests = BondOfferingsDealFileInfoRequestsTagged & typeof bondOfferingsDealFileInfoRequests;
export const bondOfferingsDealFileInfoRequestsC = pipe(bondOfferingsDealFileInfoRequestsTaggedC, c => new t.Type<BondOfferingsDealFileInfoRequests, BondOfferingsDealFileInfoRequestsTagged>(
  `BondOfferingsDealFileInfoRequests`,
  (u: unknown): u is BondOfferingsDealFileInfoRequests => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferingsDealFileInfoRequests> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferingsDealFileInfoRequests }))),
  (x: BondOfferingsDealFileInfoRequests): BondOfferingsDealFileInfoRequestsTagged => ({ ...x, _tag: `BondOfferingsDealFileInfoRequests`}),
));
export type BondOfferingsDealFileInfoRequestsC = typeof bondOfferingsDealFileInfoRequestsC;


export const analyticsEmailDetails = {
  _tag: `AnalyticsEmailDetails`,
  rolesAllowed: [imported39_bankerUser, imported41_bankerAdmin]
} as const;

export const analyticsEmailDetailsTaggedC = t.type({
  _tag: t.literal(`AnalyticsEmailDetails`)
});
export type AnalyticsEmailDetailsTaggedC = typeof analyticsEmailDetailsTaggedC;
export type AnalyticsEmailDetailsTagged = t.TypeOf<AnalyticsEmailDetailsTaggedC>;
export type AnalyticsEmailDetails = AnalyticsEmailDetailsTagged & typeof analyticsEmailDetails;
export const analyticsEmailDetailsC = pipe(analyticsEmailDetailsTaggedC, c => new t.Type<AnalyticsEmailDetails, AnalyticsEmailDetailsTagged>(
  `AnalyticsEmailDetails`,
  (u: unknown): u is AnalyticsEmailDetails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AnalyticsEmailDetails> => pipe(c.decode(u), E.map(x => ({ ...x, ...analyticsEmailDetails }))),
  (x: AnalyticsEmailDetails): AnalyticsEmailDetailsTagged => ({ ...x, _tag: `AnalyticsEmailDetails`}),
));
export type AnalyticsEmailDetailsC = typeof analyticsEmailDetailsC;


export const rfpsDealFileSummary = {
  _tag: `RfpsDealFileSummary`,
  rolesAllowed: [imported40_bankerUser, imported42_bankerAdmin]
} as const;

export const rfpsDealFileSummaryTaggedC = t.type({
  _tag: t.literal(`RfpsDealFileSummary`)
});
export type RfpsDealFileSummaryTaggedC = typeof rfpsDealFileSummaryTaggedC;
export type RfpsDealFileSummaryTagged = t.TypeOf<RfpsDealFileSummaryTaggedC>;
export type RfpsDealFileSummary = RfpsDealFileSummaryTagged & typeof rfpsDealFileSummary;
export const rfpsDealFileSummaryC = pipe(rfpsDealFileSummaryTaggedC, c => new t.Type<RfpsDealFileSummary, RfpsDealFileSummaryTagged>(
  `RfpsDealFileSummary`,
  (u: unknown): u is RfpsDealFileSummary => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsDealFileSummary> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsDealFileSummary }))),
  (x: RfpsDealFileSummary): RfpsDealFileSummaryTagged => ({ ...x, _tag: `RfpsDealFileSummary`}),
));
export type RfpsDealFileSummaryC = typeof rfpsDealFileSummaryC;


export const rfpsDealFileDetails = {
  _tag: `RfpsDealFileDetails`,
  rolesAllowed: [imported41_bankerUser, imported43_bankerAdmin]
} as const;

export const rfpsDealFileDetailsTaggedC = t.type({
  _tag: t.literal(`RfpsDealFileDetails`)
});
export type RfpsDealFileDetailsTaggedC = typeof rfpsDealFileDetailsTaggedC;
export type RfpsDealFileDetailsTagged = t.TypeOf<RfpsDealFileDetailsTaggedC>;
export type RfpsDealFileDetails = RfpsDealFileDetailsTagged & typeof rfpsDealFileDetails;
export const rfpsDealFileDetailsC = pipe(rfpsDealFileDetailsTaggedC, c => new t.Type<RfpsDealFileDetails, RfpsDealFileDetailsTagged>(
  `RfpsDealFileDetails`,
  (u: unknown): u is RfpsDealFileDetails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsDealFileDetails> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsDealFileDetails }))),
  (x: RfpsDealFileDetails): RfpsDealFileDetailsTagged => ({ ...x, _tag: `RfpsDealFileDetails`}),
));
export type RfpsDealFileDetailsC = typeof rfpsDealFileDetailsC;


export const rfpsDealFileInvestors = {
  _tag: `RfpsDealFileInvestors`,
  rolesAllowed: [imported42_bankerUser, imported44_bankerAdmin]
} as const;

export const rfpsDealFileInvestorsTaggedC = t.type({
  _tag: t.literal(`RfpsDealFileInvestors`)
});
export type RfpsDealFileInvestorsTaggedC = typeof rfpsDealFileInvestorsTaggedC;
export type RfpsDealFileInvestorsTagged = t.TypeOf<RfpsDealFileInvestorsTaggedC>;
export type RfpsDealFileInvestors = RfpsDealFileInvestorsTagged & typeof rfpsDealFileInvestors;
export const rfpsDealFileInvestorsC = pipe(rfpsDealFileInvestorsTaggedC, c => new t.Type<RfpsDealFileInvestors, RfpsDealFileInvestorsTagged>(
  `RfpsDealFileInvestors`,
  (u: unknown): u is RfpsDealFileInvestors => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsDealFileInvestors> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsDealFileInvestors }))),
  (x: RfpsDealFileInvestors): RfpsDealFileInvestorsTagged => ({ ...x, _tag: `RfpsDealFileInvestors`}),
));
export type RfpsDealFileInvestorsC = typeof rfpsDealFileInvestorsC;


export const rfpsDealFileAnalytics = {
  _tag: `RfpsDealFileAnalytics`,
  rolesAllowed: [imported43_bankerUser, imported45_bankerAdmin]
} as const;

export const rfpsDealFileAnalyticsTaggedC = t.type({
  _tag: t.literal(`RfpsDealFileAnalytics`)
});
export type RfpsDealFileAnalyticsTaggedC = typeof rfpsDealFileAnalyticsTaggedC;
export type RfpsDealFileAnalyticsTagged = t.TypeOf<RfpsDealFileAnalyticsTaggedC>;
export type RfpsDealFileAnalytics = RfpsDealFileAnalyticsTagged & typeof rfpsDealFileAnalytics;
export const rfpsDealFileAnalyticsC = pipe(rfpsDealFileAnalyticsTaggedC, c => new t.Type<RfpsDealFileAnalytics, RfpsDealFileAnalyticsTagged>(
  `RfpsDealFileAnalytics`,
  (u: unknown): u is RfpsDealFileAnalytics => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsDealFileAnalytics> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsDealFileAnalytics }))),
  (x: RfpsDealFileAnalytics): RfpsDealFileAnalyticsTagged => ({ ...x, _tag: `RfpsDealFileAnalytics`}),
));
export type RfpsDealFileAnalyticsC = typeof rfpsDealFileAnalyticsC;


export const rfpsDealFileEmails = {
  _tag: `RfpsDealFileEmails`,
  rolesAllowed: [imported44_bankerUser, imported46_bankerAdmin]
} as const;

export const rfpsDealFileEmailsTaggedC = t.type({
  _tag: t.literal(`RfpsDealFileEmails`)
});
export type RfpsDealFileEmailsTaggedC = typeof rfpsDealFileEmailsTaggedC;
export type RfpsDealFileEmailsTagged = t.TypeOf<RfpsDealFileEmailsTaggedC>;
export type RfpsDealFileEmails = RfpsDealFileEmailsTagged & typeof rfpsDealFileEmails;
export const rfpsDealFileEmailsC = pipe(rfpsDealFileEmailsTaggedC, c => new t.Type<RfpsDealFileEmails, RfpsDealFileEmailsTagged>(
  `RfpsDealFileEmails`,
  (u: unknown): u is RfpsDealFileEmails => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsDealFileEmails> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsDealFileEmails }))),
  (x: RfpsDealFileEmails): RfpsDealFileEmailsTagged => ({ ...x, _tag: `RfpsDealFileEmails`}),
));
export type RfpsDealFileEmailsC = typeof rfpsDealFileEmailsC;


export const rfpsDealFileInfoRequests = {
  _tag: `RfpsDealFileInfoRequests`,
  rolesAllowed: [imported45_bankerUser, imported47_bankerAdmin]
} as const;

export const rfpsDealFileInfoRequestsTaggedC = t.type({
  _tag: t.literal(`RfpsDealFileInfoRequests`)
});
export type RfpsDealFileInfoRequestsTaggedC = typeof rfpsDealFileInfoRequestsTaggedC;
export type RfpsDealFileInfoRequestsTagged = t.TypeOf<RfpsDealFileInfoRequestsTaggedC>;
export type RfpsDealFileInfoRequests = RfpsDealFileInfoRequestsTagged & typeof rfpsDealFileInfoRequests;
export const rfpsDealFileInfoRequestsC = pipe(rfpsDealFileInfoRequestsTaggedC, c => new t.Type<RfpsDealFileInfoRequests, RfpsDealFileInfoRequestsTagged>(
  `RfpsDealFileInfoRequests`,
  (u: unknown): u is RfpsDealFileInfoRequests => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpsDealFileInfoRequests> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpsDealFileInfoRequests }))),
  (x: RfpsDealFileInfoRequests): RfpsDealFileInfoRequestsTagged => ({ ...x, _tag: `RfpsDealFileInfoRequests`}),
));
export type RfpsDealFileInfoRequestsC = typeof rfpsDealFileInfoRequestsC;


export const allDealPortalPageC = [dashboardC, investorActivityC, issuersC, portalUsersC, bondOfferingsC, analyticsOverallC, analyticsBondsC, analyticsEmailsC, rfpsC, investorProfileC, bondOfferingsDealFileSummaryC, bondOfferingsDealFileDetailsC, bondOfferingsDealFileInvestorsC, bondOfferingsDealFileAnalyticsC, bondOfferingsDealFileEmailsC, bondOfferingsDealFileInfoRequestsC, analyticsEmailDetailsC, rfpsDealFileSummaryC, rfpsDealFileDetailsC, rfpsDealFileInvestorsC, rfpsDealFileAnalyticsC, rfpsDealFileEmailsC, rfpsDealFileInfoRequestsC] as const;
export const allDealPortalPageNames = [`Dashboard`, `InvestorActivity`, `Issuers`, `PortalUsers`, `BondOfferings`, `AnalyticsOverall`, `AnalyticsBonds`, `AnalyticsEmails`, `Rfps`, `InvestorProfile`, `BondOfferingsDealFileSummary`, `BondOfferingsDealFileDetails`, `BondOfferingsDealFileInvestors`, `BondOfferingsDealFileAnalytics`, `BondOfferingsDealFileEmails`, `BondOfferingsDealFileInfoRequests`, `AnalyticsEmailDetails`, `RfpsDealFileSummary`, `RfpsDealFileDetails`, `RfpsDealFileInvestors`, `RfpsDealFileAnalytics`, `RfpsDealFileEmails`, `RfpsDealFileInfoRequests`] as const;
export type DealPortalPageName = (typeof allDealPortalPageNames)[number];

export const DealPortalPageCU = t.union([dashboardC, investorActivityC, issuersC, portalUsersC, bondOfferingsC, analyticsOverallC, analyticsBondsC, analyticsEmailsC, rfpsC, investorProfileC, bondOfferingsDealFileSummaryC, bondOfferingsDealFileDetailsC, bondOfferingsDealFileInvestorsC, bondOfferingsDealFileAnalyticsC, bondOfferingsDealFileEmailsC, bondOfferingsDealFileInfoRequestsC, analyticsEmailDetailsC, rfpsDealFileSummaryC, rfpsDealFileDetailsC, rfpsDealFileInvestorsC, rfpsDealFileAnalyticsC, rfpsDealFileEmailsC, rfpsDealFileInfoRequestsC]);
export type DealPortalPageCU = typeof DealPortalPageCU;
export type DealPortalPageU = t.TypeOf<DealPortalPageCU>;

export const dealPortalPageOrd: Ord.Ord<DealPortalPageU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDealPortalPage = [dashboard, investorActivity, issuers, portalUsers, bondOfferings, analyticsOverall, analyticsBonds, analyticsEmails, rfps, investorProfile, bondOfferingsDealFileSummary, bondOfferingsDealFileDetails, bondOfferingsDealFileInvestors, bondOfferingsDealFileAnalytics, bondOfferingsDealFileEmails, bondOfferingsDealFileInfoRequests, analyticsEmailDetails, rfpsDealFileSummary, rfpsDealFileDetails, rfpsDealFileInvestors, rfpsDealFileAnalytics, rfpsDealFileEmails, rfpsDealFileInfoRequests] as const;
export type DealPortalPageMap<A> = { [K in DealPortalPageName]: A };





const analyticsBondsPath = lit("deal-portal").then(lit("analytics")).then(lit("bonds"));
const analyticsBondsPathParts = ["analytics", "bonds"] as const;
const analyticsBondsQuery = t.strict({});
export type AnalyticsBondsParams = Describe<typeof analyticsBondsPath._A & typeof analyticsBondsQuery._A>;

export function analyticsBondsRoute(): { match: Match<AnalyticsBondsParams>; pathParts: typeof analyticsBondsPathParts };
export function analyticsBondsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AnalyticsBondsParams>): { match: Match<AnalyticsBondsParams & t.TypeOf<A>>; pathParts: typeof analyticsBondsPathParts };
export function analyticsBondsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AnalyticsBondsParams>) {
  return {
    match: (q ? analyticsBondsPath.then(query(t.intersection([analyticsBondsQuery, q]))) : analyticsBondsPath.then(query(analyticsBondsQuery))).then(end),
    pathParts: analyticsBondsPathParts,
  };
}

const analyticsEmailsPath = lit("deal-portal").then(lit("analytics")).then(lit("emails"));
const analyticsEmailsPathParts = ["analytics", "emails"] as const;
const analyticsEmailsQuery = t.strict({});
export type AnalyticsEmailsParams = Describe<typeof analyticsEmailsPath._A & typeof analyticsEmailsQuery._A>;

export function analyticsEmailsRoute(): { match: Match<AnalyticsEmailsParams>; pathParts: typeof analyticsEmailsPathParts };
export function analyticsEmailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AnalyticsEmailsParams>): { match: Match<AnalyticsEmailsParams & t.TypeOf<A>>; pathParts: typeof analyticsEmailsPathParts };
export function analyticsEmailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AnalyticsEmailsParams>) {
  return {
    match: (q ? analyticsEmailsPath.then(query(t.intersection([analyticsEmailsQuery, q]))) : analyticsEmailsPath.then(query(analyticsEmailsQuery))).then(end),
    pathParts: analyticsEmailsPathParts,
  };
}

const analyticsEmailDetailsPath = lit("deal-portal").then(lit("analytics")).then(lit("emails")).then(lit("details")).then(int("campaignId"));
const analyticsEmailDetailsPathParts = ["analytics", "emails", "details", "campaignId"] as const;
const analyticsEmailDetailsQuery = t.strict({});
export type AnalyticsEmailDetailsParams = Describe<typeof analyticsEmailDetailsPath._A & typeof analyticsEmailDetailsQuery._A>;

export function analyticsEmailDetailsRoute(): { match: Match<AnalyticsEmailDetailsParams>; pathParts: typeof analyticsEmailDetailsPathParts };
export function analyticsEmailDetailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AnalyticsEmailDetailsParams>): { match: Match<AnalyticsEmailDetailsParams & t.TypeOf<A>>; pathParts: typeof analyticsEmailDetailsPathParts };
export function analyticsEmailDetailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AnalyticsEmailDetailsParams>) {
  return {
    match: (q ? analyticsEmailDetailsPath.then(query(t.intersection([analyticsEmailDetailsQuery, q]))) : analyticsEmailDetailsPath.then(query(analyticsEmailDetailsQuery))).then(end),
    pathParts: analyticsEmailDetailsPathParts,
  };
}

const investorActivityPath = lit("deal-portal").then(lit("analytics")).then(lit("investor-activity"));
const investorActivityPathParts = ["analytics", "investor-activity"] as const;
const investorActivityQuery = t.strict({});
export type InvestorActivityParams = Describe<typeof investorActivityPath._A & typeof investorActivityQuery._A>;

export function investorActivityRoute(): { match: Match<InvestorActivityParams>; pathParts: typeof investorActivityPathParts };
export function investorActivityRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorActivityParams>): { match: Match<InvestorActivityParams & t.TypeOf<A>>; pathParts: typeof investorActivityPathParts };
export function investorActivityRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorActivityParams>) {
  return {
    match: (q ? investorActivityPath.then(query(t.intersection([investorActivityQuery, q]))) : investorActivityPath.then(query(investorActivityQuery))).then(end),
    pathParts: investorActivityPathParts,
  };
}

const investorProfilePath = lit("deal-portal").then(lit("analytics")).then(lit("investor-activity")).then(lit("details"));
const investorProfilePathParts = ["analytics", "investor-activity", "details"] as const;
const investorProfileQuery = t.exact(t.type({
  "userId": NumberFromString,
  "startDate": LocalDateC,
  "endDate": LocalDateC
}));
export type InvestorProfileParams = Describe<typeof investorProfilePath._A & typeof investorProfileQuery._A>;

export function investorProfileRoute(): { match: Match<InvestorProfileParams>; pathParts: typeof investorProfilePathParts };
export function investorProfileRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, InvestorProfileParams>): { match: Match<InvestorProfileParams & t.TypeOf<A>>; pathParts: typeof investorProfilePathParts };
export function investorProfileRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, InvestorProfileParams>) {
  return {
    match: (q ? investorProfilePath.then(query(t.intersection([investorProfileQuery, q]))) : investorProfilePath.then(query(investorProfileQuery))).then(end),
    pathParts: investorProfilePathParts,
  };
}

const analyticsOverallPath = lit("deal-portal").then(lit("analytics")).then(lit("overall"));
const analyticsOverallPathParts = ["analytics", "overall"] as const;
const analyticsOverallQuery = t.strict({});
export type AnalyticsOverallParams = Describe<typeof analyticsOverallPath._A & typeof analyticsOverallQuery._A>;

export function analyticsOverallRoute(): { match: Match<AnalyticsOverallParams>; pathParts: typeof analyticsOverallPathParts };
export function analyticsOverallRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, AnalyticsOverallParams>): { match: Match<AnalyticsOverallParams & t.TypeOf<A>>; pathParts: typeof analyticsOverallPathParts };
export function analyticsOverallRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, AnalyticsOverallParams>) {
  return {
    match: (q ? analyticsOverallPath.then(query(t.intersection([analyticsOverallQuery, q]))) : analyticsOverallPath.then(query(analyticsOverallQuery))).then(end),
    pathParts: analyticsOverallPathParts,
  };
}

const bondOfferingsPath = lit("deal-portal").then(lit("bond-offerings"));
const bondOfferingsPathParts = ["bond-offerings"] as const;
const bondOfferingsQuery = t.strict({});
export type BondOfferingsParams = Describe<typeof bondOfferingsPath._A & typeof bondOfferingsQuery._A>;

export function bondOfferingsRoute(): { match: Match<BondOfferingsParams>; pathParts: typeof bondOfferingsPathParts };
export function bondOfferingsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsParams>): { match: Match<BondOfferingsParams & t.TypeOf<A>>; pathParts: typeof bondOfferingsPathParts };
export function bondOfferingsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsParams>) {
  return {
    match: (q ? bondOfferingsPath.then(query(t.intersection([bondOfferingsQuery, q]))) : bondOfferingsPath.then(query(bondOfferingsQuery))).then(end),
    pathParts: bondOfferingsPathParts,
  };
}

const bondOfferingsDealFileAnalyticsPath = lit("deal-portal").then(lit("bond-offerings")).then(lit("deal-file")).then(int("offeringId")).then(lit("analytics"));
const bondOfferingsDealFileAnalyticsPathParts = ["bond-offerings", "deal-file", "offeringId", "analytics"] as const;
const bondOfferingsDealFileAnalyticsQuery = t.strict({});
export type BondOfferingsDealFileAnalyticsParams = Describe<typeof bondOfferingsDealFileAnalyticsPath._A & typeof bondOfferingsDealFileAnalyticsQuery._A>;

export function bondOfferingsDealFileAnalyticsRoute(): { match: Match<BondOfferingsDealFileAnalyticsParams>; pathParts: typeof bondOfferingsDealFileAnalyticsPathParts };
export function bondOfferingsDealFileAnalyticsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsDealFileAnalyticsParams>): { match: Match<BondOfferingsDealFileAnalyticsParams & t.TypeOf<A>>; pathParts: typeof bondOfferingsDealFileAnalyticsPathParts };
export function bondOfferingsDealFileAnalyticsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsDealFileAnalyticsParams>) {
  return {
    match: (q ? bondOfferingsDealFileAnalyticsPath.then(query(t.intersection([bondOfferingsDealFileAnalyticsQuery, q]))) : bondOfferingsDealFileAnalyticsPath.then(query(bondOfferingsDealFileAnalyticsQuery))).then(end),
    pathParts: bondOfferingsDealFileAnalyticsPathParts,
  };
}

const bondOfferingsDealFileDetailsPath = lit("deal-portal").then(lit("bond-offerings")).then(lit("deal-file")).then(int("offeringId")).then(lit("details"));
const bondOfferingsDealFileDetailsPathParts = ["bond-offerings", "deal-file", "offeringId", "details"] as const;
const bondOfferingsDealFileDetailsQuery = t.strict({});
export type BondOfferingsDealFileDetailsParams = Describe<typeof bondOfferingsDealFileDetailsPath._A & typeof bondOfferingsDealFileDetailsQuery._A>;

export function bondOfferingsDealFileDetailsRoute(): { match: Match<BondOfferingsDealFileDetailsParams>; pathParts: typeof bondOfferingsDealFileDetailsPathParts };
export function bondOfferingsDealFileDetailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsDealFileDetailsParams>): { match: Match<BondOfferingsDealFileDetailsParams & t.TypeOf<A>>; pathParts: typeof bondOfferingsDealFileDetailsPathParts };
export function bondOfferingsDealFileDetailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsDealFileDetailsParams>) {
  return {
    match: (q ? bondOfferingsDealFileDetailsPath.then(query(t.intersection([bondOfferingsDealFileDetailsQuery, q]))) : bondOfferingsDealFileDetailsPath.then(query(bondOfferingsDealFileDetailsQuery))).then(end),
    pathParts: bondOfferingsDealFileDetailsPathParts,
  };
}

const bondOfferingsDealFileEmailsPath = lit("deal-portal").then(lit("bond-offerings")).then(lit("deal-file")).then(int("offeringId")).then(lit("emails"));
const bondOfferingsDealFileEmailsPathParts = ["bond-offerings", "deal-file", "offeringId", "emails"] as const;
const bondOfferingsDealFileEmailsQuery = t.strict({});
export type BondOfferingsDealFileEmailsParams = Describe<typeof bondOfferingsDealFileEmailsPath._A & typeof bondOfferingsDealFileEmailsQuery._A>;

export function bondOfferingsDealFileEmailsRoute(): { match: Match<BondOfferingsDealFileEmailsParams>; pathParts: typeof bondOfferingsDealFileEmailsPathParts };
export function bondOfferingsDealFileEmailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsDealFileEmailsParams>): { match: Match<BondOfferingsDealFileEmailsParams & t.TypeOf<A>>; pathParts: typeof bondOfferingsDealFileEmailsPathParts };
export function bondOfferingsDealFileEmailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsDealFileEmailsParams>) {
  return {
    match: (q ? bondOfferingsDealFileEmailsPath.then(query(t.intersection([bondOfferingsDealFileEmailsQuery, q]))) : bondOfferingsDealFileEmailsPath.then(query(bondOfferingsDealFileEmailsQuery))).then(end),
    pathParts: bondOfferingsDealFileEmailsPathParts,
  };
}

const bondOfferingsDealFileInfoRequestsPath = lit("deal-portal").then(lit("bond-offerings")).then(lit("deal-file")).then(int("offeringId")).then(lit("info-requests"));
const bondOfferingsDealFileInfoRequestsPathParts = [
  "bond-offerings", "deal-file", "offeringId", "info-requests"
] as const;
const bondOfferingsDealFileInfoRequestsQuery = t.strict({});
export type BondOfferingsDealFileInfoRequestsParams = Describe<typeof bondOfferingsDealFileInfoRequestsPath._A & typeof bondOfferingsDealFileInfoRequestsQuery._A>;

export function bondOfferingsDealFileInfoRequestsRoute(): { match: Match<BondOfferingsDealFileInfoRequestsParams>; pathParts: typeof bondOfferingsDealFileInfoRequestsPathParts };
export function bondOfferingsDealFileInfoRequestsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsDealFileInfoRequestsParams>): { match: Match<BondOfferingsDealFileInfoRequestsParams & t.TypeOf<A>>; pathParts: typeof bondOfferingsDealFileInfoRequestsPathParts };
export function bondOfferingsDealFileInfoRequestsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsDealFileInfoRequestsParams>) {
  return {
    match: (q ? bondOfferingsDealFileInfoRequestsPath.then(query(t.intersection([bondOfferingsDealFileInfoRequestsQuery, q]))) : bondOfferingsDealFileInfoRequestsPath.then(query(bondOfferingsDealFileInfoRequestsQuery))).then(end),
    pathParts: bondOfferingsDealFileInfoRequestsPathParts,
  };
}

const bondOfferingsDealFileInvestorsPath = lit("deal-portal").then(lit("bond-offerings")).then(lit("deal-file")).then(int("offeringId")).then(lit("investors"));
const bondOfferingsDealFileInvestorsPathParts = ["bond-offerings", "deal-file", "offeringId", "investors"] as const;
const bondOfferingsDealFileInvestorsQuery = t.strict({});
export type BondOfferingsDealFileInvestorsParams = Describe<typeof bondOfferingsDealFileInvestorsPath._A & typeof bondOfferingsDealFileInvestorsQuery._A>;

export function bondOfferingsDealFileInvestorsRoute(): { match: Match<BondOfferingsDealFileInvestorsParams>; pathParts: typeof bondOfferingsDealFileInvestorsPathParts };
export function bondOfferingsDealFileInvestorsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsDealFileInvestorsParams>): { match: Match<BondOfferingsDealFileInvestorsParams & t.TypeOf<A>>; pathParts: typeof bondOfferingsDealFileInvestorsPathParts };
export function bondOfferingsDealFileInvestorsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsDealFileInvestorsParams>) {
  return {
    match: (q ? bondOfferingsDealFileInvestorsPath.then(query(t.intersection([bondOfferingsDealFileInvestorsQuery, q]))) : bondOfferingsDealFileInvestorsPath.then(query(bondOfferingsDealFileInvestorsQuery))).then(end),
    pathParts: bondOfferingsDealFileInvestorsPathParts,
  };
}

const bondOfferingsDealFileSummaryPath = lit("deal-portal").then(lit("bond-offerings")).then(lit("deal-file")).then(int("offeringId")).then(lit("summary"));
const bondOfferingsDealFileSummaryPathParts = ["bond-offerings", "deal-file", "offeringId", "summary"] as const;
const bondOfferingsDealFileSummaryQuery = t.strict({});
export type BondOfferingsDealFileSummaryParams = Describe<typeof bondOfferingsDealFileSummaryPath._A & typeof bondOfferingsDealFileSummaryQuery._A>;

export function bondOfferingsDealFileSummaryRoute(): { match: Match<BondOfferingsDealFileSummaryParams>; pathParts: typeof bondOfferingsDealFileSummaryPathParts };
export function bondOfferingsDealFileSummaryRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondOfferingsDealFileSummaryParams>): { match: Match<BondOfferingsDealFileSummaryParams & t.TypeOf<A>>; pathParts: typeof bondOfferingsDealFileSummaryPathParts };
export function bondOfferingsDealFileSummaryRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondOfferingsDealFileSummaryParams>) {
  return {
    match: (q ? bondOfferingsDealFileSummaryPath.then(query(t.intersection([bondOfferingsDealFileSummaryQuery, q]))) : bondOfferingsDealFileSummaryPath.then(query(bondOfferingsDealFileSummaryQuery))).then(end),
    pathParts: bondOfferingsDealFileSummaryPathParts,
  };
}

const dashboardPath = lit("deal-portal").then(lit("dashboard"));
const dashboardPathParts = ["dashboard"] as const;
const dashboardQuery = t.strict({});
export type DashboardParams = Describe<typeof dashboardPath._A & typeof dashboardQuery._A>;

export function dashboardRoute(): { match: Match<DashboardParams>; pathParts: typeof dashboardPathParts };
export function dashboardRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DashboardParams>): { match: Match<DashboardParams & t.TypeOf<A>>; pathParts: typeof dashboardPathParts };
export function dashboardRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DashboardParams>) {
  return {
    match: (q ? dashboardPath.then(query(t.intersection([dashboardQuery, q]))) : dashboardPath.then(query(dashboardQuery))).then(end),
    pathParts: dashboardPathParts,
  };
}

const issuersPath = lit("deal-portal").then(lit("issuers"));
const issuersPathParts = ["issuers"] as const;
const issuersQuery = t.strict({});
export type IssuersParams = Describe<typeof issuersPath._A & typeof issuersQuery._A>;

export function issuersRoute(): { match: Match<IssuersParams>; pathParts: typeof issuersPathParts };
export function issuersRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuersParams>): { match: Match<IssuersParams & t.TypeOf<A>>; pathParts: typeof issuersPathParts };
export function issuersRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuersParams>) {
  return {
    match: (q ? issuersPath.then(query(t.intersection([issuersQuery, q]))) : issuersPath.then(query(issuersQuery))).then(end),
    pathParts: issuersPathParts,
  };
}

const portalUsersPath = lit("deal-portal").then(lit("portal-users"));
const portalUsersPathParts = ["portal-users"] as const;
const portalUsersQuery = t.strict({});
export type PortalUsersParams = Describe<typeof portalUsersPath._A & typeof portalUsersQuery._A>;

export function portalUsersRoute(): { match: Match<PortalUsersParams>; pathParts: typeof portalUsersPathParts };
export function portalUsersRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, PortalUsersParams>): { match: Match<PortalUsersParams & t.TypeOf<A>>; pathParts: typeof portalUsersPathParts };
export function portalUsersRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, PortalUsersParams>) {
  return {
    match: (q ? portalUsersPath.then(query(t.intersection([portalUsersQuery, q]))) : portalUsersPath.then(query(portalUsersQuery))).then(end),
    pathParts: portalUsersPathParts,
  };
}

const rfpsPath = lit("deal-portal").then(lit("rfps"));
const rfpsPathParts = ["rfps"] as const;
const rfpsQuery = t.strict({});
export type RfpsParams = Describe<typeof rfpsPath._A & typeof rfpsQuery._A>;

export function rfpsRoute(): { match: Match<RfpsParams>; pathParts: typeof rfpsPathParts };
export function rfpsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsParams>): { match: Match<RfpsParams & t.TypeOf<A>>; pathParts: typeof rfpsPathParts };
export function rfpsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsParams>) {
  return {
    match: (q ? rfpsPath.then(query(t.intersection([rfpsQuery, q]))) : rfpsPath.then(query(rfpsQuery))).then(end),
    pathParts: rfpsPathParts,
  };
}

const rfpsDealFileAnalyticsPath = lit("deal-portal").then(lit("rfps")).then(lit("deal-file")).then(int("rfpId")).then(lit("analytics"));
const rfpsDealFileAnalyticsPathParts = ["rfps", "deal-file", "rfpId", "analytics"] as const;
const rfpsDealFileAnalyticsQuery = t.strict({});
export type RfpsDealFileAnalyticsParams = Describe<typeof rfpsDealFileAnalyticsPath._A & typeof rfpsDealFileAnalyticsQuery._A>;

export function rfpsDealFileAnalyticsRoute(): { match: Match<RfpsDealFileAnalyticsParams>; pathParts: typeof rfpsDealFileAnalyticsPathParts };
export function rfpsDealFileAnalyticsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsDealFileAnalyticsParams>): { match: Match<RfpsDealFileAnalyticsParams & t.TypeOf<A>>; pathParts: typeof rfpsDealFileAnalyticsPathParts };
export function rfpsDealFileAnalyticsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsDealFileAnalyticsParams>) {
  return {
    match: (q ? rfpsDealFileAnalyticsPath.then(query(t.intersection([rfpsDealFileAnalyticsQuery, q]))) : rfpsDealFileAnalyticsPath.then(query(rfpsDealFileAnalyticsQuery))).then(end),
    pathParts: rfpsDealFileAnalyticsPathParts,
  };
}

const rfpsDealFileDetailsPath = lit("deal-portal").then(lit("rfps")).then(lit("deal-file")).then(int("rfpId")).then(lit("details"));
const rfpsDealFileDetailsPathParts = ["rfps", "deal-file", "rfpId", "details"] as const;
const rfpsDealFileDetailsQuery = t.strict({});
export type RfpsDealFileDetailsParams = Describe<typeof rfpsDealFileDetailsPath._A & typeof rfpsDealFileDetailsQuery._A>;

export function rfpsDealFileDetailsRoute(): { match: Match<RfpsDealFileDetailsParams>; pathParts: typeof rfpsDealFileDetailsPathParts };
export function rfpsDealFileDetailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsDealFileDetailsParams>): { match: Match<RfpsDealFileDetailsParams & t.TypeOf<A>>; pathParts: typeof rfpsDealFileDetailsPathParts };
export function rfpsDealFileDetailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsDealFileDetailsParams>) {
  return {
    match: (q ? rfpsDealFileDetailsPath.then(query(t.intersection([rfpsDealFileDetailsQuery, q]))) : rfpsDealFileDetailsPath.then(query(rfpsDealFileDetailsQuery))).then(end),
    pathParts: rfpsDealFileDetailsPathParts,
  };
}

const rfpsDealFileEmailsPath = lit("deal-portal").then(lit("rfps")).then(lit("deal-file")).then(int("rfpId")).then(lit("emails"));
const rfpsDealFileEmailsPathParts = ["rfps", "deal-file", "rfpId", "emails"] as const;
const rfpsDealFileEmailsQuery = t.strict({});
export type RfpsDealFileEmailsParams = Describe<typeof rfpsDealFileEmailsPath._A & typeof rfpsDealFileEmailsQuery._A>;

export function rfpsDealFileEmailsRoute(): { match: Match<RfpsDealFileEmailsParams>; pathParts: typeof rfpsDealFileEmailsPathParts };
export function rfpsDealFileEmailsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsDealFileEmailsParams>): { match: Match<RfpsDealFileEmailsParams & t.TypeOf<A>>; pathParts: typeof rfpsDealFileEmailsPathParts };
export function rfpsDealFileEmailsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsDealFileEmailsParams>) {
  return {
    match: (q ? rfpsDealFileEmailsPath.then(query(t.intersection([rfpsDealFileEmailsQuery, q]))) : rfpsDealFileEmailsPath.then(query(rfpsDealFileEmailsQuery))).then(end),
    pathParts: rfpsDealFileEmailsPathParts,
  };
}

const rfpsDealFileInfoRequestsPath = lit("deal-portal").then(lit("rfps")).then(lit("deal-file")).then(int("rfpId")).then(lit("info-requests"));
const rfpsDealFileInfoRequestsPathParts = ["rfps", "deal-file", "rfpId", "info-requests"] as const;
const rfpsDealFileInfoRequestsQuery = t.strict({});
export type RfpsDealFileInfoRequestsParams = Describe<typeof rfpsDealFileInfoRequestsPath._A & typeof rfpsDealFileInfoRequestsQuery._A>;

export function rfpsDealFileInfoRequestsRoute(): { match: Match<RfpsDealFileInfoRequestsParams>; pathParts: typeof rfpsDealFileInfoRequestsPathParts };
export function rfpsDealFileInfoRequestsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsDealFileInfoRequestsParams>): { match: Match<RfpsDealFileInfoRequestsParams & t.TypeOf<A>>; pathParts: typeof rfpsDealFileInfoRequestsPathParts };
export function rfpsDealFileInfoRequestsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsDealFileInfoRequestsParams>) {
  return {
    match: (q ? rfpsDealFileInfoRequestsPath.then(query(t.intersection([rfpsDealFileInfoRequestsQuery, q]))) : rfpsDealFileInfoRequestsPath.then(query(rfpsDealFileInfoRequestsQuery))).then(end),
    pathParts: rfpsDealFileInfoRequestsPathParts,
  };
}

const rfpsDealFileInvestorsPath = lit("deal-portal").then(lit("rfps")).then(lit("deal-file")).then(int("rfpId")).then(lit("investors"));
const rfpsDealFileInvestorsPathParts = ["rfps", "deal-file", "rfpId", "investors"] as const;
const rfpsDealFileInvestorsQuery = t.strict({});
export type RfpsDealFileInvestorsParams = Describe<typeof rfpsDealFileInvestorsPath._A & typeof rfpsDealFileInvestorsQuery._A>;

export function rfpsDealFileInvestorsRoute(): { match: Match<RfpsDealFileInvestorsParams>; pathParts: typeof rfpsDealFileInvestorsPathParts };
export function rfpsDealFileInvestorsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsDealFileInvestorsParams>): { match: Match<RfpsDealFileInvestorsParams & t.TypeOf<A>>; pathParts: typeof rfpsDealFileInvestorsPathParts };
export function rfpsDealFileInvestorsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsDealFileInvestorsParams>) {
  return {
    match: (q ? rfpsDealFileInvestorsPath.then(query(t.intersection([rfpsDealFileInvestorsQuery, q]))) : rfpsDealFileInvestorsPath.then(query(rfpsDealFileInvestorsQuery))).then(end),
    pathParts: rfpsDealFileInvestorsPathParts,
  };
}

const rfpsDealFileSummaryPath = lit("deal-portal").then(lit("rfps")).then(lit("deal-file")).then(int("rfpId")).then(lit("summary"));
const rfpsDealFileSummaryPathParts = ["rfps", "deal-file", "rfpId", "summary"] as const;
const rfpsDealFileSummaryQuery = t.strict({});
export type RfpsDealFileSummaryParams = Describe<typeof rfpsDealFileSummaryPath._A & typeof rfpsDealFileSummaryQuery._A>;

export function rfpsDealFileSummaryRoute(): { match: Match<RfpsDealFileSummaryParams>; pathParts: typeof rfpsDealFileSummaryPathParts };
export function rfpsDealFileSummaryRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, RfpsDealFileSummaryParams>): { match: Match<RfpsDealFileSummaryParams & t.TypeOf<A>>; pathParts: typeof rfpsDealFileSummaryPathParts };
export function rfpsDealFileSummaryRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, RfpsDealFileSummaryParams>) {
  return {
    match: (q ? rfpsDealFileSummaryPath.then(query(t.intersection([rfpsDealFileSummaryQuery, q]))) : rfpsDealFileSummaryPath.then(query(rfpsDealFileSummaryQuery))).then(end),
    pathParts: rfpsDealFileSummaryPathParts,
  };
}

export const allRoutes = [analyticsBondsRoute, analyticsEmailsRoute, analyticsEmailDetailsRoute, investorActivityRoute, investorProfileRoute, analyticsOverallRoute, bondOfferingsRoute, bondOfferingsDealFileAnalyticsRoute, bondOfferingsDealFileDetailsRoute, bondOfferingsDealFileEmailsRoute, bondOfferingsDealFileInfoRequestsRoute, bondOfferingsDealFileInvestorsRoute, bondOfferingsDealFileSummaryRoute, dashboardRoute, issuersRoute, portalUsersRoute, rfpsRoute, rfpsDealFileAnalyticsRoute, rfpsDealFileDetailsRoute, rfpsDealFileEmailsRoute, rfpsDealFileInfoRequestsRoute, rfpsDealFileInvestorsRoute, rfpsDealFileSummaryRoute] as const;