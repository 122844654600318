import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { RatingAgencyCU as imported35_RatingAgencyCU } from "../domaintables/ratingAgencies";

export const analystC = t.type({
  ratingAgencyId: imported35_RatingAgencyCU,
  firstName: t.string,
  lastName: t.string,
  email: optionFromNullable(t.string),
  phoneNumber: optionFromNullable(t.string)
});
export type AnalystC = typeof analystC;
export type Analyst = t.TypeOf<AnalystC>;


export const analystPostC = t.type({
  id: optionFromNullable(t.number),
  firstName: t.string,
  lastName: t.string,
  email: optionFromNullable(t.string),
  phoneNumber: optionFromNullable(t.string)
});
export type AnalystPostC = typeof analystPostC;
export type AnalystPost = t.TypeOf<AnalystPostC>;


