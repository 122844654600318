import * as t from "io-ts";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";

export const emmaSubcategoryC = t.type({
  id: t.number,
  name: t.string
});
export type EmmaSubcategoryC = typeof emmaSubcategoryC;
export type EmmaSubcategory = t.TypeOf<EmmaSubcategoryC>;


export const emmaDateQualifierC = t.type({
  id: t.number,
  name: t.string,
  requiresBegin: t.boolean,
  requiresEnd: t.boolean,
  requiresAsOf: t.boolean
});
export type EmmaDateQualifierC = typeof emmaDateQualifierC;
export type EmmaDateQualifier = t.TypeOf<EmmaDateQualifierC>;


export const emmaCategoryC = t.type({
  id: t.number,
  name: t.string,
  requiresDescription: t.boolean,
  subcategories: t.readonlyArray(emmaSubcategoryC),
  dateQualifiers: readonlyNonEmptyArrayC(emmaDateQualifierC)
});
export type EmmaCategoryC = typeof emmaCategoryC;
export type EmmaCategory = t.TypeOf<EmmaCategoryC>;


export const emmaCategoryGroupC = t.type({
  name: t.string,
  categories: readonlyNonEmptyArrayC(emmaCategoryC)
});
export type EmmaCategoryGroupC = typeof emmaCategoryGroupC;
export type EmmaCategoryGroup = t.TypeOf<EmmaCategoryGroupC>;


export const emmaDisclosureTypeC = t.type({
  id: t.number,
  name: t.string,
  groups: readonlyNonEmptyArrayC(emmaCategoryGroupC)
});
export type EmmaDisclosureTypeC = typeof emmaDisclosureTypeC;
export type EmmaDisclosureType = t.TypeOf<EmmaDisclosureTypeC>;


