import { WithStatusCU as imported327_WithStatusCU, hasManyLinkC as imported154_hasManyLinkC, WithStatusCU as imported331_WithStatusCU, hasManyLinkC as imported151_hasManyLinkC, WithStatusCU as imported335_WithStatusCU, hasManyLinkC as imported149_hasManyLinkC, WithStatusCU as imported325_WithStatusCU, hasManyLinkC as imported150_hasManyLinkC, WithStatusCU as imported328_WithStatusCU, WithStatusCU as imported332_WithStatusCU, WithStatusCU as imported326_WithStatusCU, hasManyLinkC as imported153_hasManyLinkC, WithStatusCU as imported333_WithStatusCU, WithStatusCU as imported330_WithStatusCU, hasManyLinkC as imported152_hasManyLinkC, hasManyLinkC as imported155_hasManyLinkC, WithStatusCU as imported329_WithStatusCU, hasManyLinkC as imported156_hasManyLinkC, WithStatusCU as imported334_WithStatusCU } from "./threadThrough";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { genRfpWithRelatedContentC as imported18_genRfpWithRelatedContentC, rfpCardRelatedContentC as imported4_rfpCardRelatedContentC } from "./rfp";
import { programRatingC as imported14_programRatingC } from "./ratingBase";
import { RatingAgencyCU as imported40_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { externalLinkC as imported20_externalLinkC, externalLinkC as imported21_externalLinkC } from "./externalLink";
import { projectC as imported22_projectC } from "./project";
import { mediaC as imported70_mediaC, mediaC as imported71_mediaC, imageUploadResponseC as imported7_imageUploadResponseC, mediaUploadResponseWithCategoryC as imported11_mediaUploadResponseWithCategoryC } from "./media";
import { taggedContentC as imported171_taggedContentC, taggedContentC as imported169_taggedContentC, taggedContentC as imported168_taggedContentC, taggedContentC as imported167_taggedContentC, taggedContentC as imported170_taggedContentC } from "./taggedContent";
import { quickFactC as imported9_quickFactC, quickFactC as imported10_quickFactC } from "./quickFact";
import { bondProgramC as imported18_bondProgramC } from "./bondProgramBase";
import { subscribedC as imported35_subscribedC, subscribedC as imported34_subscribedC } from "./subscribed";
import { eventC as imported17_eventC } from "./event";
import { sortOrderC as imported28_sortOrderC, sortOrderC as imported29_sortOrderC, sortOrderC as imported30_sortOrderC, sortOrderC as imported31_sortOrderC } from "./sortOrder";
import { markdownC } from "../../codecs/markdown";
import { issuerNewsC as imported19_issuerNewsC } from "./issuerNewsBase";
import { ratingWithRelatedContentC as imported25_ratingWithRelatedContentC } from "./rating";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { cusip9C as imported7_cusip9C } from "./cusip";
import { bondOfferingWithRoadshowsAndDocsC as imported3_bondOfferingWithRoadshowsAndDocsC } from "./bondOffering";
import { relatedDocumentLinkC as imported31_relatedDocumentLinkC, relatedExternalLinkLinkC as imported11_relatedExternalLinkLinkC, relatedProjectLinkC as imported27_relatedProjectLinkC, relatedNewsLinkC as imported18_relatedNewsLinkC, postAttachedC as imported21_postAttachedC, relatedPhotoLinkC as imported4_relatedPhotoLinkC, relatedCusip9LinkC as imported10_relatedCusip9LinkC, relatedQuickFactLinkC as imported3_relatedQuickFactLinkC, postRelatedC as imported53_postRelatedC, postAttachedC as imported19_postAttachedC, relatedNewsLinkC as imported19_relatedNewsLinkC, isAttachedC as imported19_isAttachedC, postRelatedAndAttachedC as imported11_postRelatedAndAttachedC, relatedProjectLinkC as imported26_relatedProjectLinkC, relatedEventLinkC as imported19_relatedEventLinkC, postRelatedC as imported51_postRelatedC, relatedCusip9LinkC as imported11_relatedCusip9LinkC, postAttachedC as imported20_postAttachedC, relatedDocumentLinkC as imported30_relatedDocumentLinkC, postRelatedC as imported54_postRelatedC, relatedEventLinkC as imported18_relatedEventLinkC, postRelatedC as imported52_postRelatedC } from "./relatedContent";

export const bondProgramRelatedContentC = t.type({
  offerings: t.readonlyArray(imported34_subscribedC(imported325_WithStatusCU(imported167_taggedContentC(imported3_bondOfferingWithRoadshowsAndDocsC)))),
  rfps: t.readonlyArray(imported35_subscribedC(imported326_WithStatusCU(imported168_taggedContentC(imported18_genRfpWithRelatedContentC(imported4_rfpCardRelatedContentC))))),
  ratings: t.readonlyArray(t.tuple([imported40_RatingAgencyCU, readonlyNonEmptyArrayC(imported327_WithStatusCU(imported25_ratingWithRelatedContentC(imported14_programRatingC)))])),
  photos: t.readonlyArray(imported149_hasManyLinkC(imported328_WithStatusCU(imported70_mediaC), imported4_relatedPhotoLinkC)),
  quickFacts: t.readonlyArray(imported150_hasManyLinkC(imported329_WithStatusCU(imported9_quickFactC), imported3_relatedQuickFactLinkC)),
  documents: t.readonlyArray(imported151_hasManyLinkC(imported330_WithStatusCU(imported169_taggedContentC(imported71_mediaC)), imported19_isAttachedC(imported30_relatedDocumentLinkC))),
  externalLinks: t.readonlyArray(imported152_hasManyLinkC(imported331_WithStatusCU(imported20_externalLinkC), imported11_relatedExternalLinkLinkC)),
  projects: t.readonlyArray(imported153_hasManyLinkC(imported332_WithStatusCU(imported170_taggedContentC(imported22_projectC)), imported26_relatedProjectLinkC)),
  news: t.readonlyArray(imported154_hasManyLinkC(imported333_WithStatusCU(imported171_taggedContentC(imported19_issuerNewsC)), imported18_relatedNewsLinkC)),
  events: t.readonlyArray(imported155_hasManyLinkC(imported334_WithStatusCU(imported17_eventC), imported18_relatedEventLinkC)),
  cusip9s: t.readonlyArray(imported156_hasManyLinkC(imported335_WithStatusCU(imported7_cusip9C), imported10_relatedCusip9LinkC))
});
export type BondProgramRelatedContentC = typeof bondProgramRelatedContentC;
export type BondProgramRelatedContent = t.TypeOf<BondProgramRelatedContentC>;


export const bondProgramWithRelatedContentC = t.type({
  program: imported18_bondProgramC,
  relatedContent: bondProgramRelatedContentC
});
export type BondProgramWithRelatedContentC = typeof bondProgramWithRelatedContentC;
export type BondProgramWithRelatedContent = t.TypeOf<BondProgramWithRelatedContentC>;


export const bondProgramPostRelatedContentC = t.type({
  photos: imported19_postAttachedC(imported7_imageUploadResponseC, imported28_sortOrderC),
  quickFacts: imported20_postAttachedC(imported10_quickFactC, imported29_sortOrderC),
  documents: imported11_postRelatedAndAttachedC(imported31_relatedDocumentLinkC, imported11_mediaUploadResponseWithCategoryC, imported30_sortOrderC),
  externalLinks: imported21_postAttachedC(imported21_externalLinkC, imported31_sortOrderC),
  projects: imported51_postRelatedC(imported27_relatedProjectLinkC),
  news: imported52_postRelatedC(imported19_relatedNewsLinkC),
  events: imported53_postRelatedC(imported19_relatedEventLinkC),
  cusip9s: imported54_postRelatedC(imported11_relatedCusip9LinkC)
});
export type BondProgramPostRelatedContentC = typeof bondProgramPostRelatedContentC;
export type BondProgramPostRelatedContent = t.TypeOf<BondProgramPostRelatedContentC>;


export const bondProgramPostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  overview: optionFromNullable(markdownC),
  cusip6: t.string,
  sectorId: optionFromNullable(t.number),
  sortOrder: t.number,
  relatedContent: bondProgramPostRelatedContentC
});
export type BondProgramPostC = typeof bondProgramPostC;
export type BondProgramPost = t.TypeOf<BondProgramPostC>;


