import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { dateQualifierC as imported16_dateQualifierC, dateQualifierC as imported19_dateQualifierC, dateQualifierC as imported17_dateQualifierC, dateQualifierC as imported15_dateQualifierC, dateQualifierC as imported14_dateQualifierC, dateQualifierC as imported18_dateQualifierC } from "./dateQualifier";
import * as t from "io-ts";

export const cusip6C = t.type({
  name: t.string,
  cusip6: t.string
});
export type Cusip6C = typeof cusip6C;
export type Cusip6 = t.TypeOf<Cusip6C>;


export const cusip6PostBodyC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  cusip6: t.string
});
export type Cusip6PostBodyC = typeof cusip6PostBodyC;
export type Cusip6PostBody = t.TypeOf<Cusip6PostBodyC>;


export const cusip9C = t.type({
  bondName: t.string,
  cusip: t.string,
  hidden: t.boolean,
  issueDate: optionFromNullable(imported14_dateQualifierC),
  datedDate: optionFromNullable(imported15_dateQualifierC),
  maturityDate: optionFromNullable(imported16_dateQualifierC)
});
export type Cusip9C = typeof cusip9C;
export type Cusip9 = t.TypeOf<Cusip9C>;


export const cusip9PostBodyC = t.type({
  id: optionFromNullable(t.number),
  bondName: t.string,
  cusip: t.string,
  hidden: t.boolean,
  issueDate: optionFromNullable(imported17_dateQualifierC),
  datedDate: optionFromNullable(imported18_dateQualifierC),
  maturityDate: optionFromNullable(imported19_dateQualifierC)
});
export type Cusip9PostBodyC = typeof cusip9PostBodyC;
export type Cusip9PostBody = t.TypeOf<Cusip9PostBodyC>;


export const hasCusip9C = t.type({
  hasCusip9: t.boolean,
  cusip9: cusip9C
});
export type HasCusip9C = typeof hasCusip9C;
export type HasCusip9 = t.TypeOf<HasCusip9C>;


