import { WithStatusCU as imported387_WithStatusCU, WithStatusCU as imported388_WithStatusCU, WithStatusCU as imported390_WithStatusCU, withIdC as imported301_withIdC, WithStatusCU as imported389_WithStatusCU, WithStatusCU as imported391_WithStatusCU, WithStatusCU as imported386_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import { LocalDateC } from "../../codecs/localDate";
import { taggedContentC as imported215_taggedContentC, taggedContentC as imported218_taggedContentC, taggedContentC as imported217_taggedContentC, taggedContentC as imported216_taggedContentC, taggedContentC as imported219_taggedContentC, taggedContentC as imported214_taggedContentC } from "./taggedContent";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { eventC as imported19_eventC } from "./event";
import { roadShowC as imported21_roadShowC } from "./roadshow";
import { projectC as imported27_projectC } from "./project";
import * as E from "fp-ts/lib/Either";
import { issuerNewsC as imported25_issuerNewsC } from "./issuerNewsBase";
import { documentWithCategoryC as imported21_documentWithCategoryC } from "./document";
import { issuerSitesPageC as imported1_issuerSitesPageC } from "./pageConfig";
import { bondOfferingC as imported31_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported64_rfpC } from "./rfpBase";

export const rfp = {
  _tag: `Rfp`,
  id: 8
} as const;

export const rfpTaggedC = t.type({
  _tag: t.literal(`Rfp`)
});
export type RfpTaggedC = typeof rfpTaggedC;
export type RfpTagged = t.TypeOf<RfpTaggedC>;
export type Rfp = RfpTagged & typeof rfp;
export const rfpC = pipe(rfpTaggedC, c => new t.Type<Rfp, RfpTagged>(
  `Rfp`,
  (u: unknown): u is Rfp => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Rfp> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfp }))),
  (x: Rfp): RfpTagged => ({ ...x, _tag: `Rfp`}),
));
export type RfpC = typeof rfpC;


export const document = {
  _tag: `Document`,
  id: 1
} as const;

export const documentTaggedC = t.type({
  _tag: t.literal(`Document`)
});
export type DocumentTaggedC = typeof documentTaggedC;
export type DocumentTagged = t.TypeOf<DocumentTaggedC>;
export type Document = DocumentTagged & typeof document;
export const documentC = pipe(documentTaggedC, c => new t.Type<Document, DocumentTagged>(
  `Document`,
  (u: unknown): u is Document => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Document> => pipe(c.decode(u), E.map(x => ({ ...x, ...document }))),
  (x: Document): DocumentTagged => ({ ...x, _tag: `Document`}),
));
export type DocumentC = typeof documentC;


export const page = {
  _tag: `Page`,
  id: 5
} as const;

export const pageTaggedC = t.type({
  _tag: t.literal(`Page`)
});
export type PageTaggedC = typeof pageTaggedC;
export type PageTagged = t.TypeOf<PageTaggedC>;
export type Page = PageTagged & typeof page;
export const pageC = pipe(pageTaggedC, c => new t.Type<Page, PageTagged>(
  `Page`,
  (u: unknown): u is Page => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Page> => pipe(c.decode(u), E.map(x => ({ ...x, ...page }))),
  (x: Page): PageTagged => ({ ...x, _tag: `Page`}),
));
export type PageC = typeof pageC;


export const project = {
  _tag: `Project`,
  id: 6
} as const;

export const projectTaggedC = t.type({
  _tag: t.literal(`Project`)
});
export type ProjectTaggedC = typeof projectTaggedC;
export type ProjectTagged = t.TypeOf<ProjectTaggedC>;
export type Project = ProjectTagged & typeof project;
export const projectC = pipe(projectTaggedC, c => new t.Type<Project, ProjectTagged>(
  `Project`,
  (u: unknown): u is Project => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Project> => pipe(c.decode(u), E.map(x => ({ ...x, ...project }))),
  (x: Project): ProjectTagged => ({ ...x, _tag: `Project`}),
));
export type ProjectC = typeof projectC;


export const offering = {
  _tag: `Offering`,
  id: 4
} as const;

export const offeringTaggedC = t.type({
  _tag: t.literal(`Offering`)
});
export type OfferingTaggedC = typeof offeringTaggedC;
export type OfferingTagged = t.TypeOf<OfferingTaggedC>;
export type Offering = OfferingTagged & typeof offering;
export const offeringC = pipe(offeringTaggedC, c => new t.Type<Offering, OfferingTagged>(
  `Offering`,
  (u: unknown): u is Offering => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Offering> => pipe(c.decode(u), E.map(x => ({ ...x, ...offering }))),
  (x: Offering): OfferingTagged => ({ ...x, _tag: `Offering`}),
));
export type OfferingC = typeof offeringC;


export const news = {
  _tag: `News`,
  id: 3
} as const;

export const newsTaggedC = t.type({
  _tag: t.literal(`News`)
});
export type NewsTaggedC = typeof newsTaggedC;
export type NewsTagged = t.TypeOf<NewsTaggedC>;
export type News = NewsTagged & typeof news;
export const newsC = pipe(newsTaggedC, c => new t.Type<News, NewsTagged>(
  `News`,
  (u: unknown): u is News => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, News> => pipe(c.decode(u), E.map(x => ({ ...x, ...news }))),
  (x: News): NewsTagged => ({ ...x, _tag: `News`}),
));
export type NewsC = typeof newsC;


export const roadShow = {
  _tag: `RoadShow`,
  id: 7
} as const;

export const roadShowTaggedC = t.type({
  _tag: t.literal(`RoadShow`)
});
export type RoadShowTaggedC = typeof roadShowTaggedC;
export type RoadShowTagged = t.TypeOf<RoadShowTaggedC>;
export type RoadShow = RoadShowTagged & typeof roadShow;
export const roadShowC = pipe(roadShowTaggedC, c => new t.Type<RoadShow, RoadShowTagged>(
  `RoadShow`,
  (u: unknown): u is RoadShow => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RoadShow> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadShow }))),
  (x: RoadShow): RoadShowTagged => ({ ...x, _tag: `RoadShow`}),
));
export type RoadShowC = typeof roadShowC;


export const event = {
  _tag: `Event`,
  id: 2
} as const;

export const eventTaggedC = t.type({
  _tag: t.literal(`Event`)
});
export type EventTaggedC = typeof eventTaggedC;
export type EventTagged = t.TypeOf<EventTaggedC>;
export type Event = EventTagged & typeof event;
export const eventC = pipe(eventTaggedC, c => new t.Type<Event, EventTagged>(
  `Event`,
  (u: unknown): u is Event => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Event> => pipe(c.decode(u), E.map(x => ({ ...x, ...event }))),
  (x: Event): EventTagged => ({ ...x, _tag: `Event`}),
));
export type EventC = typeof eventC;


export const allFeaturedItemTypeC = [documentC, eventC, newsC, offeringC, pageC, projectC, roadShowC, rfpC] as const;
export const allFeaturedItemTypeNames = [`Document`, `Event`, `News`, `Offering`, `Page`, `Project`, `RoadShow`, `Rfp`] as const;
export type FeaturedItemTypeName = (typeof allFeaturedItemTypeNames)[number];

export const FeaturedItemTypeCU = t.union([documentC, eventC, newsC, offeringC, pageC, projectC, roadShowC, rfpC]);
export type FeaturedItemTypeCU = typeof FeaturedItemTypeCU;
export type FeaturedItemTypeU = t.TypeOf<FeaturedItemTypeCU>;

export const featuredItemTypeOrd: Ord.Ord<FeaturedItemTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allFeaturedItemType = [document, event, news, offering, page, project, roadShow, rfp] as const;
export type FeaturedItemTypeMap<A> = { [K in FeaturedItemTypeName]: A };


export const featuredItemC = t.type({
  itemId: t.number,
  itemType: FeaturedItemTypeCU,
  header: t.string,
  displayUntil: optionFromNullable(LocalDateC),
  url: t.string,
  dateLabel: optionFromNullable(t.string),
  sortOrder: t.number
});
export type FeaturedItemC = typeof featuredItemC;
export type FeaturedItem = t.TypeOf<FeaturedItemC>;


export const featuredItemsAvailableC = t.type({
  bonds: t.readonlyArray(imported386_WithStatusCU(imported214_taggedContentC(imported31_bondOfferingC))),
  rfps: t.readonlyArray(imported387_WithStatusCU(imported215_taggedContentC(imported64_rfpC))),
  news: t.readonlyArray(imported388_WithStatusCU(imported216_taggedContentC(imported25_issuerNewsC))),
  events: t.readonlyArray(imported389_WithStatusCU(imported19_eventC)),
  documents: t.readonlyArray(imported390_WithStatusCU(imported217_taggedContentC(imported21_documentWithCategoryC))),
  projects: t.readonlyArray(imported391_WithStatusCU(imported218_taggedContentC(imported27_projectC))),
  roadshows: t.readonlyArray(imported301_withIdC(imported219_taggedContentC(imported21_roadShowC))),
  pages: t.readonlyArray(imported1_issuerSitesPageC)
});
export type FeaturedItemsAvailableC = typeof featuredItemsAvailableC;
export type FeaturedItemsAvailable = t.TypeOf<FeaturedItemsAvailableC>;


export const featuredItemPostBodyC = t.type({
  id: t.number,
  itemId: t.number,
  itemTypeId: t.number,
  header: optionFromNullable(t.string),
  displayUntil: optionFromNullable(LocalDateC),
  sortOrder: t.number
});
export type FeaturedItemPostBodyC = typeof featuredItemPostBodyC;
export type FeaturedItemPostBody = t.TypeOf<FeaturedItemPostBodyC>;


