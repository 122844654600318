import { documentTypeC as imported10_documentTypeC } from "./document";
import { MediaCategoryCU as imported7_MediaCategoryCU } from "../domaintables/mediaCategories";
import { either } from "io-ts-types/lib/either";
import * as t from "io-ts";

export const categoryWithCountC = t.type({
  category: either(imported10_documentTypeC, imported7_MediaCategoryCU),
  count: t.number
});
export type CategoryWithCountC = typeof categoryWithCountC;
export type CategoryWithCount = t.TypeOf<CategoryWithCountC>;


