import * as t from "io-ts";
import { rfpC as imported63_rfpC } from "./rfpBase";
import { SectorCU as imported19_SectorCU } from "../domaintables/sectors";
import { bondOfferingDetailsC as imported40_bondOfferingDetailsC } from "./bondOffering";
import { withIdC as imported283_withIdC, withIdC as imported284_withIdC } from "./threadThrough";
import { withIssuerC as imported13_withIssuerC, issuerC as imported52_issuerC, withIssuerC as imported12_withIssuerC } from "./issuer";

export const sectorDataC = t.type({
  sector: imported19_SectorCU,
  issuers: t.readonlyArray(imported52_issuerC),
  offerings: t.readonlyArray(imported12_withIssuerC(imported283_withIdC(imported40_bondOfferingDetailsC))),
  rfps: t.readonlyArray(imported13_withIssuerC(imported284_withIdC(imported63_rfpC)))
});
export type SectorDataC = typeof sectorDataC;
export type SectorData = t.TypeOf<SectorDataC>;


